import React from 'react';
import { TagOutlined, TeamOutlined } from '@ant-design/icons';
import Card from '../../../common/Card';
import "../../../styles/campaignForm/CampaignObjective.css"
import {CampaignObjectives} from "../../../common/enums";
const CampaignObjective = ({
                               handleChange,
                               value }) => {

    return (
        <div className='form-main'>
            <div>
                <div className='form-heading-logo'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M20.172 6.75003H18.311L13.745 11.314C13.9022 11.7148 13.9167 12.1575 13.7859 12.5677C13.6552 12.9779 13.3871 13.3306 13.0269 13.5664C12.6667 13.8022 12.2363 13.9068 11.808 13.8626C11.3798 13.8184 10.9798 13.6281 10.6753 13.3237C10.3709 13.0192 10.1806 12.6192 10.1364 12.191C10.0922 11.7627 10.1968 11.3323 10.4326 10.9721C10.6684 10.6119 11.0211 10.3438 11.4313 10.2131C11.8415 10.0823 12.2842 10.0968 12.685 10.254L17.25 5.68903V3.82803C17.2501 3.57901 17.349 3.3402 17.525 3.16403L19.255 1.43403C19.2869 1.40172 19.3271 1.37876 19.3712 1.36766C19.4153 1.35655 19.4615 1.35772 19.505 1.37103C19.594 1.39703 19.66 1.47103 19.678 1.56203L20.138 3.86303L22.438 4.32303C22.528 4.34103 22.602 4.40703 22.628 4.49603C22.6411 4.53926 22.6423 4.58525 22.6314 4.62909C22.6204 4.67294 22.5978 4.713 22.566 4.74503L20.835 6.47503C20.748 6.56219 20.6446 6.63134 20.5309 6.67853C20.4171 6.72572 20.2951 6.75002 20.172 6.75003Z" fill="black" />
                        <path d="M2.625 12C2.625 14.4864 3.61272 16.871 5.37087 18.6291C7.12903 20.3873 9.5136 21.375 12 21.375C13.2311 21.375 14.4502 21.1325 15.5877 20.6614C16.7251 20.1902 17.7586 19.4997 18.6291 18.6291C19.4997 17.7586 20.1902 16.7251 20.6614 15.5877C21.1325 14.4502 21.375 13.2311 21.375 12C21.375 11.102 21.249 10.234 21.014 9.41301C20.9673 9.2245 20.9955 9.02522 21.0928 8.8571C21.19 8.68899 21.3487 8.56515 21.5353 8.51164C21.722 8.45813 21.9222 8.47913 22.0937 8.57021C22.2653 8.6613 22.3948 8.81537 22.455 9.00001C22.729 9.95401 22.875 10.96 22.875 12C22.875 18.006 18.006 22.875 12 22.875C5.994 22.875 1.125 18.006 1.125 12C1.125 5.99401 5.994 1.12501 12 1.12501C13.015 1.12401 14.024 1.26501 15 1.54401C15.0952 1.5707 15.1841 1.61594 15.2617 1.67713C15.3393 1.73831 15.4041 1.81423 15.4523 1.90053C15.5005 1.98683 15.5311 2.0818 15.5425 2.17998C15.5538 2.27816 15.5457 2.37762 15.5184 2.47264C15.4912 2.56765 15.4455 2.65635 15.3839 2.73364C15.3223 2.81092 15.246 2.87527 15.1595 2.92297C15.0729 2.97068 14.9778 3.00081 14.8795 3.01163C14.7813 3.02244 14.6819 3.01374 14.587 2.98601C13.7457 2.74573 12.8749 2.62423 12 2.62501C9.5136 2.62501 7.12903 3.61273 5.37087 5.37088C3.61272 7.12903 2.625 9.5136 2.625 12Z" fill="black" />
                        <path d="M7.12499 12C7.12778 12.6624 7.26553 13.3172 7.52983 13.9245C7.79414 14.5318 8.17945 15.0788 8.66223 15.5323C9.14502 15.9857 9.71516 16.336 10.3378 16.5617C10.9605 16.7874 11.6227 16.8839 12.2839 16.8452C12.9451 16.8064 13.5914 16.6334 14.1835 16.3365C14.7756 16.0396 15.3009 15.6252 15.7275 15.1186C16.1541 14.6119 16.4729 14.0236 16.6645 13.3896C16.8561 12.7556 16.9165 12.0892 16.842 11.431C16.821 11.2977 16.8364 11.1613 16.8867 11.036C16.9369 10.9108 17.0201 10.8015 17.1274 10.7197C17.2348 10.6379 17.3622 10.5867 17.4963 10.5714C17.6303 10.5562 17.766 10.5775 17.889 10.633C18.14 10.745 18.309 10.984 18.331 11.258C18.4863 12.5733 18.2279 13.9042 17.5919 15.0659C16.9558 16.2275 15.9737 17.1622 14.782 17.7399C13.5902 18.3176 12.2481 18.5097 10.9422 18.2895C9.63624 18.0692 8.43135 17.4476 7.49499 16.511C6.55888 15.5755 5.93711 14.3718 5.71591 13.0669C5.49471 11.7621 5.68504 10.4207 6.26051 9.22888C6.83598 8.03706 7.76805 7.05385 8.92747 6.41562C10.0869 5.77739 11.4162 5.51577 12.731 5.66703C12.8304 5.67626 12.9269 5.70523 13.015 5.75225C13.103 5.79927 13.1808 5.8634 13.2438 5.94087C13.3067 6.01834 13.3536 6.1076 13.3816 6.20341C13.4096 6.29923 13.4182 6.39967 13.4069 6.49885C13.3955 6.59802 13.3645 6.69395 13.3157 6.78099C13.2668 6.86803 13.2011 6.94444 13.1223 7.00574C13.0435 7.06705 12.9533 7.112 12.8569 7.13798C12.7605 7.16396 12.6599 7.17044 12.561 7.15704C11.878 7.07807 11.1861 7.14444 10.5306 7.35177C9.87504 7.5591 9.27078 7.90272 8.75743 8.36006C8.24409 8.81741 7.83327 9.37814 7.55193 10.0055C7.27059 10.6328 7.12509 11.3125 7.12499 12Z" fill="black" />
                    </svg>
                    <h2 className='campaign_name'>Whats your campaign Objective?</h2>
                </div>
                <div style={{ marginTop: "40px" }}>
                    <span className='objective-heading'>Choose your objective</span><br></br>
                    <span className='objective-detail'>Select an objective to tailor your experience to the goals and settings that will work best for your campaign</span>
                </div>
                <div style={{ padding: "40px 0px" }}>
                    <Card
                        options={CampaignObjectives}
                        type="card-objective"
                        handleChange={handleChange}
                        name="campaign_objective"
                        value={value}
                    />

                </div>
            </div>

        </div>
    );
}

export default CampaignObjective;