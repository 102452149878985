import {createAsyncThunk} from "@reduxjs/toolkit";
import {BudgetActionType} from "../actions/BudgetAction";
import { CREATE_BUDGET, LIST_BUDGET } from "../types/BudgetActionTypes";


export const createBudget=createAsyncThunk(CREATE_BUDGET,async (body)=>{
    try{
       return await BudgetActionType.createBudget(body);

    } catch (e){
        throw  e?.response?.data?.error[0] || e;    }
})


export const listBudget=createAsyncThunk(LIST_BUDGET,async ({id})=>{
    try{
        const res= await BudgetActionType.getBudgets(id);
        return res?.data;
    } catch (e){
        throw  e?.response?.data?.error[0] || e;
}

})
