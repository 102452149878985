import React from 'react';
import { Form, Checkbox } from 'antd';

const { Item } = Form;

const CampaignOther = ({ values, setFieldValue, campaignType }) => {

    const options = [
        {
            key: "1",
            title: "Target Google Search",
            value: values.target_google_search,
            name: "target_google_search",
        },
        {
            key: "2",
            title: "Target Search Network",
            value: values.target_search_network,
            name: "target_search_network",
        },
        {
            key: "3",
            title: "Target Partner Search Network",
            value: values.target_partner_search_network,
            name: "target_partner_search_network",
        },
        {
            key: "4",
            title: "Target Content Network",
            value: values.target_content_network,
            name: "target_content_network",
        }
    ];

    return (
        <div className='form-main'>
            <div>
            <h2 className='campaign_name'> Target Campaign </h2><br></br>
                {campaignType === 'SEARCH' ? (
                    options.map(option => (
                        <Item key={option.key} name={option.name}>
                            <Checkbox
                                checked={option.value}
                                onChange={(e) => setFieldValue(option.name, e.target.checked)}
                            >
                            <span style={{ marginLeft: "10px" }}>{option.title}</span>
                            </Checkbox>
                        </Item>
                    ))
                ) : (
                    <Item name={options[3].name}>
                        <Checkbox
                            checked={options[3].value}
                            onChange={(e) => setFieldValue(options[3].name, e.target.checked)}
                        >
                        <span style={{ marginLeft: "10px" }}>{options[3].title}</span>
                        </Checkbox>
                        
                    </Item>
                )}
            </div>
        </div>
    );
};

export default CampaignOther;
