import React, {useState} from "react";
import {useDispatch} from "react-redux";
import Listing from "../../common/Listing";
import {Functions} from "../../common/functions";

const Keywords = () => {
    const [loading, setLoading] = useState(false);
    const [contentLoading,setContentLoading] = useState(true);
    const dispatch = useDispatch();
    const [keywords,setKeywords] = useState([]);
    const fetchCampaignDetails = async (selectedAccount) => Functions.getKeywordList(setContentLoading,setKeywords,selectedAccount,dispatch)

    return (
        <Listing
            contentLoading={contentLoading}
            loading={loading} data={keywords}
            setLoading={setLoading}
            fetchDetails={fetchCampaignDetails}
            isKeywordPage={true}
        />
    )


}


export default Keywords;