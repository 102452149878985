import {useState, useEffect, useContext} from "react";
import {Button, Col, Empty, Input, notification, Row, Select, Spin, Table, Switch, Modal, Space, Avatar} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Status,advertising_channel,delivery_method } from "../utils/enums";
import TableListing from "./TableListing";
import CampaignSelect from "./SelectBoxes/CampaignSelect";
import { RESPONSE_SERVICES } from "./Services";
import { useDispatch, useSelector } from "react-redux";
import {updateUrl} from "./updateUrl";
import {NavBarContext} from "../Components/Navbar/Navbar";
import {deleteCampaign} from "../redux/thunk/CampaignThunk";
import {DeleteOutlined} from "@ant-design/icons";
import {Functions} from "./functions";
import {convertToNumber, numberWithCommas} from "./UtilityFunctions";
const { Option } = Select;

const Listing = ({ data, loading, setLoading, contentLoading = false, fetchDetails,
    campaignPage = false, adgroup = false, adGroupWithParams = false,
    campaignIds, getAdsDetails, adGroupIds, getAdList, adPage = false, isKeywordPage = false,paramsCampaignId,paramsCustomerId,adPageWithParams,
    budgetPage=false }) => {


    const searchParams = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { linkedAccount } = useSelector((state) => state.linkedAccounts);
    const {setParamsCampaignId,setParamsCustomerId,setIsVisible,setInitialRender}=useContext(NavBarContext);
    // for ads page
    const keysToShowFirst=["marketing_images","logo_images","square_marketing_images","square_logo_images","youtube_videos",'type','id'];



    /**
     * States
     */
    const [defaultCampaignId, setDefaultCampaignId] = useState(searchParams.get('campaign_id')==="null"? null : searchParams.get('campaign_id'));
    const [defaultCustomerId, setDefaultCustomerId] = useState(searchParams.get('customer_id')==="null"? null : searchParams.get('customer_id'));
    const [selectedAccount, setSelectedAccount] = useState(searchParams.get('customer_id')==="null"? null : searchParams.get('customer_id'));
    const [sortedInfo, setSortedInfo] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    // const [currentPage, setCurrentPage] = useState(1);
    const [searchedData, setSearchedData] = useState(data);
    const [selectedCampaignId, setSelectedCampaignId] = useState({id:searchParams.get('campaign_id')==="nul"?null:searchParams.get('campaign_id'),name:""});
    const [selectedAdGroupId, setSelectedAdGroupId] = useState(null);



    /**
     * Function to handle Search
     */
    const handleSearch = () => {
        const searchTermWithoutSpaces = searchTerm.replace(/\s/g, '').toLowerCase();
        const filteredData = data?.filter(val => {
            const nameWithoutSpaces = (val?.name || '').toString().replace(/\s/g, '').toLowerCase();
            const id = (val?.id || '').toString()?.toLowerCase(); // Added check for id
            const resourceName = (val?.resourceName || '').toString().replace(/\s/g, '').toLowerCase();
            const index = (val?.index || '').toString().toLowerCase();
            const text = (val?.text || '').toString().replace(/\s/g, '').toLowerCase();
            // Add more fields if needed for search
            return nameWithoutSpaces.includes(searchTermWithoutSpaces) || 
                   id.includes(searchTermWithoutSpaces) || 
                   resourceName.includes(searchTermWithoutSpaces) ||
                   index.includes(searchTermWithoutSpaces) ||
                   text.includes(searchTermWithoutSpaces);
        });
        setSearchedData(filteredData);
    }

    /**
     * Delete Campaign
     */
    const handleDelete=async (record)=>{
        try{
            setLoading(true);
            const res=await dispatch(deleteCampaign({campaign_id:record?.id?.toString(),customer_id:defaultCustomerId}));
            if(res?.type==="account/campaignDelete/fulfilled"){
                notification.success({message:"Successfully deleted account"});
                fetchDetails(defaultCustomerId);
            }                    
            Modal.destroyAll();
            setLoading(false);
        } catch (e) {
            RESPONSE_SERVICES.errorService(e);
            setLoading(false);
        }
    }

    /**
     * On Search Term change
     */
    useEffect(() => {
        if (!searchTerm || searchTerm === '') {
            setSearchedData(data);
        } else {
            handleSearch();
        }
    }, [searchTerm, data]);

    /**
     * When Campaign Ids are present update url
     */
    useEffect(() => {
        if (campaignIds && campaignIds?.length) {
            setSelectedCampaignId(campaignIds[0]);
            setDefaultCampaignId(campaignIds[0]?.id);
            updateUrl(campaignIds[0]?.id,defaultCustomerId)
            setParamsCustomerId(defaultCustomerId);
            setParamsCampaignId(campaignIds[0]?.id)
        }
    }, [campaignIds])

    /**
     * Add Group Id SetState on data present
     */
    useEffect(() => {
        if (adGroupIds && adGroupIds?.length) {
            setSelectedAdGroupId(adGroupIds[0]);
            getAdList(adGroupIds[0]?.id?.toString(), selectedAccount);

        }
    }, [adGroupIds]);

    /**
     * fetch Ads Details if CampaignId and CustomerId is present
     */
    useEffect(() => {
        if (((selectedCampaignId && selectedCampaignId?.id && selectedCampaignId?.id!=='null')) && selectedAccount && adgroup && !adPageWithParams) {
            getAdsDetails(selectedCampaignId, selectedAccount);
           if(selectedCampaignId?.id!==defaultCampaignId){
               setDefaultCampaignId(selectedCampaignId?.id);
               updateUrl(selectedCampaignId?.id,defaultCustomerId);
               setParamsCustomerId(defaultCustomerId);
               setParamsCampaignId(selectedCampaignId?.id)
           }

        }
    }, [selectedCampaignId]);

    /**
     * When no account is present in Params
     */
    useEffect(() => {
        if(!selectedAccount){
          if(campaignPage) {
              setInitialRender(true);
          }
          setIsVisible(true);
        } else {
            if(!localStorage.getItem("linkedAccounts")){
                Functions.getLinkedAccount(null,null,navigate,dispatch)
            }
        }
    }, [selectedAccount]);

    /**
     * Fetch Campaign based on Selected Account
     */
    useEffect(() => {
           if(selectedAccount && (!adgroup || !defaultCampaignId)){
               setDefaultCustomerId(selectedAccount);
               fetchDetails(selectedAccount);
               updateUrl(defaultCampaignId,selectedAccount);
               setParamsCustomerId(selectedAccount);
               setParamsCampaignId(defaultCampaignId);
           }

    }, [selectedAccount]);

    /**
     * Reset Search Data
     */
    useEffect(() => {
        setSearchTerm('');
        setSearchedData(data);
    }, [data]);

    /**
     * Function to Capitalize table headers
     * @param str
     * @returns {string}
     */
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    /**npm
     * Getting Columns for Table
     */
    let columns = searchedData && searchedData?.length ?
        Object.keys(searchedData[0]).map((key, index) => {
            if (key !== 'resource_name' && !keysToShowFirst.includes(key)) {
                return getColumnConfig(key);
            }
        }).filter(column => column) : [];


    /**
     * Adding keys to the left which are necessary
     */
    keysToShowFirst.map(val=>{
        if(searchedData && searchedData.length && searchedData[0][val]!==undefined){
            columns.unshift(getColumnConfig(val));
        }
    })

    /**
     * Give different properties to columns
     */
    function getColumnConfig(key) {
        const columnConfig = {
            title: capitalizeFirstLetter(key.replace(/_/g, " ")),
            dataIndex: key,
            key: key,
            width: !budgetPage && 150,
            sorter: (a, b) => a[key].toString().localeCompare(b[key].toString()),
            sortOrder: sortedInfo.columnKey === key && sortedInfo.order,
            sortDirections: ['ascend', 'descend'],
            render: (text, record) => {
                if (key === 'status') {
                    return Status[(record.status || 0) % 5];
                } else if (typeof record[key] === 'number') {
                    let t = parseFloat((key === 'optimization_score' || key === 'ctr') ? (text * 100).toFixed(2) : text.toFixed(2));
                    if (key.includes('micros') || key === 'average_cpc' || key === 'cost_per_conversion') {
                        let temp = convertToNumber(text);
                        if (!temp) return '-';
                        else return temp;
                    }
                    if (t === 0) {
                        return '-';
                    } else if (key === 'optimization_score' || key === 'ctr') {
                        return (t + '%');
                    } else {
                        return t;
                    }
                } else if (text === 0 || text === '0') {
                    return '-';
                } else {
                    return text?text.toString():'-';
                }
            },
            ...(key === 'name') && {
                onCell: (record) => ({
                    onClick: (event) => {
                        let account = paramsCustomerId || selectedAccount;
                        let cid = paramsCampaignId || selectedCampaignId;
                        if (campaignPage) {
                            setLoading(true);
                            navigate(`/adgroup/list?customer_id=${account}&campaign_id=${record?.id}`)
                        } else if (adgroup) {
                            navigate(`/ads/list?customer_id=${account}&campaign_id=${cid?.id || cid}&group_id=${record?.id}`);
                        }
                    },
                    style: { cursor: 'pointer' }
                })
            },
            // show $ sign for budget
            ...(key === 'campaign_budget' && {
                render: (text, record) => {
                    return `$ ${numberWithCommas(convertToNumber(text))}`;
                },
            }),
            // Changing name to Cost
            ...(key === 'cost_micros' && {
                title: "Cost"
            }),
            // show switch on and off in target_column_search
            ...((key === 'target_google_search' || (key === 'target_search_network') || (key === 'target_content_network')) && {
                render: (text, record) => {
                    return text ? <Switch defaultChecked disabled className="switch" /> : <Switch disabled />;
                },
            }),
            ...(key === 'advertising_channel' && {
                title: "Campaign Type",
                render: (text, record) => {
                    return advertising_channel[text];
                },
            }),
            ...(key === 'delivery_method' && {
                render: (text, record) => {
                    return delivery_method[text];
                },
            }),
            // change amount_macros to Amount
            ...(key === 'amount_micros' && {
                title: 'Amount',
                render: (text, record) => {
                    return `$ ${convertToNumber(text)}`;
                },
            }),
            ...(key === 'impressions' && {
                render: (text, record) => {
                    return text > 0 ? numberWithCommas(text) : "-";
                },
            }),
            ...(key === 'status' && {
                filters: [
                    { text: 'Enabled', value: 2 },
                    { text: 'Paused', value: 3 },
                    { text: 'Removed', value: 4 },
                    { text: 'Unknown', value: 1 },
                    { text: 'Unspecified', value: 0 },
                ],
                onFilter: (value, record) => record.status === value,
            }),
            ...(key!=='type' && key!=='id' && keysToShowFirst.includes(key) && {
                render: (text, record) => {
                    if(text && text.length){
                        return text.map(val=><Avatar src={val.url}/>)
                    }
                    return '-'
                }
            })
        };
        return columnConfig;
    }


    /**
     * Adding Delete button on Columns in Campaign page
      */
    if(campaignPage){
        columns=[
            ...columns,
            {
                title: 'Action',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (record) => <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({                    
                    title: 'Confirm',
                    content: 'Are you sure you want to delete this campaign?',
                    className:'delete-modal',
                    footer: (_, { CancelBtn}) => (
                      <div style={{marginTop:"30px"}}>                        
                        <CancelBtn />
                        <Button style={{background:"tomato",color:"#fff",border:"none"}} onClick={()=>{handleDelete(record);}}>
                           {loading?<Spin/>:"Delete"}
                        </Button>                       
                      </div>
                    ),
                  });
                }}
              >
                    <DeleteOutlined />
              </Button>
            },
        ]
    }


    /**
     * Handle Table Data Change
     */
    const handleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
    };

    /**
     * Function to Change Selected Campaign
     */
    const handleCampaignSelectChange = (val, record) => {
        setSearchedData([]);
        setSelectedCampaignId({ name: record?.children, id: val });
    }

    /**
     * Function to Change AddGroup Change
     * @param val
     * @param record
     */
    const handleAddGroupSelectChange = (val, record) => {
        setSearchedData([]);
        setSelectedAdGroupId({ name: record?.children, id: val });
        getAdList(val, selectedAccount);
    }

    return (
        <Spin spinning={loading} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', maxWidth: '80vw' }}>
            <Col style={{ minWidth: "100%", minHeight: "100%" }} className="container" justify="start" align="start">
                <Row style={{ padding: "2%", minWidth: "100%",alignItems:"center" }} justify="space-between">
                    <Col>
                            <Row align="middle" justify="space-between">
                                <Col style={{ marginRight: '10px' }}>
                                    {!!contentLoading === false && <div className="search_box">
                                        <Input
                                            type="text"
                                            placeholder='Search'
                                            rootClassName={"custom-input-field"}
                                            value={searchTerm}
                                            onChange={(val) => setSearchTerm(val.target.value)}
                                            suffix={<SearchOutlined style={{ color: '#969696' }} />}
                                        />
                                    </div>}

                                </Col>
                            </Row>

                    </Col>

                    <Col width={'70vw'}>
                        <Row align="middle" justify="space-between" style={{ gap: "20px" }}>
                            <Col>
                            
                                {
                                    !!(adGroupIds && adGroupIds?.length) && <Col style={{display:"flex",flexDirection:"column",gap:"3px"}}>
                                        <span style={{fontSize:"12px"}}>Select Ad Group</span>
                                        <CampaignSelect selectedCampaign={selectedAdGroupId} options={adGroupIds} handleSelect={handleAddGroupSelectChange} position="header" />
                                    </Col>}
                            </Col>
                            <Col>
                           
                                {
                                    !!(campaignIds && campaignIds?.length) && <Col style={{display:"flex",flexDirection:"column",gap:"3px"}}>

                                         <span style={{fontSize:"12px"}}>Select Campaign</span>
                                        <CampaignSelect selectedCampaign={selectedCampaignId} options={campaignIds} handleSelect={handleCampaignSelectChange} position="header" />
                                    </Col>}
                            </Col>

                            {/*{!!linkedAccount && !!linkedAccount.length && !!(!adGroupWithParams) && !!(!adPageWithParams) && !!(!selectedAccount) && <Col style={{display:"flex",flexDirection:"column",gap:"3px"}} position="header">*/}
                            {/*    <span style={{fontSize:"12px"}}>Select Client ID</span>*/}
                            {/*    <Select value={selectedAccount} className={'single_select select-nav'} onSelect={(val) => {*/}
                            {/*        setSelectedAccount(val);*/}
                            {/*    }}>*/}
                            {/*        {linkedAccount?.map((option, index) => (*/}
                            {/*            <Option key={index} value={option.split("/")[1]}>*/}
                            {/*                {option.split("/")[1]}*/}
                            {/*            </Option>*/}
                            {/*        ))}*/}
                            {/*    </Select>*/}
                            {/*</Col>}*/}
                            <Col>
                                {!!(selectedAccount || adgroup) && <Link to={campaignPage ? `/campaign/add?campaign_id=${selectedAccount}` : adgroup && !adPage ? '/adgroup/create' : adPage ? '/ads/create' : isKeywordPage ? '/keyword/create' : '/budget/create'} style={{ textDecoration: "none", paddingTop: "1%" }}>
                                    <Button type={"primary"} className={'primary-btn add-button'}>
                                        <PlusOutlined /> {campaignPage ? 'Add Campaign' : adgroup && !adPage ? 'Add Ad Group' : adPage ? 'Create Ad' : isKeywordPage ? 'Add Keywords' : 'Add Budget'}
                                    </Button>
                                </Link>}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row justify="center">
                    <div style={{ overflow: 'auto' }}>
                        <Spin spinning={!loading && contentLoading}>
                            {searchedData && searchedData.length ? (
                                <div className={'custom-table'}>
                                    <TableListing columns={columns} handleChange={handleChange} searchedData={searchedData} />
                                </div>
                            ) : !contentLoading ? (
                                <Empty description={<span>No Campaign Data Available</span>} />
                            ) : <div style={{ width: '200px', height: '100px' }}></div>
                            }
                        </Spin>
                    </div>
                </Row>
            </Col>       
        </Spin>
    );
}

export default Listing;
