import {createSlice} from "@reduxjs/toolkit";
import {handleAsyncAction} from "../common/AsyncCases";
import {createAds, listAds} from "../thunk/AdsThunk";


const AdsSlice = createSlice({
    name: "ads",
    initialState: {
        adList:[],
        isLoading:false,
        error:null
    },
    reducers:{},
    extraReducers:(builder)=>{
        handleAsyncAction(builder,listAds,(state, action)=>{
            state.adList=action?.payload?.data?.ads || [];
        });


    }

})

export default AdsSlice.reducer