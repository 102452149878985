import React from 'react';
import TextInput from '../../../common/TextInput';
import SelectInput from '../../../common/SelectBoxes/SelectInput';
import "../../../styles/campaignForm/CampaignName.css"
import {CampaignStatus} from "../../../common/enums";
const CampaignName = ({
                            values,
                            errors,
                            touched,
                            handleChange,
                      }) => {

    return (
        <div className="form-main">
            <div>
                <div className='form-heading-logo'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4 6H20M7 12H20M10 18H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h2 className='campaign_name'>Campaign Detail</h2>
                </div>
                <div className='campaign-name-form-main'>
                    <div>
                        <p><span className='error-asterisk-sign'>*</span> Campaign Name</p>
                        <TextInput
                            value={values.campaign_name}
                            handleChange={handleChange}
                            name='campaign_name'
                            validateStatus={errors?.campaign_name && touched?.campaign_name ? "error" : ""}
                            help={errors?.campaign_name && touched?.campaign_name ? errors?.campaign_name : ""}
                        />
                    </div>
                    <div>
                        <p><span className='error-asterisk-sign'>*</span> Campaign Status</p>
                        <SelectInput
                            value={values.campaign_status}
                            handleChange={handleChange}
                            name='campaign_status'
                            options={CampaignStatus}
                            validateStatus={errors?.campaign_status && touched?.campaign_status ? "error" : ""}
                            help={errors?.campaign_status && touched?.campaign_status ? errors?.campaign_status : ""}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CampaignName;