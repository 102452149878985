import React from 'react';
import {Avatar, Button, Card} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {logout} from "../../redux/slices/userSlice";

const { Meta } = Card;

const AccountPage = () => {
    // Dummy user data
    //type dispay ,Search -> bidding stratergy
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout=()=>{
        dispatch(logout());
        navigate('/login')
    }
    const {user}=useSelector((state)=>state.user);
    return (
        <div style={{minHeight: '100vh', minWidth:'80vw',alignItems: 'center',justifyContent:'center',display:'flex' }}>
            <Card style={{ width: '30%',minHeight:'60vh',alignItems: 'center',justifyContent:'center',display:'flex'}}>
                <Meta
                    avatar={<Avatar src={user?.picture} />}
                    title={user?.name}
                    description={user?.email}
                />
               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'20px'}}>
                   <Button style={{width:"50%"}} type={'primary'} onClick={handleLogout}>Logout</Button>
               </div>
            </Card>
        </div>
    );
};

export default AccountPage;
