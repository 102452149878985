import React, {useEffect, useRef} from 'react';
import {Form} from "antd";
import {Formik, useFormik, useFormikContext} from 'formik';
import CampaignName from './FormComponents/CampaignName';
import CampaignObjective from './FormComponents/CampaignObjective';
import CampaignType from './FormComponents/CampaignType';
import CampainBudget from './FormComponents/CampaignBudget';
import CampaignOther from './FormComponents/CampaignOther';
import CampaignDuration from './FormComponents/CampaignDuration';

const {Item} = Form;

const CampaignForm = ({campaignForm, data, setNewCampaignValues, toSave, current, setCurrent}) => {
    const formRef = useRef(null);
    let savedData = localStorage.getItem('campaignInfo');
    if (savedData) {
        savedData = JSON.parse(savedData);
    }
    let isCampaignPresent = localStorage.getItem('campaignId');

    return (
        <div style={{marginTop: "20px"}}>
            <Formik
                initialValues={
                    savedData || data
                }
                validationSchema={campaignForm.validationSchema}
                onSubmit={campaignForm.handleSubmit}
            >
                {({values, handleChange, setFieldValue, handleSubmit, errors, touched}) => {
                    if (toSave && current === 0) {
                        localStorage.setItem("campaignInfo", JSON.stringify(values));
                        setCurrent(toSave);
                    }
                    return (
                        <Form layout="vertical" onFinish={handleSubmit} style={{
                            padding: '20px',
                            display: 'grid',
                            gap: '20px'
                        }}>
                            <CampaignName
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                               />
                            {
                                !!(!isCampaignPresent) && <>
                                    <CampaignObjective
                                        handleChange={handleChange} value={values.campaign_objective}
                                    />

                                    <CampaignType
                                        value={values.advertising_channel_type}
                                        handleChange={handleChange}
                                        validateStatus={errors.advertising_channel_type && touched.advertising_channel_type ? "error" : ""}
                                        help={errors.advertising_channel_type && touched.advertising_channel_type ? errors.advertising_channel_type : ""}
                                    /></>
                            }


                            {values.advertising_channel_type && <CampaignOther
                                setFieldValue={setFieldValue}
                                values={values}
                                campaignType={values.advertising_channel_type}
                            />}

                            {!!(!isCampaignPresent) &&<CampaignDuration
                                setFieldValue={setFieldValue}
                                values={values}
                                errors={errors}
                                touched={touched}
                            />}
                            <Item style={{float: "right", position: "relative", right: "0"}}>
                                <button className="next-button" type="submit">
                                    Continue
                                </button>
                            </Item>
                        </Form>
                    )
                }}
            </Formik></div>
    );
}

export default CampaignForm;