// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-title {
  font-size: 1.5rem;
  color: #333;
}

.ant-modal-body {
  font-size: 1.2rem;
}

.ant-btn-primary {
  padding: 0px 25px;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.ant-modal-content {
  height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/styles/AdsForm/AdsForm.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,sCAAsC;EACtC,kCAAkC;EAClC,WAAW;AACb;AACA;EACE,kCAAkC;EAClC,2BAA2B;AAC7B;;AAEA;EACE,sCAAsC;EACtC,kCAAkC;EAClC,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".ant-modal-title {\n  font-size: 1.5rem;\n  color: #333;\n}\n\n.ant-modal-body {\n  font-size: 1.2rem;\n}\n\n.ant-btn-primary {\n  padding: 0px 25px;\n  background-color: var(--primary-color);\n  border-color: var(--primary-color);\n  color: #fff;\n}\n.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {\n  border-color: var(--primary-color);\n  color: var(--primary-color);\n}\n\n.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {\n  background-color: var(--primary-color);\n  border-color: var(--primary-color);\n  color: #fff;\n}\n\n.ant-modal-content {\n  height: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
