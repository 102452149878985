import { getLinkedAccount } from "../redux/thunk/linkedAccountThunk";
import { LINKED_ACCOUNTS_FULFILLED, LINKED_ACCOUNTS_REJECTED } from "../redux/types/linkedAccountActionTypes";
import { RESPONSE_SERVICES } from "./Services";
import { getCampaignInfo, getCampaignsWithoutStoring, listAdGroup } from "../redux/thunk/CampaignThunk";
import {
    CREATE_LIST_AD_INFO,
    GET_CAMPAIGN_INFO_FULFILLED,
    GET_CAMPAIGN_INFO_REJECTED,
    LIST_AD_GROUP_FULFILLED
} from "../redux/types/CampaignActionTypes";
import { getKeywordList } from "../redux/thunk/KeywordThunk";
import { listBudget } from "../redux/thunk/BudgetThunk";
import { LIST_BUDGET_FULFILLED, LIST_BUDGET_REJECTED } from "../redux/types/BudgetActionTypes";
import { listAds, uploadAsset } from "../redux/thunk/AdsThunk";
import { UPLOAD_ASSET_FULFILLED, UPLOAD_IMAGE_FULFILLED } from "../redux/types/AdsActionTypes";


export const Functions = {
    getLinkedAccount: async (setLoading, setSelectedAccount, navigate, dispatch, setItem = null) => {
        try {
            if (setLoading) setLoading(true);
            let data = localStorage.getItem("login");
            data = JSON.parse(data);
            const res = await dispatch(getLinkedAccount({ email: data?.email }))
            if (res?.type === LINKED_ACCOUNTS_FULFILLED) {
                if (res?.payload?.accounts?.resourceNames) {
                    if (setSelectedAccount) {
                        setSelectedAccount(res?.payload?.accounts?.resourceNames[0].split("/")[1]);
                    }
                    localStorage.setItem('linkedAccounts', JSON.stringify(res?.payload?.accounts?.resourceNames));
                    if (setItem) setItem(res?.payload?.accounts?.resourceNames);
                } else {
                    navigate('/noaccount');
                }
                let customerIds = {};
                for (const [key, value] of Object.entries(res?.payload?.accounts)) {
                    if (key !== 'resourceNames') {
                        customerIds[key] = value;
                    }
                }
                localStorage.setItem("AccountDescriptions", JSON.stringify(customerIds));
            }
            if (res?.type === LINKED_ACCOUNTS_REJECTED) {
                RESPONSE_SERVICES.errorService(res.error);
            }
            if (setLoading) setLoading(false)
        } catch (e) {
            if (setLoading) setLoading(false);
            RESPONSE_SERVICES.errorService(e);

        }
    },
    fetchCampaignDetails: async (selectedAccount, setContentLoading, dispatch) => {
        try {
            setContentLoading(true);
            const res = await dispatch(getCampaignInfo({ campaignId: selectedAccount }));
            if (res?.type === GET_CAMPAIGN_INFO_FULFILLED) {
            }
            if (res?.type === GET_CAMPAIGN_INFO_REJECTED) {
                RESPONSE_SERVICES.errorService(res.error);
            }
            setContentLoading(false);
        } catch (e) {
            setContentLoading(false);

            RESPONSE_SERVICES.errorService(e);
        }
    },
    fetchCamp: async (selectedAccount, setContentLoading, dispatch, setCamp) => {
        try {
            setContentLoading(true);
            const res = await dispatch(getCampaignsWithoutStoring({ campaignId: selectedAccount }));
            if (res?.type === 'account/getCamp/fulfilled') {
                setCamp(res?.payload?.data?.campaigns);
            }
            if (res?.type === 'account/getCamp/rejected') {
                RESPONSE_SERVICES.errorService(res.error);
            }
            setContentLoading(false);
        } catch (e) {
            setContentLoading(false);

            RESPONSE_SERVICES.errorService(e);
        }
    },
    getAdGroupList: async (setLoading, selectedCampaignId, selectedCustomerId, setAdGroupList, dispatch) => {
        setLoading(true);
        try {
            const body = {
                "campaign_id": selectedCampaignId,
                "customer_id": selectedCustomerId,
            }
            const res = await dispatch(listAdGroup(body));
            if (res?.type === LIST_AD_GROUP_FULFILLED) {
                if (res?.payload?.data?.ad_groups) {
                    if (setAdGroupList) setAdGroupList(res?.payload?.data?.ad_groups);
                }
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            RESPONSE_SERVICES.errorService(e);

        }
    },
    getKeywordList: async (setContentLoading, setKeywordData, selectedAccount, dispatch) => {
        try {
            setContentLoading(true);
            setKeywordData([]);
            const res = await dispatch(getKeywordList({ customer_id: selectedAccount }));
            if (res?.type === CREATE_LIST_AD_INFO) {
                setKeywordData(res?.payload?.data?.keywords || []);
            }
            setContentLoading(false);
        } catch (e) {
            setContentLoading(false);
            setKeywordData([]);
            RESPONSE_SERVICES.errorService(e);
        }
    },
    getBudgetList: async (setContentLoading, setBudgetData, selectedAccount, dispatch) => {
        try {
            setContentLoading(true);
            setBudgetData([]);
            // const res = await Budget.getBudgets(selectedAccount);
            const res = await dispatch(listBudget({ id: selectedAccount }));
            if (res?.type === LIST_BUDGET_FULFILLED) {
                setBudgetData(res?.payload?.data?.budgets);
            }
            if (res?.type === LIST_BUDGET_REJECTED) {
                RESPONSE_SERVICES.errorService(res.error);
            }
            setContentLoading(false);
        } catch (e) {
            setContentLoading(false);
            setBudgetData([]);
            RESPONSE_SERVICES.errorService(e);
        }
    },
    getAdList: async (setContentLoading, gid, customerId, dispatch, setAdList) => {
        try {
            setContentLoading(true);
            const body = {
                "ad_group_id": gid?.toString(),
                "customer_id": customerId,
            }
            const res = await dispatch(listAds(body));
            if (res?.type === 'account/listAds/fulfilled') {
                setAdList(res?.payload?.data?.ads);
            } else {
                RESPONSE_SERVICES.errorService(res?.error);
            }
            setContentLoading(false);
        } catch (e) {
            setContentLoading(false);
            RESPONSE_SERVICES.errorService(e)
        }
    },
    handleAssetSubmit: async (url, dispatch, notification, setShowModal, setUrl, asset_type, setIsUpload) => {
        const adGroup = localStorage.getItem('adGroupData');
        const adGroupData = JSON.parse(adGroup);
        const customer_id = adGroupData?.customer_id;
        const video = asset_type === "YOUTUBE_VIDEO" ? true : false;
        const apiData = video ? uploadAsset({ "youtube_video_url": url, customer_id, asset_type }) : uploadAsset({ "image_url": url, customer_id, asset_type })
        const res = await dispatch(apiData)
        if (res?.type === UPLOAD_ASSET_FULFILLED) {
            notification.success({
                message: 'Success',
                description: res?.payload?.message,
            });
            // setShowModal(false);
            setUrl('')
            setIsUpload(true);
        }
        if (res?.error) {
            notification.error({
                message: 'Error',
                description: res?.error?.message
            });
        }
    },
    // Define the base64ToBlob function
    base64ToBlob: (base64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    },

    // Now you can use the base64ToBlob function in your saveChanges function

    handleAssetImageSubmit: async (file, dispatch, uploadAssetImage, notification, setIsUpload, formikField, setLoading, isLogo) => {
        setLoading(true);
        const adGroup = localStorage.getItem('adGroupData');
        const adGroupData = JSON.parse(adGroup);
        const customer_id = adGroupData?.customer_id;
        const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms)
        );
        await timeout(1000);
        const res = await dispatch(uploadAssetImage({ customer_id, file }));
        if (res.type === UPLOAD_IMAGE_FULFILLED) {
            notification.success({
                message: 'Success',
                description: res?.payload?.message,
            });
            // convert res?.payload?.data?.asset_resource_name to array
            const asset_resource_name = [res?.payload?.data?.asset_resource_name];
            formikField && formikField(asset_resource_name);           
            if (isLogo) {
                const logoItems = JSON.stringify([{ url: res?.payload?.data?.asset[0].url }])
                localStorage.setItem('logoList', logoItems);
                // let logoList = localStorage.getItem('logoList');            
                // const logoItem = { url: res?.payload?.data?.asset[0].url };
                // if (logoList !== null) {
                //     logoList = JSON.parse(logoList);
                //     logoList.push(logoItem);
                // } else {
                //     logoList = [logoItem];
                // }
                // localStorage.setItem('logoList', JSON.stringify(logoList));
            }
            else {                
                const imageItem = { url: res?.payload?.data?.asset[0].url };
                let imageList = localStorage.getItem('imageList');
                if (imageList !== null) {
                    imageList = JSON.parse(imageList);
                    imageList.push(imageItem);
                } else {
                    imageList = [imageItem];
                }
                localStorage.setItem('imageList', JSON.stringify(imageList));
            }
            setIsUpload(true);
        }
        if (res.error) {
            notification.error({
                message: 'Error',
                description: res.error.message
            });
        }
        setLoading(false);
    }
}
