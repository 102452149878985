import api from "../../api/api";


export const AdsActionTypes = {
    createAds:async (body)=>{
        return await api.post('/api/v1/ad/create',body);
    },
    createAdsDisplay:async (body)=>{
        return await api.post('/api/v1/ad/create/display',body);
    },
    listAds:async (body)=>{
        return await api.post('/api/v1/ad/list',body);
    },
    uploadImage:async (body)=>{
        return await api.post('/api/v1/assets/upload',body,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    uploadAsset:async (body)=>{
        return await api.post('/api/v1/assets/create',body);
    },
    listAssets:async (body)=>{
        return await api.post('/api/v1/assets/list',body);
    },
}