import React, {useContext, useEffect, useState} from "react";
import {NavBarContext} from "../../Navbar/Navbar";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {Functions} from "../../../common/functions";
import {createAdGroup} from "../../../redux/thunk/CampaignThunk";
import {CREATE_AD_GROUP_FULFILLED, CREATE_AD_GROUP_REJECTED} from "../../../redux/types/CampaignActionTypes";
import {Form, notification, Spin} from "antd";
import {RESPONSE_SERVICES} from "../../../common/Services";
import ReusableAddForm from "../../../common/ReusableAddForm";
import {Formik} from "formik";
import AdGroupDetails from "../AdGroupFormComponents/AdGroupDetails";
import AdGroupKeywords from "../AdGroupFormComponents/AdGroupKeywords";

const { Item } = Form;

 const CreateSeperateAddCampaign=()=>{
    const {getPath}=useContext(NavBarContext)
    const {linkedAccount}=useSelector((state) => state.linkedAccounts);
    const {campaigns}=useSelector((state) => state.campaigns);
    const [loading, setLoading] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [keywords, setKeywords] = useState([]);
    const [contentLoading, setContentLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        customer_id: Yup.string().required("Customer ID is required"),
        campaign_id: Yup.string().required("Campaign Name is required"),
        name: Yup.string().required("Ad Group Name is required"),
        ad_budget: Yup.number()
            .required("Ad Budget is required")
            .positive("Ad Budget must be positive")
            .integer("Ad Budget must be an integer").max(10000, "Budget must be less than 100"),
        keywords: Yup.string().min(3, "Atleast one keyword is required"),
    });



    const getCustomerId = async (val) => Functions.fetchCampaignDetails(val,setContentLoading,dispatch);

    /**
     * Get Linked Accounts
     */
    useEffect(() => {
        Functions.getLinkedAccount(setLoading,null,navigate,dispatch)
    }, []);

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        let body = { ...values, keywords: keywords };
        try {
            const res = await dispatch(createAdGroup(body));
            if (res?.type === CREATE_AD_GROUP_FULFILLED) {
                notification.success({ message: "Successfully created Ad Group" });
                navigate(getPath('/adgroup/list'));

            }
            if (res?.type === CREATE_AD_GROUP_REJECTED) {
                RESPONSE_SERVICES.errorService(res.error);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            RESPONSE_SERVICES.errorService(e);
        }
    };

    const handleSelectCampaign = (value, key, handleChange) => {
        handleChange("campaign_id")(value.toString());
        setSelectedCampaign(key?.children);
    }

    return (
        <ReusableAddForm contentLoading={contentLoading}>
            <Formik
                initialValues={{
                    customer_id: "",
                    campaign_id: "",
                    name: "",
                    ad_budget: "",
                    keywords: [],
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, errors, touched }) => (
                    <Form layout="vertical" onFinish={handleSubmit} style={{
                        paddingTop: '20px',
                        display: 'grid',
                        gap: '20px'
                    }}
                    >
                        <AdGroupDetails
                            values={values}
                            customerOptions={linkedAccount?.map((value) => {return {label: value?.toString()?.split("/")[1], value: value?.toString()?.split("/")[1]}})}
                            getCustomerId={getCustomerId}
                            campaignId={campaigns}
                            errors={errors}
                            touched={touched}
                            CampaignHandleSelect={handleSelectCampaign}
                            CampaignSelectedCampaign={selectedCampaign}
                            handleChange={handleChange}
                        />
                        <AdGroupKeywords
                            keywordsValue={values.keywords}
                            handleKeywordChange={handleChange}
                            keywords={keywords}
                            setKeywords={setKeywords}
                            keywordsError={errors.keywords}
                            keywordsTouched={touched.keywords}
                            errors={errors}
                        />
                        <Item>
                            <button className="next-button loading-enabled-next-button" type="submit">
                                {loading ? <Spin /> : "Submit"}
                            </button>
                        </Item>
                    </Form>
                )}
            </Formik>
        </ReusableAddForm>
    );
}



export default CreateSeperateAddCampaign;