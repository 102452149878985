import { useEffect, useState } from "react";
import MultitextInput from "../../../common/MutlitextInput";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";

const KeywordsListing = ({ items, name, keywords, setKeywords, typeItems, setTypeItems }) => {
  const handleDeselect = (name, value) => {
    let val = value;
    if (name === "phrase_match") {
      val = `(${value})`;
    } else if (name === "exact_match") {
      val = `[${value}]`;
    }
    const newKeywords = keywords.filter((item) => item !== val);
    let updatedItems = typeItems?.filter((item) => item !== value);
    setTypeItems(updatedItems);
    setKeywords(newKeywords);
  };
  return (
    <div style={{ display: "flex", flexWrap:  'wrap', gap: "12px", marginTop: "5px"}}>
      {items?.map((value, index) => {
        return (
          <Tooltip placement="top" title={value}>
          <div
            key={index}
            style={{
              display: "flex",
              gap: "12px",
              border: "1px solid #CAB8FF",
              borderRadius: "3px",
              background: "#CAB8FF",
              padding: "3px 6px",
            }}
          >
            <span style={{ color: "#8833FF", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px'}}>{value}</span>
            <CloseCircleTwoTone
              onClick={() => handleDeselect(name, value)}
              style={{ cursor: "pointer" }}
            />
          </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

const AdGroupKeywords = ({
  keywordsValue,
  handleKeywordChange,
  keywords,
  setKeywords,
    keywordsError,
    keywordsTouched,errors
}) => {
  const broad = [];
  const phrase = [];
  const exact = [];
  if(Array.isArray(keywords)){
    keywords?.map((keyword) => {
      if (keyword?.startsWith("(") && keyword?.endsWith(")")) {
        phrase.push(keyword?.replace(/[\[\]\(\)]/g, ''));
      } else if (keyword?.startsWith("[") && keyword?.endsWith("]")) {
        exact.push(keyword?.replace(/[\[\]\(\)]/g, ''));
      } else {
        broad.push(keyword?.replace(/[\[\]\(\)]/g, ''));
      }
    });
  }

  const [broadItems, setBroadItems] = useState(broad || []);
  const [phraseItems, setPhraseItems] = useState(phrase || []);
  const [exactItems, setExactItems] = useState(exact || []);
  const handleSelectedValue = (value, name) => {
    let val = value;
    if (name === "phrase_match") {
      val = `(${value})`;
    } else if (name === "exact_match") {
      val = `[${value}]`;
    }
    setKeywords((keyword) => [...keyword, val]);
  };
  const handleDeselectedValue = (value, name) => {
    let val = value;
    if (name === "phrase_match") {
      val = `(${value})`;
    } else if (name === "exact_match") {
      val = `[${value}]`;
    }
    const newKeywords = keywords.filter((item) => item !== val);
    setKeywords(newKeywords);
  };

  useEffect(() => {
    handleKeywordChange("keywords")(JSON.stringify(keywords));
  }, [keywords]);

  // console.log("keywords", broadItems, phraseItems, exactItems, keywords)

  return (
    <div className="form-main">
      <div>
        <div className="form-heading-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 6H20M7 12H20M10 18H20"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h2 className="campaign_name">Keywords</h2>
        </div>
        <p style={{fontSize: '12px'}}><span className='error-asterisk-sign'>*</span> Must fill one of the following</p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <div>
            <KeywordsListing
              items={broadItems}
              name="broad_spectrum"
              keywords={keywords}
              setKeywords={setKeywords}
              typeItems={broadItems}
              setTypeItems={setBroadItems}
            />
            <p>Broad Spectrum</p>
            <MultitextInput
              name="broad_spectrum"
              value={broadItems}
              handleSelectedValue={handleSelectedValue}
              handleDeselectedValue={handleDeselectedValue}
              handleChange={handleKeywordChange}
              setItems={setBroadItems}
              items={broadItems}
            />
          </div>
          <div>
            <KeywordsListing 
              items={phraseItems} 
              name="phrase_match"
              keywords={keywords}
              setKeywords={setKeywords}
              typeItems={phraseItems}
              setTypeItems={setPhraseItems}
            />
            <p>Phrase Match</p>
            <MultitextInput
              name="phrase_match"
              value={phraseItems}
              handleSelectedValue={handleSelectedValue}
              handleDeselectedValue={handleDeselectedValue}
              handleChange={handleKeywordChange}
              setItems={setPhraseItems}
              items={phraseItems} 
            />
          </div>
          <div>
            <KeywordsListing 
               items={exactItems} 
               name="exact_match"
               keywords={keywords}
               setKeywords={setKeywords}
               typeItems={exactItems}
               setTypeItems={setExactItems}
            />
            <p>Exact Match</p>
            <MultitextInput
              name="exact_match"
              value={exactItems}
              handleSelectedValue={handleSelectedValue}
              handleDeselectedValue={handleDeselectedValue}
              handleChange={handleKeywordChange}
              setItems={setExactItems}
              items={exactItems}
            />
          </div>

          {keywordsError && keywordsTouched && (
              <div className="error-message" style={{color:"tomato"}}>{errors.keywords}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdGroupKeywords;
