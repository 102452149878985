import Wrapper from "../../layout";
import {useContext, useEffect} from "react";
import { useNavigate} from 'react-router-dom';
import {NavBarContext} from "../Navbar/Navbar";
const HomePage=()=>{
    const navigate = useNavigate();
    const {getPath}=useContext(NavBarContext)
    useEffect(() => {
        const data=localStorage.getItem("login");
        if(data){
            navigate('Login');
        }else navigate(getPath("/campaign"));
    }, []);
    return <></>
}


// const HomePage=Wrapper(Home);

export default HomePage;