import {createSlice} from "@reduxjs/toolkit";
import {handleAsyncAction} from "../common/AsyncCases";
import {loginUser} from "../thunk/userThunk";


const userSlice = createSlice({
    name: "user",
    initialState: {
        user: JSON.parse(localStorage.getItem("login")) || null,
        isLoading: false,
        error: null,
        fulfilled:false
    },
    reducers:{
        logout:(state)=>{
            localStorage.clear()
            state.user = null;
        }
    },
    extraReducers:(builder)=>{
        handleAsyncAction(builder,loginUser,(state,action)=>{
          state.user=action.payload;
        })
    }
});

export const {logout} = userSlice.actions;

export default userSlice.reducer;