import React, {useState} from "react";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {RESPONSE_SERVICES} from "../../../common/Services";
import {createAdGroup, updateAdGroup} from "../../../redux/thunk/CampaignThunk";
import {Form, notification, Spin} from "antd";
import {CREATE_AD_GROUP_FULFILLED, CREATE_AD_GROUP_REJECTED} from "../../../redux/types/CampaignActionTypes";
import ReusableAddForm from "../../../common/ReusableAddForm";
import {Formik} from "formik";
import TextInput from "../../../common/TextInput";
import AdGroupKeywords from "../AdGroupFormComponents/AdGroupKeywords";
import BackandSubmit from "../../../common/BackandSubmit";

const { Item } = Form;

const CreateAdsCampaign = ({campaignId,setGroupId,setCurrent,toSave,setToSave,current}) => {
    const [loading, setLoading] = useState(false);

    const params=new URLSearchParams(window.location.search);
    const customerId=params.get('campaign_id');
    let adGroupData=localStorage.getItem('adGroupData');
    if(adGroupData){
        adGroupData=JSON.parse(adGroupData);
    }
    let prevData=localStorage.getItem('campaignInfo');
    if(prevData){
        prevData=JSON.parse(prevData);
    }

    const [keywords, setKeywords] = useState(adGroupData?.keywords?JSON.parse(adGroupData?.keywords): []);
    const [selectedKeywords, setSelectedKeywords] = useState({
        broad_spectrum:[],
        phrase_match:[],
        exact_match:[]
    })
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        customer_id: Yup.string().required("Customer ID is required"),
        campaign_id: Yup.string().required("Campaign ID is required"),//ring().required("Ad Group Name is required"),
        ad_budget: Yup.number()
            .required("Ad Budget is required")
            .positive("Ad Budget must be positive")
            .integer("Ad Budget must be an integer").max(1299, "Budget must be less than 1299").min(1,"Enter a value"),
        keywords: Yup.string().required("Keyword is required").min(3, "Keyword is required"),

    });


    const handleSubmit = async (values, { resetForm }) => {
        const gId=localStorage.getItem('groupId');
        setLoading(true);
        let body = { ...values, keywords: keywords };
        try {
            // await Campaign.createAdGroup(values);
            localStorage.setItem('adGroupData', JSON.stringify(values));
            if(gId){
                handleUpdateAddGroup(body,gId);
            } else {
                handleCreateNewAddGroup(body);
            }
            // setLoading(false);
        } catch (e) {
            setLoading(false);
            RESPONSE_SERVICES.errorService(e);
        }
    };

    /**
     * Handle Update Add Group
     * @param body
     * @param gid
     * @returns {Promise<void>}
     */
    const handleUpdateAddGroup = async (body,gid) => {
        setLoading(true);
        let finalBody={...body,"ad_group_id":gid};
        const res=await dispatch(updateAdGroup(finalBody));
        if(res?.type==='account/adGroupUpdate/fulfilled'){
            notification.success({message:"Updated Successfully!"})
            localStorage.setItem('groupId',res?.payload?.data?.ad_group_id)
            const currentPage=localStorage.getItem('lastPage');
            localStorage.setItem('lastPage',currentPage>=3?currentPage:3);
            setToSave(3);
            setCurrent(3);

        } else if(res?.type==='account/adGroupUpdate/rejected'){
            RESPONSE_SERVICES.errorService(res?.error);
        }
        setLoading(false);
    }

    /**
     * Create New Add Group
     * @param body
     * @returns {Promise<void>}
     */
    const handleCreateNewAddGroup=async (body)=>{
        const res = await dispatch(createAdGroup(body));
        if (res?.type === CREATE_AD_GROUP_FULFILLED) {
            notification.success({ message: "Successfully created Ad Group" });
            setGroupId(res?.payload?.data?.ad_group_id);
            localStorage.setItem('groupId',res?.payload?.data?.ad_group_id)
            const currentPage=localStorage.getItem('lastPage');
            localStorage.setItem('lastPage',currentPage>=3?currentPage:3);
            setToSave(3);
            setCurrent(3);
        }
        if (res?.type === CREATE_AD_GROUP_REJECTED) {
            RESPONSE_SERVICES.errorService(res.error);
        }
    }



    return (
        <div  style={{ marginTop: "40px"}}>
            <ReusableAddForm>
                <Formik
                    initialValues={adGroupData? {...adGroupData,keywords: adGroupData?.keywords?JSON.parse(adGroupData.keywords):[]}: {
                        customer_id: customerId,
                        campaign_id: campaignId,
                        name:  prevData? `${prevData?.campaign_name} AdGroup 1`:"",
                        ad_budget: "",
                        keywords: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleSubmit, errors, touched }) =>
                    {
                        if(toSave!==current) {
                            localStorage.setItem("adGroupData",JSON.stringify({...values,keywords:JSON.stringify(keywords)}));
                            setCurrent(toSave);
                        }
                        return (
                            <Form layout="vertical" onFinish={handleSubmit} style={{
                                padding: '20px',
                                display: 'grid',
                                // gridTemplateColumns: 'repeat(2, 1fr)',
                                gap: '20px'
                            }}
                            >
                                <div className="form-main">
                                    <div>
                                        <div className="form-heading-logo">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M4 6H20M7 12H20M10 18H20"
                                                    stroke="black"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <h2 className="campaign_name">Ad Group Detail</h2>
                                        </div>
                                        <div
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: "1fr 1fr",
                                                gap: "32px",
                                            }}
                                        >
                                            <div>
                                                <p><span className='error-asterisk-sign'>*</span> Ad Group Name</p>
                                                <TextInput
                                                    value={values.name}
                                                    handleChange={handleChange}
                                                    name="name"
                                                    validateStatus={errors.name && touched.name ? "error" : ""}
                                                    help={errors.name && touched.name ? errors.name : ""}
                                                />
                                            </div>
                                            <div>
                                                <p><span className='error-asterisk-sign'>*</span> CPC Bid</p>
                                                <TextInput
                                                    value={values.ad_budget}
                                                    handleChange={handleChange}
                                                    name="ad_budget"
                                                    validateStatus={errors.ad_budget && touched.ad_budget ? "error" : ""}
                                                    help={errors.ad_budget && touched.ad_budget ? errors.ad_budget : ""}
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <AdGroupKeywords
                                    keywordsValue={values.keywords}
                                    handleKeywordChange={handleChange}
                                    keywords={keywords}
                                    setKeywords={setKeywords}
                                    keywordsError={errors.keywords}
                                    keywordsTouched={touched.keywords}
                                    errors={errors}
                                />
                                <div style={{display: 'flex', gap: '30px', justifyContent: 'flex-end'}}>
                                    <BackandSubmit toShow={true} submitLoading={loading} index={1} setToSave={setToSave}/>
                                </div>
                            </Form>
                        )}}
                </Formik>

            </ReusableAddForm>

            {/* </Spin> */}
        </div>
    );


}


export default CreateAdsCampaign