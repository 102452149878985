import api from "../../api/api";


export const CampaignActionTypes = {
    getCampaignInfo:async (campaignId) => {
       return await api.post('/api/v1/campaigns/list',{
            "customer_id": campaignId,
        });
    },
    createCampaign:async (body) => {
      return await api.post('/api/v1/campaigns',body);
    },
    createAdGroup:async (body) => {
        return await api.post('/api/v1/adGroup/create',body);
    },
    listAdGroup:async (body) => {
      return await api.post('/api/v1/adGroup/list',body);
    },
    updateCampaign:async (body) => {
        return await api.post('/api/v1/campaigns/update',body);
    },
    deleteCampaign:async (body) => {
        return await api.post('/api/v1/campaigns/delete',body);
    },
    updateAdGroup:async (body) => {
        return await api.post('/api/v1/adGroup/update',body);
    }
}