import React from 'react';
import { Result, Button } from 'antd';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout} from "../../redux/slices/userSlice";

const NoGoogleAdsAccountPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleRedirect = () => {
        dispatch(logout());
        navigate('/login');
    };

    return (
        <Result
            status="403"
            title="No Google Ads Account Found"
            subTitle="You need a Google Ads account to access this page."
            extra={[
                <Button className={'primary-btn'} type="primary" key="login" onClick={handleRedirect}>
                    Go to Login
                </Button>,
            ]}
        />
    );
};

export default NoGoogleAdsAccountPage;
