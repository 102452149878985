import {createAsyncThunk} from "@reduxjs/toolkit";
import userAction from "../actions/userAction";
import { LOGIN } from "../types/userActionTypes";

export const loginUser = createAsyncThunk(LOGIN,async ({code})=>{
    try {
        const userData = await userAction.login({code});
        return userData?.data?.data;
    } catch (e) {
        throw  e?.response?.data?.error[0] || e;
    }
});