import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Select, DatePicker, Checkbox, Row, Col, Modal, notification, Spin } from "antd";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from "react-router-dom";
import { RESPONSE_SERVICES } from "../../common/Services";
import { createCampaign, getCampaignInfo } from "../../redux/thunk/CampaignThunk";
import { useDispatch,useSelector } from "react-redux";
import { CREATE_CAMPAIGN_FULFILLED, CREATE_CAMPAIGN_REJECTED, GET_CAMPAIGN_INFO_FULFILLED, GET_CAMPAIGN_INFO_REJECTED } from "../../redux/types/CampaignActionTypes";
import { Steps } from 'antd';
import Forms from "./Forms";
const { Option } = Select;
const { Item } = Form;

const NewCampaign = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [campaignId, setCampaignId] = useState(null);
    const [campId, setCampId] = useState(null);
    const [groupId, setGroupId] = useState(null);
    const [fetched, setFetched] = useState(false);
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [budgetNames, setBudgetNames] = useState([]);
    const [toSave, setToSave] = useState(null);



    const [newCampaignValues, setNewCampaignValues] = useState({});
    const [campaignSettingValues, setCampaignSettingValues] = useState({});
    const [adGroupValues, setAdGroupValues] = useState({});
    const [AdsValues, setAdsValues] = useState({});

    const [data,setData] = useState({

        campaign_name: "",
        advertising_channel_type:  "",
        campaign_status:  "",
        campaign_objective:  "",
        target_google_search: false,
        target_search_network:  false,
        target_partner_search_network:  false,
        target_content_network:  false,
        start_date:  null,
        end_date:  null,

    },);
    const dispatch = useDispatch();

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    let isCampaignPresent = localStorage.getItem('campaignId');

    const campaignForm = {
        initialValues: {...data},
        validationSchema: Yup.object().shape({
           campaign_name: Yup.string().required("Campaign Name is required"),
            advertising_channel_type: Yup.string().required("Advertising Channel Type is required"),
            campaign_status: Yup.string().required("Campaign Status is required"),
            target_google_search: Yup.boolean().required("Target Google Search is required"),
            target_search_network: Yup.boolean().required("Target Search Network is required"),
            target_partner_search_network: Yup.boolean().required("Target Partner Search Network is required"),
            target_content_network: Yup.boolean().required("Target Content Network is required"),
            start_date: isCampaignPresent?Yup.date():Yup.date().required("Start Date is required").min(yesterday, "Start Date cannot be in the past"),
            end_date: Yup.date().min(Yup.ref('start_date'), "End Date must be after Start Date").notRequired()
        }),
        handleSubmit: async (values) => {
            setData(values);
            localStorage.setItem('campaignInfo', JSON.stringify(values));
           setCurrent(1);
           setToSave(1);
            localStorage.setItem('lastPage',1);
            window.scrollTo({ top: 0, behavior: 'smooth' });

        }
    }

    const fetchCampaignDetails = async (id) => {
        try {
            setLoading(true);
            const res = await dispatch(getCampaignInfo({ campaignId: id }));
            if (res?.type === GET_CAMPAIGN_INFO_FULFILLED) {
                if (res?.payload && res?.payload?.length) {
                    let temp = [];
                    res?.payload.map(val => {
                        if (val?.name) {
                            temp.push(val.name);
                        }
                    })
                    setBudgetNames(temp);
                }
            }
            if (res?.type === GET_CAMPAIGN_INFO_REJECTED) {
                RESPONSE_SERVICES.errorService(res.error);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            RESPONSE_SERVICES.errorService(e);
        }
    };


  


    return (
        <Spin spinning={loading} style={{ minWidth: "100%", minHeight: '100%', display: "flex", justifyContent: "center", alignItems: "center"}}>

            <div style={{ padding: "40px",background:"#F8F7FC" }}>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center"}} >
                <Steps
                    current={current} 
                    style={{maxWidth:"500px",}}
                    items={[
                        {
                            title: "Create",
                            label: "Create Campaign",
                            onClick:()=>setToSave(0)

                        },
                        {
                            title: "Setting",
                            label:"Campaign Setting",
                            onClick:()=>{
                                const maxi=localStorage.getItem('lastPage');
                                if(parseInt(maxi)>=1){
                                    setToSave(1)
                                }
                        }
                        },

                        {
                            title: "Ad Group",
                            onClick:()=>{
                                const maxi=localStorage.getItem('lastPage');
                                if(parseInt(maxi)>=2){
                                    setToSave(2)
                                }
                            }

                        },
                        {
                            title: "Ads",
                            onClick:()=>{
                                const maxi=localStorage.getItem('lastPage');
                                if(parseInt(maxi)>=3){
                                    setToSave(3)
                                }
                            }

                        },
                    ]}
                />
                </div>
                <Forms current={current} setCurrent={setCurrent}
                    campaign={campaignForm} campaignId={campId} ad_group_id={groupId} setGroupId={setGroupId} data={data} setCampaignId={setCampId} setNewCampaignValues={setNewCampaignValues}
                       setCampaignSettingValues={setCampaignSettingValues} setAdGroupValues={setAdGroupValues} setAdsValues={setAdsValues}
                    toSave={toSave} setToSave={setToSave}
                />
            </div>
        </Spin>
    );
};


const AlertModal = () => {
    const [visible, setVisible] = useState(true); // State to control the visibility of the modal
    const navigate = useNavigate();
    // Function to handle modal close
    const handleClose = () => {
        navigate('/campaign');
        setVisible(false);
    };

    return (
        <Modal
            visible={visible} // Set the visibility state of the modal
            title="Alert" // Modal title
            footer={[ // Footer with a close button
                <Button className={'primary-btn'} key="close" type="primary" onClick={handleClose}>
                    Close
                </Button>
            ]}
            onCancel={handleClose} // Function to handle modal close when clicking outside or pressing Escape key
        >
            <p>No Campaign ID found</p> {/* Alert message */}
        </Modal>
    );
};


export default NewCampaign;
