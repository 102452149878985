import api from "../../api/api";


export const BudgetActionType={
    createBudget:async(body)=>{
       return await api.post('/api/v1/budget/create',body);

    },
    getBudgets:async(customer_id)=>{
       return await api.post('/api/v1/budget/list', {"customer_id":customer_id});

    }
}