import React, { useState } from 'react';

const Card = ({ options, handleChange, name,type ,value}) => {
    const [selected, setSelected] = useState(value);
    const handleClick = (value) => {
        setSelected(value);
        if(!name) {
            handleChange && handleChange(value)
        }
        else {
            handleChange && handleChange(name)(value)
        }
    }

    return (
        <div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "20px" }}>
                {options?.map((item, index) => {
                    return (
                        <div onClick={() => handleClick(item?.value)} key={index}>
                            <div className={`${selected === item?.value ? `selected-${type}` : type} ${"card"}`}>
                                <div className={selected === item?.value ? "selectedOuterCircle" : "outerCircle"}>
                                    <span className={selected === item?.value && "innerCircle"}></span>
                                </div>
                                <div style={{ display: "grid", placeItems: "center", padding: "20px" }}>
                                    {item.icon && <span style={{ fontSize: "23px" }}>{item.icon}</span>}
                                    {item.image && <img src={item.image} style={{ width: "140px", height: "90px" }} />}
                                    <h1 className='card-title'>{item.title}</h1>
                                    <p style={{ textAlign: "center" }} className="card-para">{item?.description}</p>
                                    {/* <button className='card-button'>Learn More</button> */}
                                </div>
                            </div>
                        </div>
                    )

                })}

            </div>
        </div>
    );
}

export default Card;