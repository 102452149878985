import React from 'react';
import { Layout } from 'antd'; // Import Layout from antd
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import Navbar from './Components/Navbar/Navbar';

const { Content } = Layout; // Destructure Content from Layout

const Wrapper = ({ children }) => { // Accept children prop directly
    const location = useLocation(); // Get the current location
    const clientId = process.env.GOOGLE_CLIENT_ID || '201891706446-0rtnh7ickkgeve8aol9d3f6k1uthso6e.apps.googleusercontent.com';

    // Check if the current path is /login
    const isLoginPage = location.pathname === '/login';

    return (
        <>
            {!isLoginPage && ( // Render Navbar and Layout only if not on the login page
                <Layout style={{ minHeight: '100vh' }}>
                    <Navbar />
                    <Layout>
                        <Content>{children}</Content> {/* Render children within Content component */}
                    </Layout>
                </Layout>
            )}
            {isLoginPage && children} {/* Render children directly on the login page */}
        </>
    );
};

export default Wrapper;
