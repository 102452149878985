import React from "react";

const AssetListingTile = ({ item }) => {
//   console.log(item.type, 'type')
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "11px",
      }}
    >

      {item?.type === "IMAGE"  ? (
        <img
          src={item?.url}
          style={{
            borderRadius: "10px",
            height: '350px',
            objectFit: 'contain',
          }}
          onError={(e) => {
            e.target.src = `https://via.placeholder.com/${item?.width_pixels}x${item?.height_pixels}`;
            e.target.onerror = null;
          }}
        />
      ) : item?.type === "YOUTUBE_VIDEO" ? (
        <iframe
          height="350"
          objectFit="contain"
          src={`https://www.youtube.com/embed/${item?.youtube_video_id}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      ): null}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1px",
        }}
      >
        <span>{item?.type !== 'TEXT' && item?.name || item?.youtube_video_title}</span>
        {item?.type === 'IMAGE' && <span>{`Horizontal (${item?.width_pixels} x ${item?.height_pixels})px`}</span>}
      </div>
    </div>
  );
};

export default AssetListingTile;
