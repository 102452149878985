import React, {useEffect, useState} from "react";
import {
  ContactsOutlined,
  ContainerOutlined,
  FundProjectionScreenOutlined,
  LoginOutlined,
  LogoutOutlined,
  PieChartOutlined,
  StockOutlined,
  FileAddOutlined,
  SyncOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {Button, Card, Layout, Menu, Modal, Select, Spin} from "antd";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../redux/slices/userSlice";
import {Content} from "antd/es/layout/layout";
import {getLinkedAccount} from "../../redux/thunk/linkedAccountThunk";
import {LINKED_ACCOUNTS_FULFILLED, LINKED_ACCOUNTS_REJECTED} from "../../redux/types/linkedAccountActionTypes";
import {RESPONSE_SERVICES} from "../../common/Services";
import {getCampaignInfo} from "../../redux/thunk/CampaignThunk";
import {GET_CAMPAIGN_INFO_FULFILLED, GET_CAMPAIGN_INFO_REJECTED} from "../../redux/types/CampaignActionTypes";
import SelectInput from "../../common/SelectBoxes/SelectInput";
import "../../styles/NavbarModal.css"
import {Functions} from "../../common/functions";
import {ref} from "yup";

const { Sider } = Layout;
const {Option}=Select
function getItem(label, key, icon, path = null, children, onClick = null) {
  return {
    key,
    icon,
    children,
    label,
    path,
    onClick,
  };
}

export const NavBarContext=React.createContext();


const NavBar = ({children}) => {
  const location = useLocation(); // Get current location
  const [collapsed, setCollapsed] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentKey = location.pathname.split('/')[1];
  const [isVisible, setIsVisible] = useState(false);
  const [descriptiveName, setDescriptiveName] = useState('');
  const accountDescriptions = localStorage.getItem("AccountDescriptions");
  const [refresh,setRefresh]=useState(false);
  const [initialRender,setInitialRender]=useState(false);
  const { Meta } = Card;
  const [searchParams] = useSearchParams();
  const customer_id = searchParams.get("customer_id");
  const campaign_id = searchParams.get("campaign_id");
  const [paramsCampaignId,setParamsCampaignId] = useState(campaign_id || null);
  const [paramsCustomerId, setParamsCustomerId] = useState(customer_id || null);

  const getPath=(url)=>{
   if(paramsCampaignId && paramsCampaignId!=='null'&& paramsCustomerId && paramsCustomerId!=='null') return url+`?campaign_id=${paramsCampaignId}&customer_id=${paramsCustomerId}`;
   else if(paramsCustomerId && paramsCustomerId!=='null') return url+`?customer_id=${paramsCustomerId}`;
   else return url;
  }

  const resetIds=()=>{
    setIsVisible(true)
  }

  const onOk=(consumerId,campaignId)=>{
    setParamsCustomerId(consumerId);
    setParamsCampaignId(campaignId)
    if(campaignId) {
      window.location.href = `${window.location.pathname}?customer_id=${consumerId}&campaign_id=${campaignId}`;
    }
    else {
      window.location.href = `${window.location.pathname}?customer_id=${consumerId}`
    }
    setIsVisible(false);
  }

  useEffect(() => {
    const id = paramsCustomerId;
    const parsedAccountDescriptions = JSON.parse(accountDescriptions) || {};
    for (const [key, value] of Object.entries(parsedAccountDescriptions)) {
      if(`${key}` === id) {
        if(`${value.descriptive_name}` === '') setDescriptiveName(`${key}`)
        else setDescriptiveName(`${value.descriptive_name}`)
      }
    }
  }, [paramsCustomerId]);

  const onCancel=()=>{
    setIsVisible(false);
  }

  useEffect(() => {
    const id = paramsCustomerId;
    const parsedAccountDescriptions = JSON.parse(accountDescriptions) || {};
    for (const [key, value] of Object.entries(parsedAccountDescriptions)) {
      if(`${key}` === id) {
        if(`${value.descriptive_name}` === '') setDescriptiveName(`${key}`)
        else setDescriptiveName(`${value.descriptive_name}`)
      }
    }
  }, [paramsCustomerId]);

  const items = [
    getItem("Insights", "7", <PieChartOutlined />, `/insights/dashboard`),
    getItem(
      "All Campaigns",
      "1",
      <FundProjectionScreenOutlined />,
     `/campaign`,
      null
    ),
    getItem("Ad Group", "3", <ContactsOutlined />, `/adgroup/list`),
    getItem("Ads", "6", <StockOutlined />, `/ads/list`),
    getItem("Budget", "4", <PieChartOutlined />, `/budget/list`),
    getItem("Keywords", "5", <ContainerOutlined />, `/keyword/list`),
    getItem("Asset Library", "8", <FileAddOutlined />, `/assetlibrary`)
    // getItem('Analytics', 'sub2', <FundOutlined />, null, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
    // getItem('Billing', '2', <CreditCardOutlined />, null, null),
  ];

  const logoutItem = user
    ? getItem("Logout", "sub3", <LogoutOutlined />, null, null, () => {
        dispatch(logout());
        navigate("/login");
      })
    : getItem("LogIn", "9", <LoginOutlined />, "/login");

  const handleLogout = () => {
    // Clear login status from context and localStorage
    logout();
    navigate("/login");
  };

  const isLoginPage = location.pathname === '/login';
  return (
      <NavBarContext.Provider value={{setParamsCampaignId, setParamsCustomerId,getPath,setIsVisible,setInitialRender}}>
        {!isLoginPage && (
            <Layout style={{ minHeight: '100vh' }}>
              <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
                <div className="demo-logo-vertical" />
                <Menu theme="dark" mode="inline">
                  {items.map(
                      (item) =>
                          item && ( // Ensure item is not null before rendering
                              <Menu.Item key={item.path.split('/')[1]} icon={item.icon}
                              className={`menu-item ${item?.path?.split('/')[1] === currentKey ? "navLinkActive" : "navLinkInActive"}`}
                              >
                                {item.path ? (
                                    <Link to={getPath(item.path)}>{item.label}</Link>
                                ) : (
                                    <span onClick={item.onClick}>{item.label}</span>
                                )}
                              </Menu.Item>
                          )
                  )}
                  {!collapsed && (

                      <div
                          style={{
                            position: "fixed",
                            bottom: 45 ,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            paddingLeft:"30px",
                            gap:"10px"
                          }}
                      >

                        {user && (
                            <div>
                              <button className={'button'} onClick={resetIds} style={{width: '160px'}}><SyncOutlined /> {descriptiveName || paramsCustomerId}</button>

                              <Meta
                                  title={user.name}
                                  style={{
                                    marginBottom: "30px !important",
                                    marginTop: "10px !important",
                                  }}
                              />


                            </div>
                        )}
                        {logoutItem && (
                            <Menu.Item
                                key={logoutItem.key}
                                icon={logoutItem.icon}
                                onClick={logoutItem.onClick}
                                style={{
                                  marginBottom: "10px !important",
                                  marginTop: "10px !important",
                                }}
                            >
                              {logoutItem.label}
                            </Menu.Item>
                        )}
                      </div>

                  )}
                </Menu>
              </Sider>
              <Layout>
                <Content>{children}</Content>
                <ResetIds visible={isVisible} onOk={onOk} onCancel={onCancel} refresh={refresh} setRefresh={setRefresh} initialRender={initialRender} paramsCustomerId={paramsCustomerId} paramsCampaignId={paramsCampaignId}/>
              </Layout>
            </Layout>
        )}
        {isLoginPage && children}

      </NavBarContext.Provider>
  );
};


function ResetIds({ visible, onCancel, onOk,refresh,setRefresh,initialRender, paramsCustomerId,paramsCampaignId }) {
  const [customerId, setCustomerId] = useState(paramsCustomerId || '');
  const [campaignName, setCampaignName] = useState('');
  const [campaignId, setCampaignId] = useState(paramsCampaignId || '');
  const [campaigns,setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedAccountDetails = JSON.parse(localStorage.getItem('AccountDescriptions'));
  const dispatch = useDispatch();
  const [linkedAccount,setLinkedAccounts]=useState(JSON.parse(localStorage.getItem('linkedAccounts')));
  const navigate = useNavigate();


  const handleRefresh = async (val) => {
    setLoading(val);
    setRefresh(val);
  }

  useEffect(() => {
    if(refresh){
      Functions.getLinkedAccount(handleRefresh,null,navigate,dispatch,setLinkedAccounts);
    }
  }, [refresh]);


  useEffect(()=>{
    if(customerId){
      Functions.fetchCamp(customerId,setLoading,dispatch,setCampaigns);
    }
  },[customerId]);

  useEffect(() => {
    if(initialRender){
      Functions.getLinkedAccount(setLoading,null,navigate,dispatch,setLinkedAccounts);
    }
  }, [initialRender]);

  useEffect(() => {
    setLinkedAccounts(JSON.parse(localStorage.getItem('linkedAccounts')))
  }, [visible]);

  const handleDropdown1Change = (value) => {
    setCustomerId(value);
  };

  useEffect(() => {
      const campaign = campaigns?.find(({id}) => id.toString() === paramsCampaignId);
      setCampaignName(campaign ? campaign.name : '');
  }, [campaigns]);

  const handleRefreshStart=()=>{
    setRefresh(true);
    setLoading(true);
    setCustomerId('');
    setCampaignId('');
    setCampaignName('');
  }

  const handleDropdown2Change = (value,record) => {
    // console.log(value, record);
    setCampaignName(record?.children);
    setCampaignId(value);
  };

  const handleOk = () => {
    onOk(customerId, campaignId);
    // console.log(campaignId)
  };

  return (
      <Modal
          // title="Select Campaign and Customer Ids"
          visible={visible}
          onCancel={onCancel}
          onOk={handleOk}
          className="custom-modal"
          okButtonProps={{disabled:customerId === ''}}
          cancelButtonProps={ (paramsCustomerId === null) && {style: { display: 'none'}} }
          closeIcon={paramsCustomerId !== null}
          maskClosable={paramsCustomerId !== null}
      >
       <Spin spinning={loading}>
      <div>
        <div style={{display: 'flex', gap: '10px'}}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 6H20M7 12H20M10 18H20"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h2 className="campaign_name" style={{marginTop: "0px", gap: '10px'}}>Select Campaign Id and Customer Name <InfoCircleOutlined style={{fontSize: '12px'}}/></h2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            // gap: "6px",
          }}
        >
          <div style={{margin: '16px 0 '}}>
            <button onClick={handleRefreshStart} style={{border: 'none', background: 'none', color: '#6F5CD9', fontSize: '16px', cursor: 'pointer'}}><SyncOutlined style={{color: '#6F5CD9', marginRight: '5px'}} /> Refresh</button>
          </div>
          <div>
            <p><span className='error-asterisk-sign'>*</span> Customer ID</p>
            <SelectInput
              value={customerId}
              setInput={setCustomerId}
              options={!linkedAccount?[]:linkedAccount?.map((item) => {return {value: item.split("/")[1], label: (selectedAccountDetails[item.split("/")[1]] && selectedAccountDetails[item.split("/")[1]]?.descriptive_name) || item.split("/")[1]}})}
              disabledOptions={selectedAccountDetails}
              modalInputFields={true}
            />
          </div>
          <div>
            <p>Campaign Name</p>
            <SelectInput
              value={campaignName}
              handleDataChange={handleDropdown2Change}
              options={campaigns?.map((val) => {return {value: val.id, label: val.name}})}
              modalInputFields={true}
            />
          </div>
          </div>
          </div>
       </Spin>
      </Modal>
  );
}


export default NavBar;
