import React from 'react';
import { Form } from 'antd';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const { Item } = Form;

const CampaignDuration = ({ setFieldValue,values,errors,touched }) => {
    const disabledDate = (current) => {
        return current && current.isBefore(dayjs().startOf('day'));
    };

    return (
        <div className='form-main'>
            <div>
                <h2 className='campaign_name'>
                    Campaign Duration</h2>
                <br></br>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                    <div>
                        <Item
                            label={<span><span className='error-asterisk-sign'>*</span> Start Date</span>}
                            hasFeedback
                            name="start_date"
                            validateStatus={errors?.start_date && touched?.start_date ? "error" : ""}
                            help={errors?.start_date && touched?.start_date ? errors?.start_date : ""}
                        >
                            <DatePicker
                                disabledDate={disabledDate}
                                className={'custom_datepicker text-field'}
                                value={values.start_date ? dayjs(values.start_date) : null}
                                defaultValue={values.start_date ? dayjs(values.start_date) : null}
                                format="YYYY-MM-DD"
                                onChange={(date, dateString) => setFieldValue("start_date", dateString)} />
                        </Item>
                    </div>
                    <div>
                        <Item
                            label="End Date"
                            name="end_date"
                            hasFeedback
                            validateStatus={errors?.end_date && touched?.end_date ? "error" : ""}
                            help={errors?.end_date && touched?.end_date ? errors?.end_date : ""}
                        >
                            <DatePicker
                                disabledDate={disabledDate}
                                className={'custom_datepicker text-field'}
                                value={values.end_date ? dayjs(values.end_date) : null}
                                defaultValue={values.end_date ? dayjs(values.end_date) : null}
                                format="YYYY-MM-DD"
                                onChange={(date, dateString) => setFieldValue("end_date", dateString)} />
                        </Item>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CampaignDuration;