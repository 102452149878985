export const CREATE_ADS = 'account/createAds';
export const LIST_ADS = 'account/listAds';
export const UPLOAD_IMAGE = 'account/uploadImage';
export const UPLOAD_ASSET = 'account/uploadAsset';
export const LIST_ASSETS = 'account/listAssets';
export const CREATE_ADS_DISPLAY = 'account/createAdsDisplay';

export const CREATE_ADS_FULFILLED = 'account/createAds/fulfilled';
export const LIST_ADS_FULFILLED = 'account/listAds/fulfilled';
export const UPLOAD_IMAGE_FULFILLED = 'account/uploadImage/fulfilled';
export const UPLOAD_ASSET_FULFILLED = 'account/uploadAsset/fulfilled';
export const LIST_ASSETS_FULFILLED = 'account/listAssets/fulfilled';
export const CREATE_ADS_DISPLAY_FULFILLED = 'account/createAdsDisplay/fulfilled';

export const CREATE_ADS_PENDING = 'account/createAds/pending';
export const LIST_ADS_PENDING = 'account/listAds/pending';
export const UPLOAD_IMAGE_PENDING = 'account/uploadImage/pending';
export const UPLOAD_ASSET_PENDING = 'account/uploadAsset/pending';
export const LIST_ASSETS_PENDING = 'account/listAssets/pending';
export const CREATE_ADS_DISPLAY_PENDING = 'account/createAdsDisplay/pending';


export const CREATE_ADS_REJECTED = 'account/createAds/rejected';
export const LIST_ADS_REJECTED = 'account/listAds/rejected';
export const UPLOAD_IMAGE_REJECTED = 'account/uploadImage/rejected';
export const UPLOAD_ASSET_REJECTED = 'account/uploadAsset/rejected';  
export const LIST_ASSETS_REJECTED = 'account/listAssets/rejected';
export const CREATE_ADS_DISPLAY_REJECTED = 'account/createAdsDisplay/rejected';