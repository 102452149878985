import React, { useEffect, useState } from "react";
import AssetListingTile from "./AssetListingTile";
import { Col, Pagination, Row, Spin } from "antd";
import { useDispatch } from "react-redux";
import {
  LIST_ASSETS_FULFILLED,
  LIST_ASSETS_REJECTED,
} from "../../redux/types/AdsActionTypes";
import { listAssets } from "../../redux/thunk/AdsThunk";
import { RESPONSE_SERVICES } from "../../common/Services";

const AssetListingGrid = ({ selectedAsset, customerId, assetUploaded, setAssetUploaded }) => {
  const dispatch = useDispatch();
  const [assetList, setAssetList] = useState([]);
  const [selectedAssetList, setSelectedAssetList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAssetList = async () => {
    setLoading(true);
    try {
      const res = await dispatch(listAssets({ 'customer_id': customerId }));
      if (res?.type === LIST_ASSETS_FULFILLED) {
        setAssetList(res?.payload?.data?.assets);
        setSelectedAssetList(res?.payload?.data?.assets);
        handleAssetTypeList(res?.payload?.data?.assets);
      }
      if (res?.type === LIST_ASSETS_REJECTED) {
        RESPONSE_SERVICES.errorService(res.error);
      }
      if(setAssetUploaded) {
        setAssetUploaded(false);
      }
      setLoading(false);
    } catch (err) {
      setAssetList([]);
      RESPONSE_SERVICES.errorService(err);
      if(setAssetUploaded) {
        setAssetUploaded(false);
      }
      setLoading(false);
    }
  };

  const handleAssetTypeList = (assets) => {
    // console.log(assets, selectedAsset);
    let currentTypeList = [];
    assets?.map((list) => {
      if (
        (list.type === "YOUTUBE_VIDEO" && selectedAsset === "Videos") ||
        (list.type === "IMAGE" && selectedAsset === "Images") ||
        selectedAsset === "All" && list.type !== "TEXT"
      ) {
        currentTypeList.push(list);
      }
    });
    setSelectedAssetList(currentTypeList);
  };
   useEffect(() => {
    handleAssetList();
   }, [])

   useEffect(() => {
    if(assetUploaded) {
      handleAssetList();
    }
   }, [assetUploaded])

  useEffect(() => {
    handleAssetTypeList(assetList);
  }, [selectedAsset]);

  return (
    loading ? 
    <Spin loading={loading} style={{display: "flex", justifyContent: "center"}}/>
    :
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "28px",
      }}
    >
      <span
        style={{
          fontSize: "18px",
          color: "#000",
          fontWeight: "500",
          lineHeight: "150%",
        }}
      >
        Asset - {selectedAsset}
      </span>
      <div>
        <Row gutter={[40, 40]}>
          {selectedAssetList?.map((item) => (
            <Col key={item?.id} xs={12} sm={8} md={8} lg={6} xl={6} xxl={6}>
              <AssetListingTile item={item}/>
            </Col>
          ))}
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination defaultCurrent={1} total={selectedAssetList?.length} />
      </div>
    </div>
  );
};

export default AssetListingGrid;
