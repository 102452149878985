import { Button, Form, Input } from "antd";
const AdsDescription = ({
  formik,
  numDescriptions,
  maxDescriptions,
  addDescription,
  removeDescription,
}) => {
  return (
    <div className="form-main">
      <div>
        <div className="form-heading-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 6H20M7 12H20M10 18H20"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h2 className="campaign_name">Descriptions</h2>
        </div>
        <div
          style={{
            display: "grid",
          }}
        >
          <Form.Item required>
            {formik.values.descriptions.map((description, index) => (
              <>
                <span><span className='error-asterisk-sign'>*</span> Description {index + 1}</span>
                <div key={index} style={{ marginTop: "-10px" }}></div>
                <div key={index}>
                  <Input
                    value={description}
                    onChange={(e) => {
                      const newDescriptions = [...formik.values.descriptions];
                      newDescriptions[index] = e.target.value;
                      formik.setFieldValue("descriptions", newDescriptions);
                    }}
                    onBlur={formik.handleBlur}
                    style={{ marginBottom: "10px" }}
                    className="text-field"
                  />
                  {numDescriptions > 2 && index > 1 && (
                    <Button
                      type="dashed"
                      onClick={() => removeDescription(index)}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </>
            ))}
            <Button
              type="dashed"
              onClick={addDescription}
              style={{ width: "100%", marginTop: "10px" }}
              disabled={numDescriptions >= maxDescriptions}
            >
              Add Description
            </Button>
            <span style={{ marginLeft: "10px", color: "gray" }}>
              {numDescriptions}/{maxDescriptions} rows
            </span>
          </Form.Item>
          {
            (formik?.touched?.descriptions && formik?.errors?.descriptions) && (
              <p style={{ color: "red" }}>{(formik?.touched?.descriptions && formik?.errors?.descriptions)?.length > 0 ? `Minimum ${numDescriptions} Descriptions are Required` : ""}</p>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default AdsDescription;
