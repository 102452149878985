export const LocalStorage={
    clearCampaignData:()=>{
        localStorage.removeItem("campaignId");
        localStorage.removeItem('createAds')
        localStorage.removeItem('campaignInfo')
        localStorage.removeItem('groupId')
        localStorage.removeItem('newCampaignInfo')
        localStorage.removeItem('lastPage')
        localStorage.removeItem('adGroupData')
    }
}