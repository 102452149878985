// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart_custompicker__zi7d5 {
    transition: border-color 0.3s ease !important;
  }
  
  .chart_custompicker__zi7d5:hover {
    border-color:  #8833FF !important;
    border :" 2px solid ";
  }`, "",{"version":3,"sources":["webpack://./src/styles/common/chart.module.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;EAC/C;;EAEA;IACE,iCAAiC;IACjC,qBAAqB;EACvB","sourcesContent":[".custompicker {\n    transition: border-color 0.3s ease !important;\n  }\n  \n  .custompicker:hover {\n    border-color:  #8833FF !important;\n    border :\" 2px solid \";\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custompicker": `chart_custompicker__zi7d5`
};
export default ___CSS_LOADER_EXPORT___;
