import {getLinkedAccount} from "../../redux/thunk/linkedAccountThunk";
import {RESPONSE_SERVICES, RESPONSE_SERVICES as Respose_SERVICES} from "../../common/Services";
import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getCampaignInfo, listAdGroup} from "../../redux/thunk/CampaignThunk";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Form, Input, Button, Row, Col, Typography, notification, Select, Spin} from 'antd';
import {createAds} from "../../redux/thunk/AdsThunk";
import {createKeyword} from "../../redux/thunk/KeywordThunk";
import {LINKED_ACCOUNTS_FULFILLED} from "../../redux/types/linkedAccountActionTypes"
import {GET_CAMPAIGN_INFO_FULFILLED ,  CREATE_KEYWORD_FULFILLED , CREATE_KEYWORD_REJECTED , LIST_AD_GROUP, LIST_AD_GROUP_FULFILLED } from "../../redux/types/CampaignActionTypes"
import TextInput from "../../common/TextInput";
import AdGroupDetails from "../AdGroup/AdGroupFormComponents/AdGroupDetails";
import AdGroupKeywords from "../AdGroup/AdGroupFormComponents/AdGroupKeywords";
import {NavBarContext} from "../Navbar/Navbar";
import {Functions} from "../../common/functions";
const { Title } = Typography;

const CreateKeywords = () => {
    const {getPath}=useContext(NavBarContext)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [customerIds, setCustomerIds] = useState([]);
    const navigate = useNavigate();
    const {user}= useSelector((state) => state.user);
    const {campaigns}= useSelector((state) => state.campaigns);
    const {linkedAccount}=useSelector((state) => state.linkedAccounts);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedAdId, setSelectedAdId] = useState(null);
    const [selectedCampaignId, setSelectedCampaignId] = useState(null);
    const [adGroupList, setAdGroupList] = useState([]);
    const [campaignIds, setCampaignIds] = useState([]);
    const [keywordsLength, setKeywordsLength] = useState(1);
    const [keywords, setKeywords] = useState([]);

    /**
     * Set Campaigns
     */
    useEffect(() => {
        setCampaignIds(campaigns)
    }, [campaigns]);

    /**
     * Set Customer Ids
     */
    useEffect(()=>{
        if(linkedAccount){
            let temp=[];
            linkedAccount.map(val=>{
                temp.push(val.toString().split("/")[1]);
            })
            setCustomerIds(temp);
        } else {
            setCustomerIds([]);
        }
    },[linkedAccount]);


    /**
     * Get Linked Accounts/ Customers
     */
    useEffect(() => {
        Functions.getLinkedAccount(setLoading,null,navigate,dispatch);
    }, [user]);

    /**
     * Fetch Campaigns / AddGroup Lists
     */
    useEffect(() => {
        if(selectedCampaignId && selectedCustomerId){
           Functions.getAdGroupList(setLoading,selectedCampaignId,selectedCustomerId,setAdGroupList,dispatch);
        }else if(selectedCustomerId){
            Functions.fetchCampaignDetails(selectedCustomerId,setLoading,dispatch,)
        }

    }, [selectedCustomerId,selectedCampaignId]);

    /**
     * Function to Submit and create keywords
     * @param values
     * @returns {Promise<void>}
     */
    const handleSubmit=async (values)=>{
        try{
            setSubmitLoading(true);
            const body={...values,  "campaign_id":selectedCampaignId.toString(),
                "customer_id":selectedCustomerId.toString(),
                "ad_group_id":selectedAdId.toString(),
                "keywords":keywords,
            }
            const res=await dispatch(createKeyword(body));
            if(res?.type===  CREATE_KEYWORD_FULFILLED ) {
                notification.success({message: "Successfully created Keywords"});
                navigate(getPath('/keyword/list'));
            }
            if(res?.type=== CREATE_KEYWORD_REJECTED) {
                Respose_SERVICES.errorService(res?.error);
            }
            setSubmitLoading(false)
        } catch (e) {
            setSubmitLoading(false);
            RESPONSE_SERVICES.errorService(e);
        }
    }

    const formik = useFormik({
        initialValues: {
            ad_group_id: '',
            customer_id: '',
            campaign_id:'',
            keywords: [],
        },
        validationSchema: Yup.object({
            ad_group_id: Yup.string().required('Ad Group Name is Required'),
            campaign_id: Yup.string().required('Campaign Name is Required'),
            customer_id: Yup.string().required('Customer Id is Required'),
            keywords: Yup.string().min(3, "Atleast one keyword is required"),
        }),
        onSubmit: handleSubmit,
    });

    const addHeadline = () => {
        setKeywordsLength(keywordsLength+1)
        formik.setFieldValue(`keywords[${formik.values.keywords.length}]`, '');
    };

    const removeHeadline = (index) => {

        const newKeywords = formik.values.keywords.filter((_, i) => i !== index);
        formik.setFieldValue('keywords', newKeywords);
        setKeywordsLength(newKeywords.length)
    };

    const handleSelectCampaign = (value, record) => {
        formik.setFieldValue('campaign_id', record?.children);
        formik.setFieldValue('ad_group_id', '');
        setSelectedCampaignId(value);
    }
    const handleCustomerIdSeparator = (value) => {
        formik.setFieldValue('customer_id', value);
        formik.setFieldValue('campaign_id', '');
        formik.setFieldValue('ad_group_id', '');
        setSelectedCustomerId(value);
    }

    const adGroupIdHandleSelect = (value, record) => {
        formik.setFieldValue('ad_group_id', record?.children);
        setSelectedAdId(value);
    }

    return (
        <div style={{marginTop: "20px"}}>
        <Spin spinning={loading}>
                    <Form
                        layout="vertical"
                        onFinish={formik.handleSubmit}
                        initialValues={formik.initialValues}
                        validationSchema={formik.validationSchema}
                        validateTrigger="onSubmit"
                        style={{
                            padding: '20px',
                            display: 'grid',
                            gap: '20px'
                        }}
                    >
                        <AdGroupDetails
                            values={formik.values}
                            touched={formik.touched}
                            handleChange={formik.handleChange}
                           customerOptions={customerIds.map((value) => {return {label: value, value: value}})}
                           handleCustomerIdSeparator={handleCustomerIdSeparator}
                            campaignId={campaignIds}
                           CampaignHandleSelect={handleSelectCampaign}
                           CampaignSelectedCampaign={selectedCampaignId}
                           adGroupIdHandleSelect={adGroupIdHandleSelect}
                           adGroupIdOptions={adGroupList?.map((option) => {return {label: option.name, value: option.id}})}
                           createKeywords={true}
                           errors={formik.errors}
                        />
                        <AdGroupKeywords
                           keywordsValue={formik.values.keywords}
                           handleKeywordChange={formik.handleChange}
                           keywords={keywords}
                           setKeywords={setKeywords}
                           keywordsError={formik.errors.keywords}
                           keywordsTouched={formik.touched.keywords}
                           errors={formik.errors}
                        />
                        <Form.Item>
                            <button className="next-button keyword-button" type="submit">
                               {
                                      submitLoading ? <Spin /> : 'Create Keyword'
                               }
                            </button>
                        </Form.Item>
                    </Form>

        </Spin>
        </div>
    );

}

export default CreateKeywords;