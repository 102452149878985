import TextInput from "../../../common/TextInput";
import SelectInput from "../../../common/SelectBoxes/SelectInput";
import CampaignSelect from "../../../common/SelectBoxes/CampaignSelect";
const AdGroupDetails = ({
    values,
    campaignId,
  customerOptions,
  getCustomerId,
  CampaignSelectedCampaign,
  handleCustomerIdSeparator=null,
    handleChange,
  createKeywords,
  adGroupIdHandleChange,
  adGroupIdHandleSelect,
  adGroupIdOptions,
  errors,
  touched,
  CampaignHandleSelect
}) => {
  
  return (
    <div className="form-main">
      <div>
        <div className="form-heading-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 6H20M7 12H20M10 18H20"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h2 className="campaign_name">Ad Group Detail</h2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "32px",
          }}
        >
          <div>
            <p><span className='error-asterisk-sign'>*</span> Customer ID</p>
            <SelectInput
              value={values.customer_id}
              handleChange={handleChange}
              name="customer_id"
              options={customerOptions}
              validateStatus={errors?.customer_id && touched?.customer_id ? "error" : ""}
              help={errors?.customer_id && touched?.customer_id ? errors?.customer_id : ""}
              getCustomerId={getCustomerId}
              handleDataChange={handleCustomerIdSeparator}
            />
          </div>
          <div>
            <p><span className='error-asterisk-sign'>*</span> Campaign Name</p>
            <CampaignSelect
              name="campaign_id"
              validateStatus={errors?.campaign_id && touched?.campaign_id && !CampaignSelectedCampaign ? "error" : ""}
              help={errors?.campaign_id && touched?.campaign_id && !CampaignSelectedCampaign ? errors?.campaign_id : ""}
              handleSelect={CampaignHandleSelect}
              handleChange={handleChange}
              selectedCampaign={CampaignSelectedCampaign}
              options={campaignId}
            />
          </div>
          {!createKeywords ? 
          <div>
            <p><span className='error-asterisk-sign'>*</span> Ad Group Name</p>
            <TextInput
              value={values.name}
              handleChange={handleChange}
              name='name'
              validateStatus={errors?.name && touched?.name ? "error" : ""}
              help={errors?.name && touched?.name ? errors?.name : ""}
            />
          </div>
          : <div>
               <p><span className='error-asterisk-sign'>*</span> Ad Group Name</p>
               <SelectInput
                value={values?.ad_group_id}
                handleChange={adGroupIdHandleChange}
                name="ad_group_id"
                options={adGroupIdOptions}
                validateStatus={errors?.ad_group_id && touched?.ad_group_id ? "error" : ""}
                help={errors?.ad_group_id && touched?.ad_group_id ? errors?.ad_group_id : ""}
                handleDataChange={adGroupIdHandleSelect}
              />
            </div>
          }
          {!!(!createKeywords) &&
            <div>
            <p><span className='error-asterisk-sign'>*</span> Ad Budget</p>
            <TextInput
              value={values.ad_budget}
              handleChange={handleChange}
              name="ad_budget"
              validateStatus={errors?.ad_budget && touched?.ad_budget ? "error" : ""}
              help={errors?.ad_budget && touched?.ad_budget ? errors?.ad_budget : ""}
              type="number"
            />
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default AdGroupDetails;
