import React, {useEffect, useState, useRef, useContext} from "react";
import {useDispatch} from "react-redux";
import {DatePicker, Input, Select, Spin} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {Chart} from "chart.js/auto";
import {getCampaignInsights} from "../../redux/thunk/InsightsThunk";
import {getLinkedAccount} from "../../redux/thunk/linkedAccountThunk";
import {
    LINKED_ACCOUNTS_FULFILLED,
    LINKED_ACCOUNTS_REJECTED,
} from "../../redux/types/linkedAccountActionTypes";
import dayjs from 'dayjs';
import {Col} from "antd";
import styles from "../../styles/common/chart.module.css"
import {NavBarContext} from "../Navbar/Navbar";
const {RangePicker} = DatePicker;

const InsightDashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params=new URLSearchParams(window.location.search);
    const customerId=params.get('customer_id');
    const {setIsVisible,setInitialRender}=useContext(NavBarContext);
    const [loading, setLoading] = useState(true);
    const [selectedAccount, setSelectedAccount] = useState([]);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [chartData, setChartData] = useState([]);
    const chartRef = useRef(null);
    const [dateRange, setDateRange] = useState([]);
    const [totalImpressionCost, setTotalImpressionCost] = useState(0);
    const [totalConversions, setTotalConversions] = useState(0);
    const [totalClicks, setTotalClicks] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
   


    useEffect(() => {
        if(customerId){
            setCurrentAccount(customerId)
        }else{
            setIsVisible(true);
        }

    }, [customerId]);


    useEffect(() => {
        const fetchInsights = async (selectedAccount, startDate, endDate) => {
            try {
                setLoading(true);

                const res = await dispatch(
                    getCampaignInsights({
                        customer_id: selectedAccount,
                        start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined,
                        end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
                    })
                );
                if (res?.type === "GET_CAMPAIGN_INSIGHTS/fulfilled") {
                    setLoading(false);
                    let totalCost = 0;
                    let totalConversions = 0;
                    let clicks = 0;
                    let cost = 0;
                    if (res?.payload?.data?.campaigns?.length) {
                        res?.payload?.data?.campaigns?.forEach(data => {
                            totalCost += data.impressions;
                            totalConversions += data.conversions;
                            clicks += data.clicks;
                            cost += data.cost_micros / 1000000;

                        });


                        setTotalImpressionCost(totalCost);
                        setTotalConversions(totalConversions);
                        setTotalClicks(clicks);
                        setTotalCost(cost);
                        setChartData(res?.payload?.data?.campaigns);
                    }
                }
                if (res?.type === "GET_CAMPAIGN_INSIGHTS/rejected") {
                    // Handle rejection
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
                // Handle error
            }
        };

        if (currentAccount && dateRange.length === 2) {
            const [startDate, endDate] = dateRange;
            fetchInsights(currentAccount, startDate, endDate);
        } else if (currentAccount && dateRange.length === 0) {
            fetchInsights(currentAccount);
        }
    }, [currentAccount, dateRange]);

    useEffect(() => {
        if (chartData.length > 0) {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
            renderChart();
        }
    }, [chartData]);

    const formatValue = (value) => {
        if (value >= 1000000000) {
            return (value / 1000000000).toFixed(1) + "B";
        } else if (value >= 1000000) {
            return (value / 1000000).toFixed(1) + "M";
        } else if (value >= 1000) {
            return (value / 1000).toFixed(1) + "K";
        } else {
            return value;
        }
    };

    const renderChart = () => {
        const ctx = document.getElementById("lineChart");

        chartRef.current = new Chart(ctx, {
            type: "line",
            data: {
                labels: chartData.map((data) => data.name),
                datasets: [
                    {
                        label: `Clicks (Total Clicks:${formatValue(totalClicks)})`,
                        data: chartData.map((data) => data.clicks),
                        borderColor: "blue",
                        fill: false,
                    },
                    {
                        label: `Conversions (Total Conversions:${formatValue(totalConversions)})`,
                        data: chartData.map((data) => data.conversions),
                        borderColor: "green",
                        fill: false,
                    },
                    {
                        label: `Impressions (Total Cost:${formatValue(totalImpressionCost)})`,
                        data: chartData.map((data) => data.impressions),
                        borderColor: "red",
                        fill: false,
                    },
                    {
                        label: `Cost (Total Cost:${formatValue(totalCost)})`,
                        data: chartData.map((data) => data.cost_micros / 1000),
                        borderColor: "orange",
                        fill: false,
                    }
                ],
            },
        });
    };

    const handleAccountChange = (value) => {
        setCurrentAccount(value);
    };

    const handleDateRangeChange = (dates) => {
        setDateRange(dates);
    };
    const render_headers = (
        <>
      {/*<Col*/}
      {/*  style={{*/}
      {/*    display: "flex",*/}
      {/*    flexDirection: "column",*/}
      {/*    marginLeft: "80%",*/}
      {/*    marginTop: "-60px",*/}
      {/*    top: "0",*/}
      {/*  }}*/}
      {/*  position="header"*/}
      {/*>*/}
      {/*      <span style={{fontSize: "12px"}}>Select client ID</span>*/}
      {/*      <Select*/}
      {/*          showSearch*/}
      {/*          style={{width: 200}}*/}
      {/*          placeholder="Select an Account"*/}
      {/*          value={currentAccount}*/}
      {/*          onChange={handleAccountChange}*/}
      {/*      >*/}
      {/*          {selectedAccount &&*/}
      {/*              selectedAccount.map((item) => (*/}
      {/*                  <Select.Option key={item} value={item}>*/}
      {/*                      {item}*/}
      {/*                  </Select.Option>*/}
      {/*              ))}*/}
      {/*      </Select>*/}
      {/*</Col>*/}
      {/* <div style={{ paddingLeft: "25px", gap: "10px" }}>
        <span
          style={{ marginLeft: "30", fontSize: "12px", paddingRight: "10px" }}
        >
          Select date range
        </span>
        <RangePicker
          style={{ marginLeft: "40",border: "2px solid #E8D9FF"}}
          format="YYYY-MM-DD"
          defaultValue=""
          onChange={handleDateRangeChange}
          className={styles.custompicker}
          value={dateRange} // Add this line to set the selected date range
          
        />
      </div> */}
    </>
  );

  const render_canvas = (
    <div style={{marginTop:"0px" , padding:"60px"}}>
     <canvas id="lineChart" width={150} height={80}></canvas>
    </div>
    
  );

  return (
    <div>
      <h1
        style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
      >
        Insight Dashboard
      </h1>
      {loading ? (
        <Spin
          spinning={loading}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25%",
            color: "#8833FF",
          }}
        />
      ) : (
        <>
          {render_headers}
          {render_canvas}
        </>
      )}
    </div>
  );
};

export default InsightDashboard;