import { GET_CAMPAIGN_INFO_REJECTED } from "../types/CampaignActionTypes";
import {LINKED_ACCOUNTS_REJECTED} from "../types/linkedAccountActionTypes";
import {LIST_AD_GROUP_REJECTED,KEYWORD_LIST_REJECTED} from "../types/CampaignActionTypes";
import {LIST_BUDGET_REJECTED} from "../types/BudgetActionTypes";
import { LIST_ADS_REJECTED } from "../types/AdsActionTypes";
import {GET_CAMPAIGN_INSIGHTS_REJECTED} from "../types/InsightsActionTypes";
import { LOGIN_REJECTED } from "../types/userActionTypes";

export const handleAsyncAction = (builder, asyncAction, fulfilledCallback) => {
    builder
        .addCase(asyncAction.pending, (state,action) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(asyncAction.fulfilled, (state, action) => {
            state.isLoading = false;
            if (fulfilledCallback) {
                fulfilledCallback(state, action);
                state.error = null;
            }
        })
        .addCase(asyncAction.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
            if(action.type === GET_CAMPAIGN_INFO_REJECTED || action.type === LIST_AD_GROUP_REJECTED) {
                state.campaigns = [];
                state.adGroupList = [];
            }
            else if(action.type === LINKED_ACCOUNTS_REJECTED) {
                state.linkedAccounts = [];
            }
            else if(action.type === LIST_BUDGET_REJECTED) {
                state.budgets = [];
            }
            else if(action.type === KEYWORD_LIST_REJECTED) {
                state.keywords = [];
            }
            else if(action.type === LIST_ADS_REJECTED) {
                state.ads = [];
            }
            else if(action.type === GET_CAMPAIGN_INSIGHTS_REJECTED) {
                state.insights = [];
            }
            else if(action.type === LOGIN_REJECTED) {
                state.user=[]
                state.error = action.payload
                localStorage.clear()
            }



        });
};

