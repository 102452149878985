import React, { useContext, useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import AssetTypes from "./AssetTypes";
import { useSearchParams } from "react-router-dom";
import { NavBarContext } from "../Navbar/Navbar";


const AssetLibrary = () => {
  const [searchParams] = useSearchParams();
  const customer_id = searchParams.get("customer_id");
  const {setParamsCustomerId}=useContext(NavBarContext);
  setParamsCustomerId(customer_id);
  

  return (
    <div style={{ padding: "20px" }}>
      <div className="form-main">
        <div style={{ display: "flex", flexDirection: "column", gap: "28px" }}>
          <div className="form-heading-logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 6H20M7 12H20M10 18H20"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h2 className="campaign_name">Asset Library</h2>
            <InfoCircleOutlined
              style={{ fontSize: "12px", cursor: "pointer" }}
            />
          </div>
          <div>
            <AssetTypes
              customerId={customer_id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetLibrary;
