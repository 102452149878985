import {Form, Spin} from "antd";
import React from "react";


const BackandSubmit=({toShow, submitLoading, index, setToSave})=>{
    return <>
        {!!toShow && <Form.Item>
            <button className="back-button" type="button" onClick={() => setToSave(index)}>
                Go Back
            </button>
        </Form.Item>}
        <Form.Item>
            <button className="next-button" type="submit">
                {
                    submitLoading ? <Spin /> : "Submit"
                }
            </button>
        </Form.Item>
    </>
}

export default BackandSubmit;