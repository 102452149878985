import React, { useState } from 'react';
import { Spin, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { uploadAssetImage } from '../../../redux/thunk/AdsThunk';
import { Functions } from '../../../common/functions';
import Cropper from 'react-easy-crop';
import { DeleteOutlined, ReloadOutlined, CloudUploadOutlined } from '@ant-design/icons';

const UploadImage = ({ setIsUpload, fileList, setFileList, handleCancel, squareMarketingImage, marketingImage, logoImage, isLogo=false }) => {
    const [aspectRatio, setAspectRatio] = useState(1);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(0.1);
    const [image, setImage] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [fileName, setFileName] = useState('');
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [base64Image, setBase64Image] = useState('');
    const [original, setOriginal] = useState('');
    const [totalCroppedImages, setTotalCroppedImages] = useState(0);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
        setCroppedArea(croppedArea);
        const fileName = aspectRatio === 1 ? "file1" : aspectRatio === 1.91 ? "file2" : "file3";
        setFileName(fileName);

        // Create a new Image object with the cropped area

        const img = new Image();
        img.src = image;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const scaleX = img.naturalWidth / img.width;
            const scaleY = img.naturalHeight / img.height;
            let canvasWidth;
            let canvasHeight;
            if (aspectRatio === 1.91 && img.width > 600 && img.height > 314 && img.width / img.height === 1.91) {
                canvasWidth = img.width;
                canvasHeight = img.height;
            }
            else if (aspectRatio === 1 && img.width > 128 && img.height > 128 && isLogo && img.height === img.width) {
                canvasWidth = img.width;
                canvasHeight = img.height;
            }
            else if (aspectRatio === 1 && img.width > 300 && img.height > 300 && !isLogo && img.width === img.height) {
                canvasWidth = img.width;
                canvasHeight = img.height;
            }
            else if (aspectRatio === 1.91 && img.width < 600 && img.height < 314 && img.width / img.height !== 1.91) {
                canvasWidth = 600
                canvasHeight = 314
            }
            else if (aspectRatio === 1 && img.width < 128 && img.height < 128 && isLogo && img.height !== img.width) {
                canvasWidth = 128
                canvasHeight = 128
            }
            else if (aspectRatio === 1 && img.width < 300 && img.height < 300 && !isLogo && img.height !== img.width) {
                canvasWidth = 300
                canvasHeight = 300
            }
            else {
                isLogo ? canvasWidth = 128 : aspectRatio === 1.91 ? canvasWidth = 600 : canvasWidth = 300
                isLogo ? canvasHeight = 128 : aspectRatio === 1.91 ? canvasHeight = 314 : canvasHeight = 300

            }

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
                img,
                croppedAreaPixels.x * scaleX,
                croppedAreaPixels.y * scaleY,
                croppedAreaPixels.width * scaleX,
                croppedAreaPixels.height * scaleY,
                0,
                0,
                canvasWidth,
                canvasHeight
            );
            const base64Image = canvas.toDataURL('image/jpeg');
            setBase64Image(base64Image);
        };
    };

    const handleSubmitImage = () => {
        if (fileName && croppedAreaPixels) {
            setTotalCroppedImages(totalCroppedImages + 1);
            const updatedFileList = fileList.map((file) => ({
                ...file,
                [fileName]: [
                    {
                        ...file[fileName][0],
                        thumbUrl: base64Image,
                    },
                ],
            }));
            setFileList(updatedFileList);
            setIsOpen(false); // Close the modal after submitting the cropped image                   
        } else {
            // Handle error condition (if necessary)
            console.error("File name or cropped area pixels are missing.");
        }
    };

    const saveChanges = () => {
        if (!fileList || fileList.length === 0) {
            console.error("No files to save.");
            return;
        }
        fileList.forEach((file) => {
            Object.keys(file).forEach((key) => {
                if (key.startsWith('file')) {
                    const aspectRatio = key.substring(4); // Extract aspect ratio from the key
                    const aspectRatioFile = file[key][0];
                    const base64String = aspectRatioFile.thumbUrl.split(',')[1]; // Extract base64 string from thumbUrl

                    // Convert base64 string to blob
                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'image/png' });

                    // Convert blob to file
                    const fileName = aspectRatioFile.originFileObj.name; // Use the original filename
                    const file_upload = new File([blob], fileName, { type: 'image/png' });
                    const type = aspectRatio === "1" ? "square" : "landscape";
                    Functions.handleAssetImageSubmit(file_upload, dispatch, uploadAssetImage, notification, setIsUpload, isLogo ? logoImage : type === "square" ? squareMarketingImage : marketingImage, setLoading,isLogo);
                }
            });
        });
    };

    const handleImageUpload = async (
        event
    ) => {
        const files = event.target.files;
        if (files) {
            setTotalCroppedImages(0);
            // extract base64 string from the image file
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = () => {
                if (isLogo) {
                    setOriginal(reader.result);
                    setFileList([{
                        originalFile: files,
                        file1: [{ thumbUrl: reader.result, originFileObj: files[0] }],
                    }]);
                }
                else {
                    setOriginal(reader.result);
                    setFileList([{
                        originalFile: files,
                        file1: [{ thumbUrl: reader.result, originFileObj: files[0] }],
                        file2: [{ thumbUrl: reader.result, originFileObj: files[0] }],
                    }]);
                }
            };
            // setImageHello(files[0]);
        }
    };


    const handlePreviewClick = (file, aspectRatio) => {
        setZoom(1);
        setImage(file.thumbUrl);
        setAspectRatio(aspectRatio);
        setIsOpen(true);
    };

    return (
        <div className="upload-asset" style={{ position: 'absolute' }}>
            <div>
                <h3>Upload Photo</h3>
                {original && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                    <img src={original} alt="original" style={{ width: '240px', height: '240px', objectFit: 'contain', marginRight: '10px' }} />
                    <div>
                        <p
                            onClick={() => {
                                setOriginal('');
                                setFileList([]);
                                setIsOpen(false);
                                setImage('');
                                setTotalCroppedImages(0);
                                isLogo={isLogo}

                            }}
                            className='delete-asset-button'
                        >
                            <DeleteOutlined />
                            Remove</p>
                        <label htmlFor="fileInputs">
                            <div>
                                <p className='replace-asset-button'>
                                    <ReloadOutlined />
                                    Replace</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    id="fileInputs"
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </label>
                    </div>
                </div>
                }                <br></br>
                {!original && <label htmlFor="fileInput">
                    <div className='inputBox'>
                        <CloudUploadOutlined className='upload-icon' />
                        <p style={{ fontSize: "14px", color: "#6F5CD9" }}>Upload Image</p>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            id="fileInput"
                            style={{ display: 'none' }}
                        />
                    </div>
                </label>}
                {fileList.length > 0 ?
                    <>
                        <div>
                            <span className='ratio-heading'><span className='error-asterisk-sign'>*</span>&nbsp;{isLogo ? "Crop image as per the ratio" : "Crop all images as per the ratio"}</span><br></br>
                            <span style={{ fontSize: "12px" }}>Please crop the image in all the respected size given below</span>
                        </div>
                        <div className="aspect-ratio-previews">
                            {isLogo ?

                                <div style={{ display: "flex", flexDirection: "column", padding: "30px 0px" }}>
                                    <span>Ratio - 1:1</span>
                                    <img
                                        src={fileList[0]?.file1[0]?.thumbUrl}
                                        alt="1"
                                        style={{
                                            width: '130px',
                                            height: '130px',
                                            objectFit: 'cover',
                                            marginRight: '10px',
                                            cursor: 'pointer',
                                            marginTop: "5px"
                                        }}
                                        onClick={() => handlePreviewClick(fileList[0].file1[0], 1)}
                                    />
                                </div>
                                :
                                <div style={{ display: "flex", padding: "30px 0px" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span>Ratio - 1:1</span>
                                        <img
                                            src={fileList[0]?.file1[0]?.thumbUrl}
                                            alt="1"
                                            style={{
                                                width: '130px',
                                                height: '130px',
                                                objectFit: 'cover',
                                                marginRight: '10px',
                                                cursor: 'pointer',
                                                marginTop: "5px"
                                            }}
                                            onClick={() => handlePreviewClick(fileList[0].file1[0], 1)}
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span>Ratio - 1:91:1</span>
                                        <img
                                            src={fileList[0]?.file2[0]?.thumbUrl}
                                            alt="2"
                                            style={{
                                                width: '200px',
                                                height: '130px',
                                                // objectFit: 'contain',
                                                marginRight: '10px',
                                                cursor: 'pointer',
                                                marginTop: "5px"
                                            }}
                                            onClick={() => handlePreviewClick(fileList[0].file2[0], 1.91)}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                    : ""
                }

            </div>
            {isOpen && image && (
                <div style={{ position: "relative", width: "400px", height: "400px" }}>
                    <Cropper
                        image={image}
                        imageStyle={{ width: "1200px", height: "800px" }}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspectRatio}
                        onCropChange={
                            setCrop
                        }
                        onCropComplete={onCropComplete}
                    />

                </div>
            )}
            {isOpen && image && fileList?.length > 0 && <>
                <div className="controls">
                    <input
                        type="range"
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e) => {
                            setZoom(e.target.value)
                        }}
                        className="zoom-range"
                    />
                </div>
                <button onClick={handleSubmitImage} className='save-changes-crop-button'>Save Changes</button>
            </>}
            <div style={{ display: 'flex', gap: '10px', justifyContent: "flex-end", marginTop: "50px" }}>
                <button className="back-button" onClick={handleCancel}>
                    Cancel
                </button>
                <button className="next-button" onClick={saveChanges} disabled={
                    isLogo ? totalCroppedImages !== fileList.length : totalCroppedImages !== 2
                }>
                    {
                        loading ? <Spin /> : "Upload"
                    }
                </button>
            </div>
            <br></br>
        </div>
    );
};

export default UploadImage;