import React, { useState } from "react";
import {
  PlusOutlined,
  VideoCameraOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, notification } from "antd";
import AssetDrawer from "../../common/AssetDrawer";
import { Functions } from "../../common/functions";
import { useDispatch } from "react-redux";
import UploadImage from "../Ads/Asset/UploadImage";
import UploadVideo from "../Ads/Asset/UploadVideo";
import UploadAssetImage from "../Ads/Asset/UploadAssetImage";

const UploadAsset = ({setIsUpload, customerId}) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const dispatch = useDispatch();
  const [videoUrl, setVideoUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [fileList, setFileList] = useState();

  const handleImageOk = () => {
    const asset_type = "IMAGE_URL";
    Functions.handleAssetSubmit(
      imageUrl,
      dispatch,
      notification,
      setShowImageModal,
      setImageUrl,
      asset_type,
      setIsUpload,
    );
  };
  const handleImageCancel = () => {
    setShowImageModal(false);
    setImageUrl("");
  };

  const handleVideoCancel = () => {
    setShowVideoModal(false);
    setVideoUrl("");
  };
  const handleVideoOk = () => {
    const asset_type = "YOUTUBE_VIDEO";
    Functions.handleAssetSubmit(
      videoUrl,
      dispatch,
      notification,
      setShowVideoModal,
      setVideoUrl,
      asset_type,
      setIsUpload
    );
  };
  const ImageItems = [
    {
      key: "2",
      label: "Upload",
      children: <UploadAssetImage setShowImageModal={setShowImageModal} customerId={customerId} setIsUpload={setIsUpload} type="image"/>
    },
  ];
  const VideoItems = [
    {
      key: "4",
      label: "Upload",
      children: <UploadVideo setVideoUrl={setVideoUrl} videoUrl={videoUrl} handleOk={handleVideoOk} handleCancel={handleVideoCancel} />,
    },
  ];
  
  const assets = [
    {
      key: "Images",
      label: (
        <div
          style={{ display: "flex", gap: "10px" }}
          onClick={() => setShowImageModal(true)}
        >
          <FileImageOutlined />
          <span>Upload Images</span>
        </div>
      ),
    },
    {
      key: "Videos",
      label: (
        <div style={{ display: "flex", gap: "10px" }}
        onClick={() => setShowVideoModal(true)}
        >
          <VideoCameraOutlined />
          <span>Upload Videos</span>
        </div>
      ),
    },
  ];

  const assetMenu = (
    <Menu>
      {assets.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={assetMenu} placement="bottomLeft">
        <button
          style={{
            display: "flex",
            border: "none",
            padding: "12px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            color: "#fff",
            width: "120px",
            borderRadius: "8px",
            background: "#8833FF",
            cursor: "pointer",
          }}
          // type="submit"
        >
          <PlusOutlined />
          <span>Upload</span>
        </button>
      </Dropdown>
      <AssetDrawer
        showAssetModal={showImageModal}
        // handleOk={handleImageOk}
        handleCancel={handleImageCancel}
        items={ImageItems}
        title="Choose Images to use in your Ad"
        type="image"
      />
      <AssetDrawer
        showAssetModal={showVideoModal}
        // handleOk={handleVideoOk}
        handleCancel={handleVideoCancel}
        items={VideoItems}
        title="Choose Videos to use in your Ad"
        type="video"
      />
    </div>
  );
};

export default UploadAsset;
