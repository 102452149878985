import React from 'react';
import { Form, Input } from 'antd';
const { Item } = Form;

const TextInput = ({ value, handleChange, handleBlur=null, label, name, validateStatus, help ,type,roas=false,macros=false}) => {
    return (
        <Item
            label={label}
            hasFeedback
            validateStatus={validateStatus}
            help={help}
            required
        >
            <Input value={value} name={name} onChange={handleChange} onBlur={handleBlur} type={type ? type : 'text'}
               prefix={type==='number' && !roas ? '$':''} className={`${roas?"text-roas":"text-field"}`}
                   suffix={roas?'%':''}
            />
        </Item>
    );
}

export default TextInput;