import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import "../../../styles/AdsForm/Assets.css"
import AssetDrawer from '../../../common/AssetDrawer';
import UploadVideo from './UploadVideo';
import { useDispatch } from 'react-redux';
import { notification,Avatar } from 'antd';
import { Functions } from '../../../common/functions';
import AssetListingGrid from '../../AssetLibrary/AssetListingGrid';
import UploadAssetImage from './UploadAssetImage';

const Assets = ({ formik, setSubmitLoading, submitLoading, squareMarketingImage, marketingImage, logoImage }) => {
    const [showImageModal, setShowImageModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showLogoModal, setShowLogoModal] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [isUpload, setIsUpload] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const dispatch = useDispatch();
    const [videoUrl, setVideoUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const logoList = JSON.parse(localStorage.getItem('logoList'));
    const imageList = JSON.parse(localStorage.getItem('imageList'));
    console.log(logoList, imageList)

    useEffect(() => {
        const adGroup = localStorage.getItem('adGroupData');
        const adGroupData = JSON.parse(adGroup);
        setCustomerId(adGroupData?.customer_id)
    }, [])
    const handleImageCancel = () => {
        setShowImageModal(false);
        setImageUrl('');
    };

    const handleVideoCancel = () => {
        setShowVideoModal(false);
        setVideoUrl('');
    }
    const handleLogoCancel = () => {
        setShowLogoModal(false);
    }
    const handleVideoOk = () => {
        const asset_type = "YOUTUBE_VIDEO"
        Functions.handleAssetSubmit(videoUrl, dispatch, notification, setShowVideoModal, setVideoUrl, asset_type, setIsUpload);
    }

    const ImageItems = [
        {
            key: '1',
            label: 'Asset Library',
            children: <AssetListingGrid selectedAsset={"Images"} customerId={customerId} assetUploaded={isUpload} setAssetUploaded={setIsUpload} />
        },
        {
            key: '2',
            label: 'Upload',
            // children: <UploadImage setImageUrl={setImageUrl} imageUrl={imageUrl} setIsUpload={setIsUpload} fileList={fileList} setFileList={setFileList} handleOk={handleImageOk} handleCancel={handleImageCancel}/>
            children: <UploadAssetImage setShowImageModal={setShowImageModal} customerId={customerId} setIsUpload={setIsUpload} formik={formik} squareMarketingImage={squareMarketingImage} marketingImage={marketingImage} logoImage={logoImage}
                setSubmitLoading={setSubmitLoading}
                submitLoading={submitLoading}
                type="image"
                />
        }
    ]
    const VideoItems = [
        {
            key: '3',
            label: 'Asset Library',
            children: <AssetListingGrid selectedAsset={"Videos"} customerId={customerId} assetUploaded={isUpload} setAssetUploaded={setIsUpload} />
        },
        {
            key: '4',
            label: 'Upload',
            children: <UploadVideo setVideoUrl={setVideoUrl} videoUrl={videoUrl} handleOk={handleVideoOk} handleCancel={handleVideoCancel} />
        }
    ]
    const LogoItems = [
        {
            key: '5',
            label: 'Asset Library',
            children: <AssetListingGrid selectedAsset={"Logos"} customerId={customerId} assetUploaded={isUpload} setAssetUploaded={setIsUpload} />
        },
        {
            key: '6',
            label: 'Upload',
            children: <UploadAssetImage setShowImageModal={setShowImageModal} customerId={customerId} setIsUpload={setIsUpload} formik={formik} squareMarketingImage={squareMarketingImage} marketingImage={marketingImage} logoImage={logoImage}
                setSubmitLoading={setSubmitLoading}
                submitLoading={submitLoading}
                type="logo"
                />
        }
    ]

    return (
        <div className="form-main">
            <div>
                <div className='form-heading-logo'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4 6H20M7 12H20M10 18H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h2 className='campaign_name'>Upload Assets &nbsp; <span className='error-asterisk-sign'>*</span></h2>
                </div>
                <div className=''>
                    <div>
                        <button type='button' className='asset-image-heading'
                            onClick={() => {
                                setShowImageModal(true);
                            }}
                        ><PlusOutlined /> Image</button>
                    </div>
                    <div>
                    {imageList && imageList.length > 0 ? (
                        <div>
                            <div className='asset-image-uploaded-list'>
                            {imageList.map((item, index) => (
                                <div key={index} className='asset-image-container'>
                                    <Avatar shape="square" size={64} src={item.url} />
                                </div>
                            ))}
                            </div>
                        </div>
                    ) : ""}

                </div>
                    <div>
                        <button type='button' className='asset-image-heading'
                            onClick={() => {
                                setShowVideoModal(true);
                            }}
                        ><PlusOutlined /> Video</button>
                    </div>
                    <div>
                        <button type='button' className='asset-image-heading'
                            onClick={() => {
                                setShowLogoModal(true);
                            }}
                        ><PlusOutlined /> Logo</button>
                    </div>
                    <div>
                    {logoList && logoList.length > 0 ? (
                        <div>
                            <div className='asset-image-uploaded-list'>
                            {logoList.map((item, index) => (
                                <div key={index} className='asset-image-container'>
                                    <Avatar shape="square" size={64} src={item.url} />
                                </div>
                            ))}
                            </div>
                        </div>
                    ) : ""}

                </div>
                </div>                               
                <AssetDrawer showAssetModal={showImageModal} handleCancel={handleImageCancel} items={ImageItems} title="Choose Images to use in your Ad" type={fileList?.length > 0 ? "image_uploaded" : "image_upload"} />
                <AssetDrawer showAssetModal={showVideoModal} handleCancel={handleVideoCancel} items={VideoItems} title="Choose Videos to use in your Ad" type="video" />
                <AssetDrawer showAssetModal={showLogoModal} handleCancel={handleLogoCancel} items={LogoItems} title="Choose Logos to use in your Ad" type="logo" />
            </div>

        </div>
    );
}

export default Assets;