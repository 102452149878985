import CreateSeperateAddCampaign from "./CreateAddComponents/IndividualAddCampaign";
import CreateAdsCampaign from "./CreateAddComponents/CreateWithCampaign";

const AdCampaign = ({campaignId, setGroupId,setCurrent,toSave,setToSave,current}) => {
    let cmpId=campaignId;
    if(!campaignId && setGroupId){
        let temp=localStorage.getItem('campaignId');
        if(temp){
           const data=JSON.parse(temp);
           let str= data?.created_campaign || data?.resource_name
            cmpId=str.split("/").pop()
        }
    }
    if (!cmpId) {
        return CreateSeperateAddCampaign()

    } else {
        return CreateAdsCampaign({campaignId:cmpId, setGroupId,setCurrent,toSave,setToSave,current});
    }
};


export default AdCampaign;
