import React, { useState } from "react";
import UploadAsset from "./UploadAsset";
import AssetListingGrid from "./AssetListingGrid";
import "../../styles/AssetLibrary/AssetTypes.css";
import { Pagination } from "antd";
import { assets } from "../../common/enums";

const AssetTypes = ({ customerId }) => {
  const [selectedAsset, setSelectedAsset] = useState("All");
  const [isUpload, setIsUpload] = useState(false);

  const handleAsset = (value) => {
    setSelectedAsset(value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "48px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "12px",
          }}
        >
          {assets?.map((value, index) => (
            <button
              key={index}
              className={`asset-types ${value === selectedAsset && `selected-asset-types`} `}
              onClick={() => handleAsset(value)}
            >
              <span style={{ color: "#8833FF" }}>{value}</span>
            </button>
          ))}
        </div>
        <div
          style={{display: "flex", gap: "20px"}}
        >
          <UploadAsset setIsUpload={setIsUpload} customerId={customerId}/>
          {/* <button
              className="asset-types"
              style={{
                alignItems: 'center'
              }}
            >
              <span style={{ color: "#8833FF"}}>Add Filter</span>
          </button> */}
        </div>
      </div>
      <div>
        <AssetListingGrid selectedAsset={selectedAsset} customerId={customerId} assetUploaded={isUpload} setAssetUploaded={setIsUpload} />
      </div>
    </div>
  );
};

export default AssetTypes;
