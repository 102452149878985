import React from 'react';
import Card from '../../../common/Card';
import {CampaignTypes} from "../../../common/enums";
const CampaignType = ({
                          handleChange,
                          help,
                          validateStatus,
                          value }) => {

    return (
        <div style={{ background: "#fff", padding: "32px", borderRadius: "8px", maxWidth: "1000px" }}>
            <div>
                <h2 className='campaign_name'><span className='error-asterisk-sign'>*</span> &nbsp; Select a Campaign Type</h2>              
                <div style={{ padding: "40px 0px" }}>
                    <Card
                        options={CampaignTypes}
                        handleChange={handleChange}
                        help={help}
                        validateStatus={validateStatus}
                        name="advertising_channel_type"
                        type="card-type"
                        value={value}
                    />

                </div>
            </div>
            {validateStatus ? <span style={{ color: "red", fontSize: "12px"}}>{help}</span> : null}

        </div>
    );
}

export default CampaignType;