import {Card, Spin} from "antd";


const ReusableAddForm = ({loading,children,contentLoading=false}) => {

    return <div >
        <Spin spinning={loading || contentLoading}>
            <Card>
                {children}
            </Card>
        </Spin>
    </div>

}

export default ReusableAddForm;
