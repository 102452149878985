import React, {useContext, useEffect, useState} from "react";
import Listing from "../../common/Listing";
import {useDispatch, useSelector} from "react-redux";
import {NavBarContext} from "../Navbar/Navbar";
import {Functions} from "../../common/functions";

const AdGroupList = () => {
    const [loading, setLoading] = useState(false);
    const [contentLoading, setContentLoading] = useState(false);
    const dispatch = useDispatch();

    const { adGroupList,campaigns } = useSelector((state) => state.campaigns);
    const {setParamsCampaignId,setParamsCustomerId}=useContext(NavBarContext)
    const params=new URLSearchParams(window.location.search);
    const paramsCampId=params.get('campaign_id');
    const paramsCustomerId=params.get('customer_id');


    useEffect(()=>{
        setParamsCampaignId(paramsCampId);
        setParamsCustomerId(paramsCustomerId);
    },[])

    const getAdGroupList=async (campaignId,customerId)=>Functions.getAdGroupList(setLoading,campaignId?.id || campaignId,customerId,null,dispatch)


    const getCampaignIds=async (selectedAccount)=>Functions.fetchCampaignDetails(selectedAccount,setContentLoading,dispatch)

    return <>
        <Listing setLoading={setLoading} loading={loading} data={adGroupList} adgroup={true} campaignIds={paramsCampId?null:campaigns}  fetchDetails={getCampaignIds} getAdsDetails={getAdGroupList} contentLoading={contentLoading}  />
    </>

}


export default AdGroupList;