import React from "react";
import { Route, Navigate } from "react-router-dom";

const ProtectedRoute = ({ element: Component }) => {
    const isAuthenticated = () => {
        return localStorage.getItem("login") !== null;
    }

    return (
        <>
            {isAuthenticated() ? <Component /> : <Navigate to={'/login'} replace />}
        </>
    );
};

export default ProtectedRoute;
