import {createAsyncThunk} from "@reduxjs/toolkit";
import {CampaignActionTypes} from "../actions/CampaignAction";
import { CREATE_AD_GROUP, CREATE_CAMPAIGN, GET_CAMPAIGN_INFO, LIST_AD_GROUP } from "../types/CampaignActionTypes";


export const getCampaignInfo=createAsyncThunk(GET_CAMPAIGN_INFO,async ({campaignId})=>{
    try {
        const res= await CampaignActionTypes.getCampaignInfo(campaignId);
        return res?.data;
    } catch (e) {
        throw  e?.response?.data?.error[0] || e;
    }
});


const GET_CAMP='account/getCamp';

export const getCampaignsWithoutStoring=createAsyncThunk(GET_CAMP,async ({campaignId})=>{
    try {
        const res= await CampaignActionTypes.getCampaignInfo(campaignId);
        return res?.data;
    } catch (e) {
        throw  e?.response?.data?.error[0] || e;
    }
})


export const createCampaign=createAsyncThunk(CREATE_CAMPAIGN,async (body)=>{
    try{
        const res=await CampaignActionTypes.createCampaign(body);
        return res?.data;
    } catch (e){
        throw  e?.response?.data?.error[0] || e;
    }
});

export const createAdGroup=createAsyncThunk(CREATE_AD_GROUP,async (body)=>{
    try{
        const res=await CampaignActionTypes.createAdGroup(body);
        return res?.data;
    } catch (e){

        throw  e?.response?.data?.error[0] || e;
    }
});


export const listAdGroup=createAsyncThunk(LIST_AD_GROUP,async (body)=>{
    try{
        const res=await CampaignActionTypes.listAdGroup(body);
        return res?.data;
    } catch (e){

        throw  e?.response?.data?.error[0] || e;
    }
});

const UPDATE_CAMPAIGN='account/campaignUpdate';
export const updateCampaign=createAsyncThunk(UPDATE_CAMPAIGN,async (body)=>{
    try{
        const res=await CampaignActionTypes.updateCampaign(body);
        return res?.data;
    } catch (e){
        throw  e?.response?.data?.error[0] || e;
    }
})


const DELETE_CAMPAIGN='account/campaignDelete';
export const deleteCampaign=createAsyncThunk(DELETE_CAMPAIGN,async (body)=>{
    try{
        const res=await CampaignActionTypes.deleteCampaign(body);
        return res?.data;
    } catch (e){
        throw  e?.response?.data?.error[0] || e;
    }
})


const UPDATE_ADGROUP='account/adGroupUpdate';
export const updateAdGroup=createAsyncThunk(UPDATE_ADGROUP,async (body)=>{
    try{
        const res=await CampaignActionTypes.updateAdGroup(body);
        return res?.data;
    } catch (e){

        throw  e?.response?.data?.error[0] || e;
    }
});
