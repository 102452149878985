import {Table} from "antd";
import {useNavigate} from "react-router-dom";


const TableListing = ({columns,searchedData,handleChange})=> {
    const navigate = useNavigate();
    return  <Table
        columns={columns}
        dataSource={searchedData}
        style={{minWidth: "100%", minHeight: "100%",padding:"0px 27px"}}
        scroll={{x: "max-content", y: '70vh'}}
        pagination={{style: {textAlign: "center"}}}
        onChange={handleChange}
    />
}



export default TableListing;