import React, { useState } from "react";
import { Form, Select } from "antd";
const { Item } = Form;

const MultitextInput = ({
  value,
  handleChange = null,
  label,
  name,
  validateStatus,
  help,
  setItems,
  handleSelectedValue,
  handleDeselectedValue,
  items,
}) => {
  const [val, setVal] = useState('');

  let handlePresentValue = () => {
    return items?.find((item) => item === val);
  }

  return (
    <>
      <Item
        label={label}
        // name={name}
        hasFeedback
        validateStatus={validateStatus}
        help={help}
        required
      >
        <input value={val} 
          type="text"
          style={{
            padding: '8px', 
            height: '45px', 
            width: '100%',
          }} 
          className="multi-text-inputField"
          onChange={(e) => {
            setVal(e.target.value);
          }}
          onKeyPress={(e) => {
            if(e.key === 'Enter' && val.trim() !== "") {
              e.preventDefault();
              if(!handlePresentValue()) {
                setItems([...items, val]);
                handleSelectedValue(val, name);
              }
              else {
                let filterItems = items.filter((item) => item !== val);;
                setItems(filterItems);
                handleDeselectedValue(val, name);
              }
              setVal('');
            }
          }}
        />
      </Item>
    </>
  );
};

export default MultitextInput;
