import {createSlice} from "@reduxjs/toolkit";
import {handleAsyncAction} from "../common/AsyncCases";
import {listBudget} from "../thunk/BudgetThunk";


const BudgetSlice = createSlice({
    name: "Budget",
    initialState: {
        isLoading: false,
        error: null,
        budgetList:[]
    },
    reducers:{},
    extraReducers:(builder)=>{
        handleAsyncAction(builder,listBudget,(state, action)=>{
            state.budgetList=action?.payload?.data || [];
        })
    }
})

export default BudgetSlice.reducer;