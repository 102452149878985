import {Form, Select} from "antd";
const { Option } = Select;
const {Item} = Form;

const CampaignSelect = ({label, name, validateStatus, help, selectedCampaign,handleSelect,options,handleChange=null,position})=>{


    const filterOptionsCampaignNameDropdown = (inputValue, option) =>
        option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

   return (
   <Item
            label={label}
            name={name}
            hasFeedback
            validateStatus={validateStatus}
            help={help}
            required
        >
   <Select value={selectedCampaign?.name || selectedCampaign} showSearch
            className={`single_select ${position === "header" && "select-nav"}`}
            style={{height:"45px",marginTop:"8px"}}
            filterOption={filterOptionsCampaignNameDropdown}
            onSelect={(val,record)=>handleSelect(val,record,handleChange)}>
        {options?.map((option, index) => (
            <Option key={index} value={option?.id}>
                {option?.name}
            </Option>
        ))}
    </Select>
    </Item>
   )
}


export default CampaignSelect;