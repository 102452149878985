import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {
    AgeRangeMap,
    AgeRangeTypeEnumValues,
    BiddingStratergyOptions, DisplayBiddingStrategyOptions, GenderTypeEnumValues,
    languageIds, languageLabels,
    locationIds, locationNames,
    LocationOptions,
    operatingSystemVersionEnums, SearchBiddingStrategyOptions, versionToIdMap
} from "../../common/enums";
import {createCampaign, updateCampaign} from "../../redux/thunk/CampaignThunk";
import {CREATE_CAMPAIGN_FULFILLED, CREATE_CAMPAIGN_REJECTED} from "../../redux/types/CampaignActionTypes";
import {Form, List, notification, Spin} from "antd";
import {RESPONSE_SERVICES} from "../../common/Services";
import {Field, Formik} from "formik";
import TextInput from "../../common/TextInput";
import SelectInput from "../../common/SelectBoxes/SelectInput";
import {date} from "yup";
import {current} from "@reduxjs/toolkit";
import CampainBudget from "./FormComponents/CampaignBudget";
import { convertToMacros } from "../../common/UtilityFunctions";
import BackandSubmit from "../../common/BackandSubmit";

const {Item}=List;

const CreateAddCampaign = ({previousData,setCurrent,setCampaignId,toSave,current,setToSave}) => {
    let prevValues=localStorage.getItem("newCampaignInfo");
    if(prevValues){
        prevValues=JSON.parse(prevValues);
    }
    let prevData=localStorage.getItem('campaignInfo');
    if(prevData){
        prevData=JSON.parse(prevData);
    }

    const [loading, setLoading] = useState(false);
    const [isOperatingSystem, setIsOperatingSystem] = useState(true);
    const [biddingType, setBiddingType] = useState(prevValues?.bidding_strategy);
    const [isTypeSearch, setIsTypeSearch] = useState(false);
    const params=new URLSearchParams(window.location.search);
    const campaignId=params.get("campaign_id");
    const dispatch = useDispatch();
    const isCampaignId=localStorage.getItem("campaignId");

    useEffect(() => {
            if(prevData?.advertising_channel_type==='SEARCH'){
                setIsTypeSearch(true);
                setIsOperatingSystem(false);
            }
    }, [prevData]);

    const campaignForm = {
        initialValues: {
            budget_name: prevData? `${prevData?.campaign_name} Budget 1`:"",
            budget_delivery_method:  "",
            budget_amount_micros:  0,
            bidding_strategy: "",
            location: "",
            target_impression_share: 0,
            bid_ceiling_micros: 0,
            target_cpa_micros: 0,
            is_shared_budget: false,
            age_range_type: "",
            gender_type: "",
            operating_system_version: '',
            language_id: '',
            location_id: '',


        },
        validationSchema: Yup.object().shape({
            bidding_strategy: Yup.string().required('Bidding strategy is required'),
            location: biddingType==='TARGET_IMPRESSION_SHARE' ?Yup.string().required('Location is required'):Yup.string(),
            target_impression_share:biddingType==='TARGET_IMPRESSION_SHARE' ? Yup.number().nullable().typeError('Target impression share must be a number').required("Target impression is required"):Yup.number(),
            bid_ceiling_micros:(biddingType==="TARGET_IMPRESSION_SHARE" || biddingType==='TARGET_SPEND')? Yup.number().nullable().typeError('Enter an amount').required("Target CPA is required").min(10000,'Minimum amount should be 10000').min(1,'Enter a value'):Yup.number(),
            target_cpa_micros:(biddingType==='MAXIMIZE_CONVERSION_VALUE' || biddingType==='MAXIMIZE_CONVERSIONS') ?Yup.number().nullable().typeError('ROAS must be a number').required("Enter a value").max(1000,'Cannot be greater than 1000').min(1,"Enter a value"):Yup.number(),
            is_shared_budget:( (biddingType==='MAXIMIZE_CONVERSION_VALUE' || biddingType==='MAXIMIZE_CONVERSIONS') && !isTypeSearch)?Yup .boolean(): Yup.boolean().nullable().required(),
            age_range_type: Yup.string(),
            gender_type: Yup.string(),
            operating_system_version:Yup.string(),
            language_id: Yup.string(),
            location_id: Yup.string(),
            budget_name: Yup.string().required("Budget Name is required"),
            budget_delivery_method: Yup.string().required("Budget Delivery Method is required"),
            budget_amount_micros: Yup.number().required("Budget Amount is required").min(1, 'Amount should be greater than 0').max(100000000, 'Amount should be less than 100000000'),

        }),
    }

    const handleSubmit= async (values) => {
        try {
            setLoading(true);
            localStorage.setItem("newCampaignInfo", JSON.stringify(values));
            const iosVersion=operatingSystemVersionEnums.findIndex(val=>val===values?.operating_system_version)
            const locationId=locationIds[values?.location_id];
            const languageId=languageIds[values?.language_id];
            let body = { ...values,"budget_amount_micros":convertToMacros(values.budget_amount_micros),
                "bid_ceiling_micros":convertToMacros(values.bid_ceiling_micros),
                "customer_id": campaignId.toString(),...prevData ,
                "operating_system_version":isOperatingSystem?versionToIdMap[values.operating_system_version]:0,
                "location_id":locationId,"language_id":languageId, "target_partner_search_network": false ,
                "is_shared_budget":( (biddingType==='MAXIMIZE_CONVERSION_VALUE' || biddingType==='MAXIMIZE_CONVERSIONS') && !isTypeSearch)?false:values.is_shared_budget,
                "age_range_type":values?.age_range_type?AgeRangeMap[values?.age_range_type]:null,
            };

            if(body?.advertising_channel_type==="DISPLAY") {
                body.target_google_search = false;
                body.target_search_network = false;
                body.target_content_network = true;
            }
            if(isCampaignId){
                submitUpdateCampaign(body)
            } else {
                submitNewCampaign(body);
            }
            // setLoading(false);

        } catch (e) {
            setLoading(false);
            RESPONSE_SERVICES.errorService(e);

        }        
    }

    const submitUpdateCampaign = async (values) => {
        setLoading(true);
        const data=JSON.parse(isCampaignId);
                const body={...values,"campaign_resource":data?.created_campaign || data?.resource_name,"budget_resource":data?.budget_resource}
        const res=await dispatch(updateCampaign(body));
        if(res?.type==="account/campaignUpdate/fulfilled"){
            notification.success({ message: "Successfully created Campaign" });
            localStorage.setItem("campaignId", JSON.stringify(res?.payload?.data));
            const currentPage=localStorage.getItem('lastPage');
            localStorage.setItem('lastPage',currentPage>=2?currentPage:2);
            setToSave(2)
            setCurrent(2);
            setToSave(2)
            setCurrent(2);

            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if(res?.type==="account/campaignUpdate/rejected") {
            setLoading(false);
            RESPONSE_SERVICES.errorService(res?.error);
        }
        // setLoading(false);
    }



    const submitNewCampaign = async (body) => {
        setLoading(true);
        const res = await dispatch(createCampaign(body));
        if (res?.type === CREATE_CAMPAIGN_FULFILLED) {
            notification.success({ message: "Successfully created Campaign" });
            const id = res.payload?.data?.resource_name?.split("/")[3];
            setCampaignId(id);
            localStorage.setItem("campaignId",JSON.stringify(res?.payload?.data));
            const currentPage=localStorage.getItem('lastPage');
            localStorage.setItem('lastPage',currentPage>=2?currentPage:2);
            setToSave(2)
            setCurrent(2);
            window.scrollTo({ top: 0, behavior: 'smooth' });

        }
        if (res?.type === CREATE_CAMPAIGN_REJECTED) {
            setLoading(false);
            RESPONSE_SERVICES.errorService(res.error);
        }
        // setLoading(false);
    }

    const {initialValues,validationSchema}=campaignForm;

    return  (

        <div style={{ marginTop: "20px"}}>
        <Formik
            initialValues={
                prevValues || initialValues
            }
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >

            {({ values, handleChange, setFieldValue, errors, touched,handleSubmit }) =>

            {
                if(toSave!==current && current===1) {
                    localStorage.setItem("newCampaignInfo", JSON.stringify(values));
                    setCurrent(toSave);
                }
                return(
                    <Form layout="vertical" onFinish={handleSubmit} style={{
                        padding: '20px',
                        display: 'grid',
                        // gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '20px'
                    }}>



                        <CampainBudget handleChange={handleChange}
                                       values={values}
                                       errors={errors}
                                       touched={touched}
                                     />
                        <div className='form-main'>
                            <div>
                                <div style={{marginTop: "5px"}}>
                                    <span className='objective-heading'>Choose your Bidding type</span><br></br>
                                    <span className='objective-detail'>Select an Bidding to tailor your experience to the goals and settings that will work best for your campaign</span>
                                </div>
                                <div>

                                    <div style={{
                                        background: '#fff',
                                        padding: '16px 0px 0px 0px',
                                        borderRadius: '8px'
                                    }}>
                                        <div>
                                            <div className='campaign-name-form-main'>
                                                <div>
                                                    <p><span className='error-asterisk-sign'>*</span> What do you want to focus on?</p>
                                                    <SelectInput
                                                        setInput={setBiddingType}
                                                        options={isTypeSearch ? SearchBiddingStrategyOptions : DisplayBiddingStrategyOptions}
                                                        value={values.bidding_strategy}
                                                        handleChange={handleChange}
                                                        x name='bidding_strategy'
                                                        validateStatus={errors.bidding_strategy && touched.bidding_strategy ? "error" : ""}
                                                        help={errors.bidding_strategy && touched.bidding_strategy ? errors.bidding_strategy : ""}
                                                    />
                                                </div>
                                                {
                                                    (biddingType === "TARGET_IMPRESSION_SHARE" || biddingType === 'TARGET_SPEND') &&
                                                    <div>

                                                                <p><span className='error-asterisk-sign'>*</span> Maximum CPC bid limit</p>
                                                                <TextInput
                                                                    type={'number'}
                                                                    value={values.bid_ceiling_micros}
                                                                    handleChange={handleChange}
                                                                    name='bid_ceiling_micros'
                                                                    validateStatus={errors.bid_ceiling_micros && touched.bid_ceiling_micros ? "error" : ""}
                                                                    help={errors.bid_ceiling_micros && touched.bid_ceiling_micros ? errors.bid_ceiling_micros : ""}
                                                                />


                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className='form-main'>
                            <div>
                                <div style={{marginTop: "5px"}}>
                                    <span className='objective-heading'>Choose your Audience</span><br></br>
                                    <span className='objective-detail'>Select Audience network that fulfils goal of your campaign</span>
                                </div>
                                <div>

                                    <div style={{
                                        background: '#fff',
                                        padding: '16px 0px 0px 0px',
                                        borderRadius: '8px'
                                    }}>
                                        <div>
                                            <div className='campaign-name-form-main'>


                                                {


                                                    biddingType === 'TARGET_IMPRESSION_SHARE' && <div>
                                                        <p><span className='error-asterisk-sign'>*</span> Location</p>
                                                        <SelectInput
                                                            options={LocationOptions}
                                                            value={values.location}
                                                            handleChange={handleChange}
                                                            x name='location'
                                                            validateStatus={errors.location && touched.location ? "error" : ""}
                                                            help={errors.location && touched.location ? errors.location : ""}
                                                        />
                                                    </div>}

                                                {biddingType === 'TARGET_IMPRESSION_SHARE' && <div>
                                                    <p>Target Impression Share</p>
                                                    <TextInput
                                                        type={'number'}
                                                        value={values.target_impression_share}
                                                        handleChange={handleChange}
                                                        x name='target_impression_share'
                                                        validateStatus={errors.target_impression_share && touched.target_impression_share ? "error" : ""}
                                                        help={errors.target_impression_share && touched.target_impression_share ? errors.target_impression_share : ""}
                                                    />
                                                </div>}
                                                {
                                                    (biddingType === 'MAXIMIZE_CONVERSION_VALUE' || biddingType === 'MAXIMIZE_CONVERSIONS') &&
                                                    <div>
                                                      <div>
                                                                <p><span className='error-asterisk-sign'>*</span> Target ROAS</p>
                                                                <TextInput
                                                                    type={'number'}
                                                                    roas={true}                                                                    
                                                                    value={values.target_cpa_micros}
                                                                    handleChange={handleChange}
                                                                    x name='target_cpa_micros'
                                                                    validateStatus={errors.target_cpa_micros && touched.target_cpa_micros ? "error" : ""}
                                                                    help={errors.target_cpa_micros && touched.target_cpa_micros ? errors.target_cpa_micros : ""}
                                                                />
                                                            </div>
                                                    </div>}
                                                {!!(!((biddingType === 'MAXIMIZE_CONVERSION_VALUE' || biddingType === 'MAXIMIZE_CONVERSIONS') && !isTypeSearch)) &&
                                                    <div>
                                                        <label>
                                                            <Field type="checkbox" name="is_shared_budget"/>
                                                            Shared Budget
                                                        </label>

                                                    </div>}

                                                <div>
                                                    <p>Age Range</p>
                                                    <SelectInput
                                                        options={AgeRangeTypeEnumValues}
                                                        value={values.age_range_type}
                                                        handleChange={handleChange}
                                                        x name='age_range_type'
                                                        validateStatus={errors.age_range_type && touched.age_range_type ? "error" : ""}
                                                        help={errors.age_range_type && touched.age_range_type ? errors.age_range_type : ""}
                                                    />
                                                </div>
                                                <div>
                                                    <p>Gender Type </p>
                                                    <SelectInput
                                                        options={GenderTypeEnumValues}
                                                        value={values.gender_type}
                                                        handleChange={handleChange}
                                                        x name='gender_type'
                                                        validateStatus={errors.gender_type && touched.gender_type ? "error" : ""}
                                                        help={errors.gender_type && touched.gender_type ? errors.gender_type : ""}
                                                    />
                                                </div>
                                                <div>
                                                    <p>Language </p>
                                                    <SelectInput

                                                        options={languageLabels}
                                                        value={values.language_id}
                                                        handleChange={handleChange}
                                                        x name='language_id'
                                                        validateStatus={errors.language_id && touched.language_id ? "error" : ""}
                                                        help={errors.language_id && touched.language_id ? errors.language_id : ""}
                                                    />
                                                </div>
                                                <div>
                                                    <p>Geo Location </p>
                                                    <SelectInput

                                                        options={locationNames}
                                                        value={values.location_id}
                                                        handleChange={handleChange}
                                                        x name='location_id'
                                                        validateStatus={errors.location_id && touched.location_id ? "error" : ""}
                                                        help={errors.location_id && touched.location_id ? errors.location_id : ""}
                                                    />
                                                </div>
                                                {
                                                    !!isOperatingSystem && <div>
                                                        <p>Operating System Version</p>
                                                        <SelectInput

                                                            options={operatingSystemVersionEnums}
                                                            value={values.operating_system_version}
                                                            handleChange={handleChange}
                                                            x name='operating_system_version'
                                                            validateStatus={errors.operating_system_version && touched.operating_system_version ? "error" : ""}
                                                            help={errors.operating_system_version && touched.operating_system_version ? errors.operating_system_version : ""}
                                                        />
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        <div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div style={{display: 'flex', gap: '30px', justifyContent: 'flex-end'}}>
                            <BackandSubmit toShow={true} submitLoading={loading} index={0} setToSave={setToSave}/>
                        </div>

                                                
                    </Form>
            )}}


            </Formik>

            </div>
    )

            }


            export default CreateAddCampaign;
