import {createAsyncThunk} from "@reduxjs/toolkit";
import {LinkedAccountActionTypes} from "../actions/linkedAccountAction";
import { LINKED_ACCOUNTS } from "../types/linkedAccountActionTypes";


export const getLinkedAccount=createAsyncThunk(LINKED_ACCOUNTS,async ({email})=>{
    try {
        const linkedAccounts = await LinkedAccountActionTypes.getLinkedAccount(email);
        return linkedAccounts?.data?.data;
    } catch (e) {
        throw  e?.response?.data?.error[0] || e;
    }
})