import TextInput from "../../../common/TextInput";
import SelectInput from "../../../common/SelectBoxes/SelectInput";
import CampaignSelect from "../../../common/SelectBoxes/CampaignSelect";
import { Form, Input } from "antd";
const AdsDetails = ({
  campaignId,
  customerIdOptions,
  handleCustomerIdChange,
  campaignIdOptions,
  handleCampaignIdChange,
  adGroupListOptions,
  handleAdGroupListChange,
  formik,
}) => {
  return (
    <div className="form-main">
      <div>
        <div className="form-heading-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 6H20M7 12H20M10 18H20"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h2 className="campaign_name">Ad Details</h2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "32px",
          }}
        >

          {!!(!campaignId) && (
            <>
              <div>
                <p><span className='error-asterisk-sign'>*</span> Customer ID</p>
                <SelectInput
                  value={formik?.values?.customer_id}
                  handleChange={formik.handleChange}
                  name="customer_id"
                  options={customerIdOptions}
                  help={formik?.touched?.customer_id && formik?.errors?.customer_id}
                  handleDataChange={handleCustomerIdChange}
                  validateStatus={formik?.touched?.customer_id && formik?.errors?.customer_id ? "error" : ""}
                />
              </div>
              <div>
                <p><span className='error-asterisk-sign'>*</span> Campaign Name</p>
                <SelectInput
                  value={formik?.values?.campaign_id}
                  handleChange={formik.handleChange}
                  name="campaign_id"
                  options={campaignIdOptions}
                  help={formik?.touched?.campaign_id && formik?.errors?.campaign_id}
                  handleDataChange={handleCampaignIdChange}
                  validateStatus={formik?.touched?.campaign_id && formik?.errors?.campaign_id ? "error" : ""}
                />
              </div>
              <div>
                <p><span className='error-asterisk-sign'>*</span> Ad Group Name</p>
                <SelectInput
                  value={formik?.values.ad_group_id}
                  handleChange={formik.handleChange}
                  name="ad_group_id"
                  options={adGroupListOptions}
                  help={formik?.touched?.ad_group_id && formik?.errors?.ad_group_id}
                  handleDataChange={handleAdGroupListChange}
                  validateStatus={formik?.touched?.ad_group_id && formik?.errors?.ad_group_id ? "error" : ""}
                />
              </div>
            </>
          )}
          <div>
            <p><span className='error-asterisk-sign'>*</span> Name</p>
            <TextInput
              value={formik.values.name}
              handleChange={formik?.handleChange}
              name="name"
              handleBlur={formik?.handleBlur}
              help={formik?.touched?.name && formik?.errors?.name}
              validateStatus={formik?.touched?.name && formik?.errors?.name ? "error" : ""}
            />
          </div>
          <div>
            <p><span className='error-asterisk-sign'>*</span> Final URLs</p>
            <Form.Item required validateStatus={formik?.touched?.final_urls && formik?.errors?.final_urls ? "error" : ""}>
              {formik.values.final_urls.map((url, index) => (
                <Input
                  key={index}
                  value={url}
                  onChange={(e) => {
                    const newUrls = [...formik.values.final_urls];
                    newUrls[index] = e.target.value;
                    formik.setFieldValue("final_urls", newUrls);
                  }}
                  onBlur={formik.handleBlur}
                  className="text-field"
                />
              ))}
              {formik.touched.final_urls && formik.errors.final_urls ? (
                <div style={{ color: "red" }}>{formik.errors.final_urls}</div>
              ) : null}
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsDetails;
