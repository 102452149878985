import {createSlice} from "@reduxjs/toolkit";
import {handleAsyncAction} from "../common/AsyncCases";
import {createAdGroup, createCampaign, getCampaignInfo, listAdGroup} from "../thunk/CampaignThunk";


const CampaignSlice = createSlice({
    name: "Campaign",
    initialState: {
        campaigns: [],
        adGroupList:[],
        isLoading:false,
        error:null
    },
    reducers:{},
    extraReducers:(builder)=>{
        handleAsyncAction(builder,getCampaignInfo,(state, action) => {
            state.campaigns=action.payload?.data?.campaigns || [];

        });
        handleAsyncAction(builder,listAdGroup,(state, action) => {
            state.adGroupList=action?.payload?.data?.ad_groups;
        });


    }
})

export default CampaignSlice.reducer;