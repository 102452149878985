import { Button, Form, Input } from "antd";
const AdsHeadlines = ({
  formik,
  numHeadlines,
  maxHeadlines,
  addHeadline,
  removeHeadline,
}) => {
  return (
    <div className="form-main">
      <div>
        <div className="form-heading-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 6H20M7 12H20M10 18H20"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h2 className="campaign_name">Headlines</h2>
        </div>
        <div
          style={{
            display: "grid",
          }}
        >
          <Form.Item required>
            {formik?.values?.headlines?.map((headline, index) => (
              <>
                <span><span className='error-asterisk-sign'>*</span> Headline {index + 1}</span>
                <div key={index} style={{ marginTop: "-10px" }}>
                  <Input
                    value={headline}
                    onChange={(e) => {
                      const newHeadlines = [...formik.values.headlines];
                      newHeadlines[index] = e.target.value;
                      formik.setFieldValue("headlines", newHeadlines);
                    }}
                    onBlur={formik.handleBlur}
                    style={{ marginBottom: "10px" }}
                    className="text-field"
                  />
                  {numHeadlines > 3 && index > 2 && (
                    <Button onClick={() => removeHeadline(index)}>
                      Remove
                    </Button>
                  )}
                </div>
              </>
            ))}
            <Button
              type="dashed"
              onClick={addHeadline}
              style={{ width: "100%", marginTop: "10px" }}
              disabled={numHeadlines >= maxHeadlines}
            >
              Add Headline
            </Button>
            <span style={{ marginLeft: "10px", color: "gray" }}>
              {numHeadlines}/{maxHeadlines} rows
            </span>
          </Form.Item>
          {
            (formik?.touched?.headlines && formik?.errors?.headlines) && (
              <p style={{ color: "red" }}>{(formik?.touched?.headlines && formik?.errors?.headlines)?.length > 0 ? `Minimum ${numHeadlines} Headlines are Required` : ""}</p>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default AdsHeadlines;
