import React from 'react';
import CampaignForm from './CampaignForm';
import CreateAdGroup from '../AdGroup/CreateAddGroup';
import CreateAds from '../Ads/CreateAds';
import "../../styles/common/createCampaignForm.css"
import CreateAddCampaign from "./NewCampaignSecondScreen";
// import AdsForm from './AdsForm';
const Forms = ({ current,campaign ,setCurrent,campaignId,ad_group_id,setGroupId,data,setCampaignId,setAdGroupValues,setAdsValues,setCampaignSettingValues,setNewCampaignValues,toSave,setToSave}) => {
  return (
    <div>
      {current === 0 && <CampaignForm campaignForm={campaign} data={data} setNewCampaignValues={setNewCampaignValues} current={current} setCurrent={setCurrent} toSave={toSave} />}
      {current===1 && <CreateAddCampaign previousData={data} setCurrent={setCurrent} setCampaignId={setCampaignId} toSave={toSave} current={current} setToSave={setToSave} />}
      {current === 2 && <CreateAdGroup campaignId={campaignId} setGroupId={setGroupId} setCurrent={setCurrent} toSave={toSave} setToSave={setToSave}  current={current}/>}
      {current === 3 && <CreateAds setCurrent={setCurrent} campaignId={campaignId} groupId={ad_group_id} toSave={toSave} setToSave={setToSave}  current={current}/>}
    </div>
  );
}

export default Forms;