// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-types {
  display: flex;
  gap: 10px;
  border: none;
  border-radius: 5px;
  background: #e9daff;
  padding: 6px 12px;
  cursor: pointer;
}

.selected-asset-types {
    border: 1px solid #8833ff;
}`, "",{"version":3,"sources":["webpack://./src/styles/AssetLibrary/AssetTypes.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".asset-types {\n  display: flex;\n  gap: 10px;\n  border: none;\n  border-radius: 5px;\n  background: #e9daff;\n  padding: 6px 12px;\n  cursor: pointer;\n}\n\n.selected-asset-types {\n    border: 1px solid #8833ff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
