import {combineReducers} from "@reduxjs/toolkit";
import UserSlice from "../slices/userSlice";
import LinkedAccountSlice from "../slices/linkedAccountSlice";
import CampaignSlice from "../slices/CampaignSlice";
import AdsSlice from "../slices/AdsSlice";
import KeywordSlice from "../slices/KeywordSlice";
import BudgetSlice from "../slices/BudgetSlice";
import InsightsSlice from "../slices/InsightsSlice";


const reducer=combineReducers({
    user:UserSlice,
    linkedAccounts:LinkedAccountSlice,
    campaigns:CampaignSlice,
    ads:AdsSlice,
    keywords:KeywordSlice,
    budgets:BudgetSlice,
    insights:InsightsSlice
});


export default reducer;