import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import React from "react";
import Login from "./Components/Login/Login";
import PageNotFoundPage from "./Components/404/404";
import HomePage from "./Components/Home/Home";
import CampaignPage from "./Components/Campaign/Campaign";
import Wrapper from "./layout";
import NewCampaign from "./Components/Campaign/NewCampaign";
import ProtectedRoute from "./common/ProtectedRoute";
import {AuthProvider} from "./Context/AuthContext";
import CreateAddGroup from "./Components/AdGroup/CreateAddGroup";
import CreateBudget from "./Components/Budget/CreateBudget";
import ListBudget from "./Components/Budget/ListBudget";
import NoGoogleAdsAccountPage from "./Components/NoAdsAccount/NoAdsAccount";
import InsightDashboard from "./Components/Insights/InsightDashboard";
// import './styles/styles.scss';
import AdGroupList from "./Components/AdGroup/AdGroupList";
import CreateAddScreen from "./Components/Ads/CreateAds";
import ListAds from "./Components/Ads/ListAds";
import Keywords from "./Components/Keyword/Keywords";
import CreateKeywords from "./Components/Keyword/CreateKeywords";
import AccountPage from "./Components/Accounts/Account";
import Navbar from "./Components/Navbar/Navbar";
import AssetLibrary from './Components/AssetLibrary/AssetLibrary';
function App() {
  return (
    <Router>
        <AuthProvider>
        <Navbar>
       <Routes>
           <Route path={'/login'} element={<Login/>} />
            <Route path={'/'} element={<ProtectedRoute element={HomePage}/>}/>
           <Route path={'/campaign'} element={<ProtectedRoute element={CampaignPage}/>}/>
           <Route path={'/campaign/add'} element={<ProtectedRoute element={NewCampaign}/>}/>
           <Route path={'/adgroup/list'} element={<ProtectedRoute element={AdGroupList}/>}/>
           <Route path={'/adgroup/create'} element={<ProtectedRoute element={CreateAddGroup}/>}/>
           <Route path={'/ads/create'} element={<ProtectedRoute element={CreateAddScreen}/>}/>
           <Route path={'/ads/list'} element={<ProtectedRoute element={ListAds}/>}/>
           <Route path={'/keyword/list'} element={<ProtectedRoute element={Keywords}/>}/>
           <Route path={'/keyword/create'} element={<ProtectedRoute element={CreateKeywords}/>}/>
           <Route path={'/budget/create'} element={<ProtectedRoute element={CreateBudget}/>}/>
           <Route path={'/budget/list'} element={<ProtectedRoute element={ListBudget}/>}/>
           <Route path={'/account'} element={<ProtectedRoute element={AccountPage}/>}/>
           <Route path={'/noaccount'} element={<ProtectedRoute element={NoGoogleAdsAccountPage}/>}/>
              <Route path={'/insights/dashboard'} element={<ProtectedRoute element={InsightDashboard}/>}/>
            <Route path={'/assetlibrary'} element={<ProtectedRoute element={AssetLibrary}/>}/>
           <Route path={'*'} element={<PageNotFoundPage/>}/>
       </Routes>
        </Navbar>
        </AuthProvider>
    </Router>
  );
}

export default App;
