export const CREATE_BUDGET='campaign/createBudget';
export const LIST_BUDGET='campaign/listBudget';

export const CREATE_BUDGET_FULFILLED='campaign/createBudget/fulfilled';
export const LIST_BUDGET_FULFILLED='campaign/listBudget/fulfilled';

export const CREATE_BUDGET_PENDING='campaign/createBudget/pending';
export const LIST_BUDGET_PENDING='campaign/listBudget/pending';

export const CREATE_BUDGET_REJECTED='campaign/createBudget/rejected';
export const LIST_BUDGET_REJECTED='campaign/listBudget/rejected';