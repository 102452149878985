import React, {useContext, useEffect, useState} from "react";
import {Form, Spin, notification} from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import ReusableAddForm from "../../common/ReusableAddForm";
import {RESPONSE_SERVICES} from "../../common/Services";
import {useDispatch, useSelector} from "react-redux";
import {createBudget} from "../../redux/thunk/BudgetThunk";
import { CREATE_BUDGET_FULFILLED, CREATE_BUDGET_REJECTED } from "../../redux/types/BudgetActionTypes";
import SelectInput from "../../common/SelectBoxes/SelectInput";
import TextInput from "../../common/TextInput";
import { convertToMacros } from "../../common/UtilityFunctions";
import {NavBarContext} from "../Navbar/Navbar";
import {Functions} from "../../common/functions";

const { Item } = Form;
const CreateBudget = () => {
    const [loading, setLoading] = useState(false);
    const [customerId, setCustomerId] = useState([]);
    const [budgetLoading,setBudgetLoading]= useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {getPath}=useContext(NavBarContext)
    const {linkedAccount}=useSelector((state) => state.linkedAccounts);

    const validationSchema = Yup.object().shape({
        customer_id: Yup.string().required("Customer ID is required"),
        delivery_method: Yup.string().required("Delivery Method is required"),
        name: Yup.string().required("Budget Name is required"),
        amount_micros: Yup.number()
            .required(" Budget is required")
            .positive(" Budget must be positive")
            .integer(" Budget must be an integer").min(1,"Budget must greater than 0").max(100000000,"Budget must be less than 100000000"),
    });

    /**
     * Saving linked Accounts inside state
     */
    useEffect(() => {
            if(linkedAccount){
                let temp=[];
                linkedAccount.map(val => {
                    temp.push(val.toString().split("/")[1]);
                })
                setCustomerId(temp);
            }else {
                setCustomerId([]);
            }
    }, [linkedAccount]);

    /**
     * Fetch Linked Accounts
     */
    useEffect(() => {
        Functions.getLinkedAccount(setLoading,null,navigate,dispatch)
    }, []);

    /**
     * handle Submit for Create Budget
     */
    const handleSubmit = async (values,{resetForm}) => {
        setBudgetLoading(true);
        let data = {...values, "amount_micros": convertToMacros(values.amount_micros)};
        try{
            const res=await dispatch(createBudget(data));
            if(res?.type===CREATE_BUDGET_FULFILLED) {
                notification.success({message: "Successfully created Budget"});
                navigate(getPath('/budget/list'));
            }
            if(res?.type===CREATE_BUDGET_REJECTED) {
                RESPONSE_SERVICES.errorService(res.error);
            }
            setBudgetLoading(false);
        } catch (e) {
            setBudgetLoading(false);
            RESPONSE_SERVICES.errorService(e);

        }
    };

    const deliveryOptions = [
        {value: "", label: "Select..."},
        {value: "STANDARD", label: "Standard"},
        {value: "ACCELERATED", label: "Accelerated"}
    ]

    return (
        <div  style={{ marginTop: "40px"}}>
            <ReusableAddForm loading={loading}>
                    <Formik
                        initialValues={{
                            customer_id: "",
                            delivery_method: "",
                            name: "",
                            amount_micros: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, handleChange, handleSubmit, errors, touched }) => (
                            <Form layout="vertical" onFinish={handleSubmit}
                            style={{
                                padding: '20px',
                                display: 'grid',
                                gap: '20px'
                             }}  
                                                     
                            >
                            <div className="form-main">
                            <div>
                                <div className="form-heading-logo">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                    d="M4 6H20M7 12H20M10 18H20"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    />
                                </svg>
                                <h2 className="campaign_name">Select Method</h2>
                                </div>
                                <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                    gap: "32px",
                                }}
                                >
                                <div>
                                    <p><span className='error-asterisk-sign'>*</span> Customer ID</p>
                                    <SelectInput
                                        value={values.customer_id}
                                        handleChange={handleChange}
                                        name="customer_id"
                                        options={customerId?.map((value) => {return {label: value, value: value}})}
                                        help={errors.customer_id && touched.customer_id ? errors.customer_id : ""}
                                        validateStatus={errors.customer_id && touched.customer_id ? "error" : ""}
                                    />
                                </div>
                                <div>
                                    <p><span className='error-asterisk-sign'>*</span> Delivery Method</p>
                                    <SelectInput
                                        value={values.delivery_method}
                                        handleChange={handleChange}
                                        name="delivery_method"
                                        options={deliveryOptions}
                                        help={errors.delivery_method && touched.delivery_method  ? errors.delivery_method : ""}
                                        validateStatus={errors.delivery_method && touched.delivery_method ? "error" : ""}
                                    />
                                </div>
                                </div>
                            <div >
                            </div>
                            </div>
                            </div>
                                 
                            <div className="form-main">
                                <div className="form-heading-logo">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                    d="M4 6H20M7 12H20M10 18H20"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    />
                                </svg>
                                <h2 className="campaign_name">Budget Details</h2>
                                </div>
                                <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr",
                                    // gap: "32px",
                                }}
                                >

                            
                                <div>
                                    <p><span className='error-asterisk-sign'>*</span> Budget Name</p>
                                    <TextInput
                                        value={values.name}
                                        handleChange={handleChange}
                                        name='name'
                                        validateStatus={errors.name && touched.name ? "error" : ""}
                                        help={errors.name && touched.name ? errors.name : ""}
                                    />
                                </div>
                                <div>
                                    <p><span className='error-asterisk-sign'>*</span> Ad Budget</p>
                                    <TextInput
                                        value={values.amount_micros}
                                        handleChange={handleChange}
                                        name='amount_micros'
                                        validateStatus={errors.amount_micros && touched.amount_micros ? "error" : ""}
                                        help={errors.amount_micros && touched.amount_micros ? errors.amount_micros : ""}
                                        type="number"
                                    />
                                </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', gap: '30px', justifyContent: 'flex-end'}}> 
                                <Item>
                                    <button className="next-button loading-enabled-next-button" type="submit">
                                        {
                                            budgetLoading ? <Spin /> : "Create"
                                        }
                                    </button>
                                </Item>
                            </div>
                            </Form>
                        )}
                    </Formik>
            </ReusableAddForm>
        </div>    
    );
};

export default CreateBudget;
