import {createAsyncThunk} from "@reduxjs/toolkit";
import {InshightsActionTypes} from "../actions/InshightsAction";
import { GET_CAMPAIGN_INSIGHTS } from "../types/InsightsActionTypes";

export const getCampaignInsights=createAsyncThunk(GET_CAMPAIGN_INSIGHTS,async (body)=>{
    try {
        const res= await InshightsActionTypes.getCampaignInsights(body);
        return res?.data;
    } catch (e) {
        throw  e?.response?.data?.error[0] || e;
    }
});
