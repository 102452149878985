import React, { useState } from "react";
import Listing from "../../common/Listing";
import {useDispatch, useSelector} from "react-redux";
import {Functions} from "../../common/functions";


const CampaignPage = () => {
    const [loading, setLoading] = useState(false);
    const [contentLoading,setContentLoading] = useState(true);
    const dispatch = useDispatch();
    const {campaigns}=useSelector((state)=>state.campaigns);

    const fetchCampaigns=(selectedAccount)=>Functions.fetchCampaignDetails(selectedAccount,setContentLoading,dispatch);

    return (
        <Listing
            contentLoading={contentLoading}
                      loading={loading} data={campaigns}
                      setLoading={setLoading}
                      fetchDetails={fetchCampaigns}
                      campaignPage={true}
        />
    );
};

export default CampaignPage;
