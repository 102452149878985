import {createSlice} from "@reduxjs/toolkit";
import {handleAsyncAction} from "../common/AsyncCases";
import {getKeywordList} from "../thunk/KeywordThunk";

const KeywordSlice = createSlice({
    name: "KeywordSlice",
    initialState: {
        keywords:[],
        isLoading: false,
        error: null
    },
    reducers:{},
    extraReducers:(builder)=>{
        handleAsyncAction(builder,getKeywordList,(state, action) => {
           state.keywords=action.payload;
        })
    }
})

export default KeywordSlice.reducer;