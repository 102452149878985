import TextInput from "../../../common/TextInput";
import SelectInput from "../../../common/SelectBoxes/SelectInput";
import CampaignSelect from "../../../common/SelectBoxes/CampaignSelect";
import { Form, Input } from "antd";
const AdsPath = ({
  formik,
  handleChange,
  handleBlur,
}) => {
  return (
    <div className="form-main">
      <div>
        <div className="form-heading-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 6H20M7 12H20M10 18H20"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h2 className="campaign_name">Add Path</h2>
        </div>
        <div
          style={{
            display: "grid",
          }}
        >
          <div>
            <p><span className='error-asterisk-sign'>*</span> Path 1</p>
            <TextInput
              value={formik.values.path1}
              handleChange={handleChange}
              name="path1"
              handleBlur={handleBlur}
              help={formik?.touched?.path1 && formik?.errors?.path1}
              validateStatus={formik?.touched?.path1 && formik?.errors?.path1 ? "error" : ""}
            />
          </div>
          <div>
          <p><span className='error-asterisk-sign'>*</span> Path 2</p>
            <TextInput
              value={formik?.values?.path2}
              handleChange={handleChange}
              name="path2"
              handleBlur={handleBlur}
              help={formik?.touched?.path2 && formik?.errors?.path2}
              validateStatus={formik?.touched?.path2 && formik?.errors?.path2 ? "error" : ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsPath;
