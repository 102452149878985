// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .ant-modal-content {
    height: 440px; /* Adjust the height as needed */
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/NavbarModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,gCAAgC;EACjD","sourcesContent":[".custom-modal .ant-modal-content {\n    height: 440px; /* Adjust the height as needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
