// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.objective-heading {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    padding-top: 150px;
}

.objective-detail {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}`, "",{"version":3,"sources":["webpack://./src/styles/campaignForm/CampaignObjective.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".objective-heading {\n    color: #000;\n    font-size: 15px;\n    font-style: normal;\n    font-weight: 700;\n    padding-top: 150px;\n}\n\n.objective-detail {\n    color: #000;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 150%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
