import {createAsyncThunk} from "@reduxjs/toolkit";
import {KeywordActionTypes} from "../actions/KeywordAction";
import { KEYWORD_LIST,CREATE_KEYWORD } from "../types/CampaignActionTypes";


export const getKeywordList=createAsyncThunk(KEYWORD_LIST,async ({customer_id})=>{
    try{
        const res=await KeywordActionTypes.getKeywordList({customer_id});
        return res?.data;
    } catch (e){
        throw  e?.response?.data?.error[0] || e;
    }
});



export const createKeyword = createAsyncThunk(CREATE_KEYWORD,async (body)=>{
    try{
        const res=await KeywordActionTypes.createKeyword(body);
        return res?.data;
    } catch (e){
        throw  e?.response?.data?.error[0] || e;
    }
})