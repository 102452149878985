import { Input } from 'antd';
import React from 'react';

const UploadImageUrl = ({setImageUrl,imageUrl,handleOk,handleCancel}) => {
  return (
    <div>
        <div>
                <h3>Upload Photo via URL</h3>
                <Input onChange={(e) => setImageUrl(e.target.value)} value={imageUrl} />
                <div style={{display: 'flex', gap: '10px', justifyContent: "flex-end", marginTop: "50px"}}>
          <button className="back-button" onClick={ handleCancel}>
            Cancel
          </button>
          <button className="next-button" onClick={handleOk}>
            Upload
          </button>
        </div>
            </div>
    </div>
  );
}

export default UploadImageUrl;