import React, { useEffect, useState} from 'react';
import {Button, Card, notification, Spin} from 'antd';
import { FacebookOutlined ,GoogleOutlined} from '@ant-design/icons';
import {LoginSocialFacebook, LoginSocialGoogle} from 'reactjs-social-login';
import {useNavigate} from "react-router-dom";
import {RESPONSE_SERVICES} from "../../common/Services";
import {useDispatch} from "react-redux";
import {loginUser} from "../../redux/thunk/userThunk";
import { LOGIN_FULFILLED, LOGIN_REJECTED } from '../../redux/types/userActionTypes';

const clientId =process.env.REACT_APP_GOOGLE_CLIENT_ID;


const LoginPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const onLogin=async ({ provider, data }) => {
      try{
          setLoading(true);
          const code=data?.code;
            const res=await dispatch(loginUser({code}));
            if(res?.type===LOGIN_FULFILLED){
                notification.success({message:"Login successful"});
                localStorage.setItem("login",JSON.stringify(res?.payload?.user));
                navigate('/campaign');
            }
            if(res?.type===LOGIN_REJECTED) {
                RESPONSE_SERVICES.errorService(res.error);
            }


      } catch (e){
          setLoading(false);
          RESPONSE_SERVICES.errorService(e);

      }
    }
        useEffect(()=>{
            if(localStorage.getItem("login")){
                navigate('/campaign');
            }

        },[])
    return (

        <Spin spinning={loading} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <Card style={{width: 300}}>
                    <h2 style={{textAlign: 'center'}}>Login</h2>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        minHeight: '10rem'
                    }}>
                        <LoginSocialGoogle
                            client_id={clientId}
                            onLoginStart={() => console.log('Login start')}
                            redirect_uri={process.env.REACT_APP_REDIRECT_URI || 'https://hackerthon-ten.vercel.app/'}
                            scope={process.env.REACT_APP_SCOPES_GOOGLE}
                            onResolve={onLogin}
                            access_type="offline"
                            onReject={err => {
                            }}
                        >
                            <Button
                                className={'primary-btn'}
                                type="primary"
                                icon={<GoogleOutlined/>}
                                style={{width: '100%'}}
                            >
                                Login with Google
                            </Button>
                        </LoginSocialGoogle>

                        {/*<LoginSocialFacebook*/}

                        {/*    appId={process.env.REACT_APP_FB_APP_ID }*/}

                        {/*    onLoginStart={() => console.log('Login start')}*/}
                        {/*    onResolve={() => console.log("facebook Login")}*/}
                        {/*    onReject={err => {*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Button*/}
                        {/*        className={'primary-btn'}*/}
                        {/*        type="primary"*/}
                        {/*        icon={<FacebookOutlined/>}*/}
                        {/*        style={{width: '100%'}}*/}
                        {/*    >*/}
                        {/*        Login with Facebook*/}
                        {/*    </Button>*/}
                        {/*</LoginSocialFacebook>*/}

                    </div>
                    {/*<LoginSocialFacebook*/}
                    {/*    appId="YOUR_FACEBOOK_APP_ID"*/}
                    {/*    onSuccess={handleFacebookLogin}*/}
                    {/*    onFailure={(error) => console.error('Facebook login failed:', error)}*/}
                    {/*    style={{ width: '100%' }}*/}
                    {/* onReject={(v)=>console.log(v)}*/}
                    {/*    onResolve={}/>*/}
                </Card>
            </div>
        </Spin>
    );
};

// const LoginPage = Wrapper(Login);

export default LoginPage;
