import React from 'react';
import { Form, Select,Tooltip } from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons'
const { Item } = Form;
const { Option } = Select;

const SelectInput = ({ value, handleChange, label, name, validateStatus, help ,options, getCustomerId,setInput, handleDataChange,disabledOptions=null, modalInputFields=false}) => {
    const modalFilterOptions = (input, option) => {
        const searchValue = Array.isArray(option?.children) ? option?.children[0] : option?.children;
        return searchValue?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return (
        <Item
            label={label}
            hasFeedback
            validateStatus={validateStatus}
            help={help}
            required
        >
            <Select className={'single_select'} value={value?.toString().replace(/_/g, ' ')}
            name={name}
                    showSearch
            style={{height:"45px",marginTop:"8px"}}
            {...(modalInputFields && { filterOption: modalFilterOptions})}
                onChange={(value, record) => {
                   if(setInput) {
                       setInput(value);
                   }
                    if(getCustomerId) {
                        getCustomerId(value);
                    }
                    if(handleDataChange) {
                        handleDataChange(value, record);
                    }
                    else if(handleChange) {
                        handleChange(name)(value);
                    }
                }}>
                {
                    options.map((item, index) => {
                        const value = typeof item === 'object' ? item.value || '' : item;
                        const isDisabled = disabledOptions && disabledOptions.hasOwnProperty(value) && (disabledOptions[value]?.manager || disabledOptions[value]?.error) || false;
                        const label = typeof item === 'object' ? item.label || '' : item;

                        return <>
                            {(disabledOptions && isDisabled) ? (
                                <Option
                                    key={index}
                                    value={value}
                                    disabled={isDisabled}
                                >
                                    {label.toString().replace(/_/g, ' ')}
                                    {disabledOptions[value] && (
                                        <Tooltip placement="right" title={disabledOptions[value].manager ? 'This is manager account' : disabledOptions[value].error ? disabledOptions[value].error : 'Something went wrong!!'} color="#FF4D4F">
                                            <span style={{ marginLeft: '10px', cursor: 'not-allowed' }}><InfoCircleOutlined /></span>
                                        </Tooltip>
                                    )}
                                </Option>
                            ) : (
                                <Option
                                    key={index}
                                    value={value}
                                >
                                    {label.toString().replace(/_/g, ' ')}
                                </Option>
                            )}                      
                        </>
                    })
                }
            </Select>
            
        </Item>
    );
}

export default SelectInput;