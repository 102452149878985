import React, { useState } from "react";
import { Select,notification} from "antd";
import Listing from "../../common/Listing";
import {RESPONSE_SERVICES} from "../../common/Services";
import {useDispatch} from "react-redux";
import {listBudget} from "../../redux/thunk/BudgetThunk";
import { LIST_BUDGET_FULFILLED, LIST_BUDGET_REJECTED } from "../../redux/types/BudgetActionTypes";
import {Functions} from "../../common/functions";

const { Option } = Select;

const ListBudget = () => {
    const [budgetData, setBudgetData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [contentLoading,setContentLoading] = useState(true);
    const dispatch = useDispatch();

    const fetchCampaignDetails = async (selectedAccount) => Functions.getBudgetList(setContentLoading,setBudgetData,selectedAccount,dispatch);

    return (
        <Listing budgetPage={true} campaignPage={false} loading={loading} setLoading={setLoading} fetchDetails={fetchCampaignDetails} data={budgetData} contentLoading={contentLoading}/>
    );
};

export default ListBudget;
