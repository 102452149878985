export const GET_CAMPAIGN_INFO = 'account/campaignInfo';
export const CREATE_CAMPAIGN='account/createCampaign';
export const CREATE_AD_GROUP='account/createAdGroup';
export const LIST_AD_GROUP='account/listAdGroup';
export const KEYWORD_LIST = 'account/keywordList';

export const GET_CAMPAIGN_INFO_FULFILLED = 'account/campaignInfo/fulfilled';
export const CREATE_CAMPAIGN_FULFILLED='account/createCampaign/fulfilled';
export const CREATE_AD_GROUP_FULFILLED='account/createAdGroup/fulfilled';
export const LIST_AD_GROUP_FULFILLED='account/listAdGroup/fulfilled';
export const KEYWORD_LIST_FULFILLED = 'account/keywordList/fulfilled';

export const GET_CAMPAIGN_INFO_PENDING = 'account/campaignInfo/pending';
export const CREATE_CAMPAIGN_PENDING='account/createCampaign/pending';
export const CREATE_AD_GROUP_PENDING='account/createAdGroup/pending';
export const LIST_AD_GROUP_PENDING='account/listAdGroup/pending';
export const KEYWORD_LIST_PENDING = 'account/keywordList/pending';


export const GET_CAMPAIGN_INFO_REJECTED = 'account/campaignInfo/rejected';
export const CREATE_CAMPAIGN_REJECTED='account/createCampaign/rejected';
export const CREATE_AD_GROUP_REJECTED='account/createAdGroup/rejected';
export const LIST_AD_GROUP_REJECTED='account/listAdGroup/rejected';
export const KEYWORD_LIST_REJECTED = 'account/keywordList/rejected';

export const CREATE_KEYWORD = 'account/createKeyword';
export const CREATE_KEYWORD_FULFILLED = 'account/createKeyword/fulfilled';
export const CREATE_KEYWORD_REJECTED = 'account/createKeyword/rejected';
export const CREATE_LIST_AD_INFO = 'account/keywordList/fulfilled'
