// enum CampaignStatus {
//     UNSPECIFIED = 0;
// UNKNOWN = 1;
// ENABLED = 2;
// PAUSED = 3;
// REMOVED = 4;
// }

export const Status = ["UNSPECIFIED", "UNKNOWN", "ENABLED", "PAUSED", "REMOVED"]

export const advertising_channel = ["UNSPECIFIED", "UNKNOWN", "SEARCH", "DISPLAY", "SHOPPING", "HOTEL", "VIDEO", "MULTI_CHANNEL", "LOCAL", "SMART", "PERFORMANCE_MAX", "LOCAL_SERVICES", "DISCOVERY", "TRAVEL"]

export const delivery_method = ["UNSPECIFIED","UNKNOWN","STANDARD","ACCELERATED"]