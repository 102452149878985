import React ,{useState} from 'react';
import { Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { Functions } from '../../../common/functions';
import AssetListingGrid from '../../AssetLibrary/AssetListingGrid';
import UploadImage from './UploadImage';
import UploadImageUrl from './UploadImageUrl';

const UploadAssetImage = ({setShowImageModal,setIsUpload,squareMarketingImage,marketingImage,logoImage,type}) => {
    const [fileList, setFileList] = useState([]);
    const dispatch = useDispatch();
    const [imageUrl, setImageUrl] = useState('');
    const handleImageOk = () => {
        const asset_type = "IMAGE_URL"
        Functions.handleAssetSubmit(imageUrl, dispatch, notification, setShowImageModal, setImageUrl, asset_type, setIsUpload);
    };
    const handleImageCancel = () => {
        setShowImageModal(false);
        setImageUrl('');
    };
    const UploadItems = [
        {
            key: '1',
            label: 'Upload Image via URL',
            children:<UploadImageUrl setImageUrl={setImageUrl} imageUrl={imageUrl} handleOk={handleImageOk} handleCancel={handleImageCancel} />
        },
        {
            key: '2',
            label: 'Upload Image from your Device',
            children: <UploadImage setImageUrl={setImageUrl} imageUrl={imageUrl} setIsUpload={setIsUpload} fileList={fileList} setFileList={setFileList} handleOk={handleImageOk} handleCancel={handleImageCancel} squareMarketingImage={squareMarketingImage} marketingImage={marketingImage} isLogo={false}/>
        }
    ]

    const LogoUploadItems = [
        {
            key: '1',
            label: 'Upload Image via URL',
            children:<UploadImageUrl setImageUrl={setImageUrl} imageUrl={imageUrl} handleOk={handleImageOk} handleCancel={handleImageCancel} />
        },
        {
            key: '2',
            label: 'Upload Image from your Device',
            children: <UploadImage setImageUrl={setImageUrl} imageUrl={imageUrl} setIsUpload={setIsUpload} fileList={fileList} setFileList={setFileList} handleOk={handleImageOk} handleCancel={handleImageCancel} logoImage={logoImage} isLogo={true}/>
        }
    ]
    return (
        <div>
                <Tabs defaultActiveKey="1" type="card"
                items={type === "image"?UploadItems:LogoUploadItems} 
                />
        </div>
    );
}

export default UploadAssetImage;