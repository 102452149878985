import React, { useState } from 'react';
import { Input } from 'antd';

const UploadVideo = ({ handleOk, handleCancel }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [error, setError] = useState("");
  const regex = `^((http|https)\:\/\/)?(www\.youtube\.com|youtu\.?be)\/((watch\?v=)?([a-zA-Z0-9]{11}))(&.*)*$ `

  const extractVideoId = () => {
    const match = videoUrl.match(regex);

    if (match && match[1]) {
      handleOk();
    } else {
      setError("Invalid YouTube URL!");
    }
  };

  return (
    <div>
      <h3>Upload Video</h3>
      <Input
        onChange={(e)=> {
          setVideoUrl(e.target.value);
          setError('');
        }} 
        value={videoUrl}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div style={{ display: 'flex', gap: '10px', justifyContent: "flex-end", marginTop: "50px" }}>
        <button className="back-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="next-button" onClick={extractVideoId}>
          Upload
        </button>
      </div>
    </div>
  );
}

export default UploadVideo;