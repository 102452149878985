// CreateAddScreen.js

import React, {useContext, useEffect, useState} from 'react';
import { Form, Typography, Spin, Modal } from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { createAdGroup, getCampaignInfo, listAdGroup } from "../../redux/thunk/CampaignThunk";
import { getLinkedAccount } from "../../redux/thunk/linkedAccountThunk";
import { useNavigate } from "react-router-dom";
import { RESPONSE_SERVICES, RESPONSE_SERVICES as Respose_SERVICES } from "../../common/Services";
import { createAds, createAdsDisplay } from "../../redux/thunk/AdsThunk";
import AdsDetails from './AdsForm/AdsDetails';
import AdsHeadlines from './AdsForm/AdsHeadlines';
import AdsDescription from './AdsForm/AdsDescription';
import AdsPath from './AdsForm/AdsPath';
import "../../styles/AdsForm/AdsForm.css"
import {NavBarContext} from "../Navbar/Navbar";
import {Functions} from "../../common/functions";
import {LocalStorage} from "../../utils/localStorage";
import BackandSubmit from "../../common/BackandSubmit";
import Assets from './Asset/Assets';
import { CREATE_ADS_DISPLAY_FULFILLED, CREATE_ADS_DISPLAY_REJECTED } from '../../redux/types/AdsActionTypes';
import { CampaignTypes } from '../../common/enums';
import Card from '../../common/Card';

const { Title } = Typography;

const CreateAddScreen = ({setCurrent,campaignId,groupId,toSave,setToSave,current}) => {
    const [numHeadlines, setNumHeadlines] = useState(3); // Initial number of headlines
    const [numDescriptions, setNumDescriptions] = useState(2); // Initial number of descriptions
    const maxHeadlines = 15; // Max number of headlines
    const maxDescriptions = 4; // Max number of descriptions
    const [campaignIds, setCampaignIds] = useState([]);
    const [selectedCampaignId, setSelectedCampaignId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [adGroupList, setAdGroupList] = useState([]);
    const [customerIds, setCustomerIds] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const [selectedAdId, setSelectedAdId] = useState(null);
    const searchParams = new URLSearchParams(window.location.search);
    const idParam = searchParams.get('campaign_id');
    const [modal, setModal] = useState(false);
    const [adType, setAdType] = useState("SEARCH");
    const {getPath}=useContext(NavBarContext)
    const {linkedAccount}=useSelector((state) => state.linkedAccounts);
    const {campaigns}=useSelector((state) => state.campaigns);
    let prevData=localStorage.getItem('campaignInfo');
    if(prevData){
        prevData=JSON.parse(prevData);
    }
    let campId=campaignId;
    if(!campaignId && setCurrent){
        campId=localStorage.getItem("campaignId");
    }
    let gId=groupId;
    if(!groupId){
        gId=localStorage.getItem("groupId");
    }

    /**
     * Set linkedAccounts
     */
    useEffect(()=>{
        let temp=[];
        linkedAccount?.map(val=>{
            temp.push(val.toString().split("/")[1]);
        });
        setCustomerIds(temp);
    },[linkedAccount]);

    /**
     * Set CampaignIds
     */
    useEffect(() => {
        setCampaignIds(campaigns);
    }, [campaigns]);

    const getCustomerIds = async () => Functions.getLinkedAccount(setLoading,setSelectedCustomerId,navigate,dispatch)

    const getCampaignIds = async () => Functions.fetchCampaignDetails(selectedCustomerId,setLoading,dispatch);

    const getAdGroupList = async () =>Functions.getAdGroupList(setLoading,selectedCampaignId,selectedCustomerId,setAdGroupList,dispatch);

    /**
     * Navigate to next page and clear localStorage
     */
    const handleClearLocalStorage = () => {
        if(current === 3) {
            LocalStorage.clearCampaignData();
            navigate(getPath('/campaign'));
        }
        else {
            navigate(getPath('/ads/list'));
        }
        setModal(0);
    }

    /**
     * Handle Create Ads
       */
    const handleSearchSubmit = async (values) => {
        try {
            setSubmitLoading(true);
            const body = {
                "campaign_id": selectedCampaignId?.toString() || campId,
                "customer_id": selectedCustomerId?.toString() || idParam,
                "ad_group_id": selectedAdId?.toString() || gId,
                'name': values.name,
                'final_urls': values.final_urls,
                'headlines': values.headlines,
                'descriptions': values.descriptions,
                'path1': values.path1,
                'path2': values.path2,
                
            }
            const res = await dispatch(createAds(body));
            if (res?.type === 'account/createAds/fulfilled') {
                if(!setCurrent){
                    navigate(getPath('/ads/list'));
                    return;
                }



                setModal(true);
                setSubmitLoading(false);
            }
            if (res?.type === 'account/createAds/rejected') {
                if(res.error.message === 'Network Error') {
                    Respose_SERVICES.errorService({'message': 'Error in Creating Ads!'});
                }
                else Respose_SERVICES.errorService(res?.error);
            }
            setSubmitLoading(false)
        } catch (e) {
            setSubmitLoading(false);
            RESPONSE_SERVICES.errorService(e);
        }
    }

    const handleDisplaySubmit = async (values) => {
        try {
            setSubmitLoading(true);
            const body = {
                ...values, "campaign_id": selectedCampaignId?.toString() || campId,
                "customer_id": selectedCustomerId?.toString() || idParam,
                "ad_group_id": selectedAdId?.toString() || gId
            }
            const res = await dispatch(createAdsDisplay(body));
            if (res?.type === CREATE_ADS_DISPLAY_FULFILLED) {
                if(!setCurrent){
                    navigate(getPath('/ads/list'));
                    return;
                }
                setModal(true);
                setSubmitLoading(false);
            }
            if (res?.type === CREATE_ADS_DISPLAY_REJECTED) {
                if(res.error.message === 'Network Error') {
                    Respose_SERVICES.errorService({'message': 'Error in Creating Ads!'});
                }
                else Respose_SERVICES.errorService(res?.error);
            }
            setSubmitLoading(false)
        } catch (e) {
            setSubmitLoading(false);
            RESPONSE_SERVICES.errorService(e);
        }
    }

    /**
     * Fetch Linked account
     */
    useEffect(() => {
        if(!campId) getCustomerIds();
    }, [user]);

    /**
     * Fetch either campaigns or adGroups
     */
    useEffect(() => {
     if(!campId){

         if (selectedCampaignId && selectedCustomerId) {
             getAdGroupList();
         } else if (selectedCustomerId) {
             getCampaignIds();
         }
     }

    }, [selectedCustomerId, selectedCampaignId]);

    let prevValues=localStorage.getItem("createAds");
    if(prevValues){
        prevValues=JSON.parse(prevValues);
    }

    const initialValue= {
        ad_group_id: gId,
        customer_id: idParam,
        campaign_id: campId,
        name: prevData && setCurrent? `${prevData?.campaign_name} Ad 1`:"",
        final_urls: ['https://example.com'],
        headlines: Array.from({ length: numHeadlines }, () => ''),
        descriptions: Array.from({ length: numDescriptions }, () => ''),
        path1: '',
        path2: '',
        square_marketing_images: [],
        marketing_images: [],
        youtube_videos_list: [],
        logos_list: [],
        long_headline_text: "demo",
        business_name: "demo",
        call_to_action_text: ""
    }
    const formik = useFormik({
        // enableReinitialize:'true',
        initialValues:setCurrent?prevValues || initialValue : initialValue,
        validationSchema: Yup.object({
            ad_group_id: Yup.string().required('Ad group Id is Required'),
            customer_id: Yup.string().required('Customer Id is Required'),
            campaign_id: Yup.string().required('Campaign Name is Required'),
            name: Yup.string().required('Name is Required'),
            final_urls: Yup.array()
                .of(Yup.string().url('Invalid URL').matches(/^https:\/\//, 'URL must start with https://'))
                .required('At least one URL is required'),
            headlines: Yup.array()
                .of(Yup.string().required('Required'))
                .max(maxHeadlines, `Max ${maxHeadlines} headlines allowed`).min(numHeadlines,'Headlines required'),
            descriptions: Yup.array()
                .of(Yup.string().required('Required'))
                .max(maxDescriptions, `Max ${maxDescriptions} descriptions allowed`).min(numDescriptions,'Descriptions required'),
            path1: Yup.string().required('Path 1 is Required'),
            path2: Yup.string().required('Path 2 is Required'),
        }),
        onSubmit: (adType === 'DISPLAY') ? handleDisplaySubmit : handleSearchSubmit
    });

    const squareMarketingImage = (id) => {
        formik.setFieldValue('square_marketing_images', id);
    }

    const marketingImage = (id) => {
        formik.setFieldValue('marketing_images', id);
    }

    const youtubeVideo = (id) => {
        formik.setFieldValue('marketing_images', id);
    }

    const logoImage = (id) => {
        formik.setFieldValue('logos_list', id);
    }

    const handleTypeChange = (value) => {
        setAdType(value);
    }

    const addHeadline = () => {
        if (numHeadlines < maxHeadlines) {
            setNumHeadlines(numHeadlines + 1);
            formik.setFieldValue(`headlines[${numHeadlines}]`, '');
        }
    };

    const removeHeadline = (index) => {
        if (numHeadlines > 1) {
            setNumHeadlines(numHeadlines - 1);
            const newHeadlines = [...formik.values.headlines];
            newHeadlines.splice(index, 1);
            formik.setFieldValue('headlines', newHeadlines);
        }
    };

    const addDescription = () => {
        if (numDescriptions < maxDescriptions) {
            setNumDescriptions(numDescriptions + 1);
            formik.setFieldValue(`descriptions[${numDescriptions}]`, '');
        }
    };

    const removeDescription = (index) => {
        if (numDescriptions > 1) {
            setNumDescriptions(numDescriptions - 1);
            const newDescriptions = [...formik.values.descriptions];
            newDescriptions.splice(index, 1);
            formik.setFieldValue('descriptions', newDescriptions);
        }
    };

    const handleCustomerIdChange = (value, record) => {
        formik.setFieldValue('customer_id', value);
        formik.setFieldValue('campaign_id', '');
        formik.setFieldValue('ad_group_id', '');
        setSelectedCustomerId(value);
        setSelectedCampaignId(null);
        setSelectedAdId(null);
    }

    const handleCampaignIdChange = (value, record) => {
        formik.setFieldValue('campaign_id', record?.children);
        formik.setFieldValue('ad_group_id', '');
        setSelectedCampaignId(value);
        setSelectedAdId(null);
    }

    const handleAdGroupListChange = (value, record) => {
        formik.setFieldValue('ad_group_id', record?.children);
        setSelectedAdId(value);
    }


    useEffect(()=>{
        if(current===3 && toSave!==current && setCurrent){
            localStorage.setItem('createAds',JSON.stringify(formik.values));
            setCurrent(toSave);
        }
    },[toSave])




    return (
        
        <div style={{ marginTop: "20px"}}>
        <Modal title="Your Campaign is created Successfully!" centered open={modal} height={200} onOk={handleClearLocalStorage} onCancel={() => setModal(0)} className='custom_modal'>
            <div style={{display: 'flex',  height: '90px', alignItems: 'center', justifyContent: 'center'}}>
               <CheckCircleOutlined style={{fontSize: '70px', color: '#009900'}}/>
            </div>
        </Modal>
        <Spin spinning={loading}>
                    <Form
                        layout="vertical"
                        onFinish={formik.handleSubmit}
                        initialValues={formik.initialValues}
                        validateTrigger="onSubmit"
                        style={{
                            padding: '20px',
                            display: 'grid',
                            gap: '20px'
                        }}
                    >
                        <AdsDetails
                           campaignId={campId}
                           customerIdOptions={customerIds?.map((option) => ({value: option, label: option}))}
                           handleCustomerIdChange={handleCustomerIdChange}
                           campaignIdOptions={campaignIds?.map((option) => ({value: option.id, label: option?.name}))}
                           handleCampaignIdChange={handleCampaignIdChange}
                           adGroupListOptions={adGroupList?.map((option) => ({value: option?.id, label: option?.name}))}
                           handleAdGroupListChange={handleAdGroupListChange}
                           formik={formik}
                        />

                        <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                            <div style={{ background: "#fff", padding: "32px", borderRadius: "8px" }}>
                                <div>
                                    <h2 className='campaign_name'><span className='error-asterisk-sign'>*</span> &nbsp; Select Ad Type</h2>              
                                    <div style={{ padding: "40px 0px" }}>
                                        <Card
                                            options={CampaignTypes}
                                            handleChange={handleTypeChange}
                                            type="card-type"
                                            value={adType}
                                        />

                                    </div>
                                </div>
                                {/* {validateStatus ? <span style={{ color: "red", fontSize: "12px"}}>{help}</span> : null} */}
                            </div>
                            {adType === 'DISPLAY' && <Assets
                                formik={formik}
                                setSubmitLoading={setSubmitLoading}
                                submitLoading={submitLoading}
                                squareMarketingImage={squareMarketingImage}
                                marketingImage={marketingImage}
                                logoImage={logoImage}
                            />}
                        </div>    

                        <AdsHeadlines 
                           formik={formik} 
                           numHeadlines={numHeadlines} 
                           maxHeadlines={maxHeadlines} 
                           addHeadline={addHeadline}
                           removeHeadline={removeHeadline}
                        />
                        <AdsDescription
                           formik={formik} 
                           numDescriptions={numDescriptions} 
                           maxDescriptions={maxDescriptions}
                           addDescription={addDescription}
                           removeDescription={removeDescription}
                        />
                        <AdsPath
                            formik={formik}
                           handleBlur={formik.handleBlur}
                           handleChange={formik.handleChange}
                        />
                        <div style={{display: 'flex', gap: '30px', justifyContent: 'flex-end'}}>
                           <BackandSubmit toShow={current===3} submitLoading={submitLoading} index={2} setToSave={setToSave}/>
                        </div>
                    </Form>
         </Spin>
        </div>
    );
};

export default CreateAddScreen;
