// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-image-heading{
    background: var(--primary-color);
    /* display: inline-block; */
    border: 1.5px solid var(--primary-color);
    padding: 8px 12px;
    margin: 20px 0px;
    border-radius: 6px;
    color: #fff;
}

.asset-image-heading:hover{
    cursor: pointer;
}

.assset-modal-image-uploaded .ant-modal-content{
    height: 600px !important;   

}

.asset-modal-image .ant-modal-content{
    height: 760px !important;
}
.asset-modal-video .ant-modal-content{
    height: 320px !important;   
}

.upload-asset-box {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 200px !important;
    width: 300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.img-crop-modal .ant-modal-content{
    height: 560px !important;
}

.upload-asset-image .ant-upload{
    width: 750px !important;
    height: 260px !important;
}
.uploaded-asset-image .ant-upload{
    width: 110px !important;
    height: 110px !important;
}
.ant-modal-title{
    font-size: 20px !important;
}


.upload-image-crop{
    margin-bottom: 25px;
}

.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: var(--primary-color) !important;
}

.ant-tabs-tab-btn:hover{
color: var(--hover-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/AdsForm/Assets.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,2BAA2B;IAC3B,wCAAwC;IACxC,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,wBAAwB;;AAE5B;;AAEA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,uCAAuC;AAC3C;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,uBAAuB;IACvB,wBAAwB;AAC5B;AACA;IACI,uBAAuB;IACvB,wBAAwB;AAC5B;AACA;IACI,0BAA0B;AAC9B;;;AAGA;IACI,mBAAmB;AACvB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;AACA,oCAAoC;AACpC","sourcesContent":[".asset-image-heading{\n    background: var(--primary-color);\n    /* display: inline-block; */\n    border: 1.5px solid var(--primary-color);\n    padding: 8px 12px;\n    margin: 20px 0px;\n    border-radius: 6px;\n    color: #fff;\n}\n\n.asset-image-heading:hover{\n    cursor: pointer;\n}\n\n.assset-modal-image-uploaded .ant-modal-content{\n    height: 600px !important;   \n\n}\n\n.asset-modal-image .ant-modal-content{\n    height: 760px !important;\n}\n.asset-modal-video .ant-modal-content{\n    height: 320px !important;   \n}\n\n.upload-asset-box {\n    background-color: #f5f5f5;\n    border-radius: 8px;\n    padding: 200px !important;\n    width: 300px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.img-crop-modal .ant-modal-content{\n    height: 560px !important;\n}\n\n.upload-asset-image .ant-upload{\n    width: 750px !important;\n    height: 260px !important;\n}\n.uploaded-asset-image .ant-upload{\n    width: 110px !important;\n    height: 110px !important;\n}\n.ant-modal-title{\n    font-size: 20px !important;\n}\n\n\n.upload-image-crop{\n    margin-bottom: 25px;\n}\n\n.ant-tabs-tab-active .ant-tabs-tab-btn{\n    color: var(--primary-color) !important;\n}\n\n.ant-tabs-tab-btn:hover{\ncolor: var(--hover-color) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
