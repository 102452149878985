// UNSPECIFIED = "UNSPECIFIED"
// UNKNOWN = "UNKNOWN"
// COMMISSION = "COMMISSION"
// ENHANCED_CPC = "ENHANCED_CPC"
// INVALID = "INVALID"
// MANUAL_CPA = "MANUAL_CPA"
// MANUAL_CPC = "MANUAL_CPC"
// MANUAL_CPM = "MANUAL_CPM"
// MANUAL_CPV = "MANUAL_CPV"
// MAXIMIZE_CONVERSIONS = "MAXIMIZE_CONVERSIONS"
// MAXIMIZE_CONVERSION_VALUE = "MAXIMIZE_CONVERSION_VALUE"
// PAGE_ONE_PROMOTED = "PAGE_ONE_PROMOTED"
// PERCENT_CPC = "PERCENT_CPC"
// TARGET_CPA = "TARGET_CPA"
// TARGET_CPM = "TARGET_CPM"
// TARGET_IMPRESSION_SHARE = "TARGET_IMPRESSION_SHARE"
// TARGET_OUTRANK_SHARE = "TARGET_OUTRANK_SHARE"
// TARGET_ROAS = "TARGET_ROAS"
// TARGET_SPEND = "TARGET_SPEND"


import {TagOutlined} from "@ant-design/icons";
import React from "react";

export const BiddingStratergyOptions=[
    // "UNSPECIFIED",
    // "UNKNOWN",
    // "COMMISSION",
    // "ENHANCED_CPC",
    // "INVALID",
    // "MANUAL_CPA",
    // "MANUAL_CPC",
    // "MANUAL_CPM",
    // "MANUAL_CPV",
    "MAXIMIZE_CONVERSIONS",
    "MAXIMIZE_CONVERSION_VALUE",
    // "PAGE_ONE_PROMOTED",
    // "PERCENT_CPC",
    // "TARGET_CPA",
    // "TARGET_CPM",
    "TARGET_IMPRESSION_SHARE",
    // "TARGET_OUTRANK_SHARE",
    // "TARGET_ROAS",
    "TARGET_SPEND"
]
export const SearchBiddingStrategyOptions=[
    "MAXIMIZE_CONVERSIONS",
    "MAXIMIZE_CONVERSION_VALUE",
    "TARGET_IMPRESSION_SHARE"
]

export const DisplayBiddingStrategyOptions=[
    "TARGET_SPEND",
    "MAXIMIZE_CONVERSIONS",
    "MAXIMIZE_CONVERSION_VALUE",
]

export const LocationOptions=[
    "ANYWHERE_ON_PAGE",
    "TOP_OF_PAGE",
    "ABSOLUTE_TOP_OF_PAGE"
]

export const AgeRangeTypeEnumValues = [
    '18-24 years',
    '25-34 years',
    '35-44 years',
    '45-54 years',
    '55-64 years',
    '65 years and up',
    'Unspecified',
    'Undetermined'
];

export const AgeRangeMap={
    '18-24 years': 'AGE_RANGE_18_24',
    '25-34 years': 'AGE_RANGE_25_34',
    '35-44 years': 'AGE_RANGE_35_44',
    '45-54 years': 'AGE_RANGE_45_54',
    '55-64 years': 'AGE_RANGE_55_64',
    '65 years and up': 'AGE_RANGE_65_UP',
    'Unspecified': 'UNSPECIFIED',
    'Undetermined': 'AGE_RANGE_UNDETERMINED'
}


export const GenderTypeEnumValues = [
    "MALE",
    "FEMALE",
    "UNDETERMINED",
    "UNSPECIFIED",
];

export  const languageIds = {
    "Arabic": 1019,
    "Bengali": 1056,
    "Bulgarian": 1020,
    "Catalan": 1038,
    "Chinese (simplified)": 1017,
    "Chinese (traditional)": 1018,
    "Croatian": 1039,
    "Czech": 1021,
    "Danish": 1009,
    "Dutch": 1010,
    "English": 1000,
    "Estonian": 1043,
    "Filipino": 1042,
    "Finnish": 1011,
    "French": 1002,
    "German": 1001,
    "Greek": 1022,
    "Gujarati": 1072,
    "Hebrew": 1027,
    "Hindi": 1023,
    "Hungarian": 1024,
    "Icelandic": 1026,
    "Indonesian": 1025,
    "Italian": 1004,
    "Japanese": 1005,
    "Kannada": 1086,
    "Korean": 1012,
    "Latvian": 1028,
    "Lithuanian": 1029,
    "Malay": 1102,
    "Malayalam": 1098,
    "Marathi": 1101,
    "Norwegian": 1013,
    "Persian": 1064,
    "Polish": 1030,
    "Portuguese": 1014,
    "Punjabi": 1110,
    "Romanian": 1032,
    "Russian": 1031,
    "Serbian": 1035,
    "Slovak": 1033,
    "Slovenian": 1034,
    "Spanish": 1003,
    "Swedish": 1015,
    "Tamil": 1130,
    "Telugu": 1131,
    "Thai": 1044,
    "Turkish": 1037,
    "Ukrainian": 1036,
    "Urdu": 1041,
    "Vietnamese": 1040
};



export const languageLabels = [
    "Arabic",
    "Bengali",
    "Bulgarian",
    "Catalan",
    "Chinese (simplified)",
    "Chinese (traditional)",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "English",
    "Estonian",
    "Filipino",
    "Finnish",
    "French",
    "German",
    "Greek",
    "Gujarati",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Icelandic",
    "Indonesian",
    "Italian",
    "Japanese",
    "Kannada",
    "Korean",
    "Latvian",
    "Lithuanian",
    "Malay",
    "Malayalam",
    "Marathi",
    "Norwegian",
    "Persian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Romanian",
    "Russian",
    "Serbian",
    "Slovak",
    "Slovenian",
    "Spanish",
    "Swedish",
    "Tamil",
    "Telugu",
    "Thai",
    "Turkish",
    "Ukrainian",
    "Urdu",
    "Vietnamese"
];

export const locationIds = {
    "Afghanistan": 2004,
    "Albania": 2008,
    "Algeria": 2012,
    "American Samoa": 2016,
    "Andorra": 2020,
    "Angola": 2024,
    "Antarctica": 2010,
    "Antigua and Barbuda": 2028,
    "Argentina": 2032,
    "Armenia": 2051,
    "Australia": 2036,
    "Austria": 2040,
    "Azerbaijan": 2031,
    "Bahrain": 2048,
    "Bangladesh": 2050,
    "Barbados": 2052,
    "Belarus": 2112,
    "Belgium": 2056,
    "Belize": 2084,
    "Benin": 2204,
    "Bhutan": 2064,
    "Bolivia": 2068,
    "Bosnia and Herzegovina": 2070,
    "Botswana": 2072,
    "Brazil": 2076,
    "Brunei": 2096,
    "Bulgaria": 2100,
    "Burkina Faso": 2854,
    "Burundi": 2108,
    "Cambodia": 2116,
    "Cameroon": 2120,
    "Canada": 2124,
    "Cape Verde": 2132,
    "Caribbean Netherlands": 2535,
    "Central African Republic": 2140,
    "Chad": 2148,
    "Chile": 2152,
    "China": 2156,
    "Christmas Island": 2162,
    "Cocos (Keeling) Islands": 2166,
    "Colombia": 2170,
    "Comoros": 2174,
    "Cook Islands": 2184,
    "Costa Rica": 2188,
    "Cote d'Ivoire": 2384,
    "Croatia": 2191,
    "Curacao": 2531,
    "Cyprus": 2196,
    "Czechia": 2203,
    "Democratic Republic of the Congo": 2180,
    "Denmark": 2208,
    "Djibouti": 2262,
    "Dominica": 2212,
    "Dominican Republic": 2214,
    "Ecuador": 2218,
    "Egypt": 2818,
    "El Salvador": 2222,
    "Equatorial Guinea": 2226,
    "Eritrea": 2232,
    "Estonia": 2233,
    "Eswatini": 2748,
    "Ethiopia": 2231,
    "Federated States of Micronesia": 2583,
    "Fiji": 2242,
    "Finland": 2246,
    "France": 2250,
    "French Polynesia": 2258,
    "French Southern and Antarctic Lands": 2260,
    "Gabon": 2266,
    "Georgia": 2268,
    "Germany": 2276,
    "Ghana": 2288,
    "Greece": 2300,
    "Grenada": 2308,
    "Guam": 2316,
    "Guatemala": 2320,
    "Guernsey": 2831,
    "Guinea": 2324,
    "Guinea-Bissau": 2624,
    "Guyana": 2328,
    "Haiti": 2332,
    "Heard Island and McDonald Islands": 2334,
    "Honduras": 2340,
    "Hungary": 2348,
    "Iceland": 2352,
    "India": 2356,
    "Indonesia": 2360,
    "Iraq": 2368,
    "Ireland": 2372,
    "Israel": 2376,
    "Italy": 2380,
    "Jamaica": 2388,
    "Japan": 2392,
    "Jersey": 2832,
    "Jordan": 2400,
    "Kazakhstan": 2398,
    "Kenya": 2404,
    "Kiribati": 2296,
    "Kuwait": 2414,
    "Kyrgyzstan": 2417,
    "Laos": 2418,
    "Latvia": 2428,
    "Lebanon": 2422,
    "Lesotho": 2426,
    "Liberia": 2430,
    "Libya": 2434,
    "Liechtenstein": 2438,
    "Lithuania": 2440,
    "Luxembourg": 2442,
    "Madagascar": 2450,
    "Malawi": 2454,
    "Malaysia": 2458,
    "Maldives": 2462,
    "Mali": 2466,
    "Malta": 2470,
    "Marshall Islands": 2584,
    "Mauritania": 2478,
    "Mauritius": 2480,
    "Mexico": 2484,
    "Moldova": 2498,
    "Monaco": 2492,
    "Mongolia": 2496,
    "Montenegro": 2499,
    "Morocco": 2504,
    "Mozambique": 2508,
    "Myanmar (Burma)": 2104,
    "Namibia": 2516,
    "Nauru": 2520,
    "Nepal": 2524,
    "Netherlands": 2528,
    "New Caledonia": 2540,
    "New Zealand": 2554,
    "Nicaragua": 2558,
    "Niger": 2562,
    "Nigeria": 2566,
    "Niue": 2570,
    "Norfolk Island": 2574,
    "North Macedonia": 2807,
    "Northern Mariana Islands": 2580,
    "Norway": 2578,
    "Oman": 2512,
    "Pakistan": 2586,
    "Palau": 2585,
    "Panama": 2591,
    "Papua New Guinea": 2598,
    "Paraguay": 2600,
    "Peru": 2604,
    "Philippines": 2608,
    "Pitcairn Islands": 2612,
    "Poland": 2616,
    "Portugal": 2620,
    "Qatar": 2634,
    "Republic of the Congo": 2178,
    "Romania": 2642,
    "Russia": 2643,
    "Rwanda": 2646,
    "Saint Barthelemy": 2652,
    "Saint Helena, Ascension and Tristan da Cunha": 2654,
    "Saint Kitts and Nevis": 2659,
    "Saint Lucia": 2662,
    "Saint Martin": 2663,
    "Saint Pierre and Miquelon": 2666,
    "Saint Vincent and the Grenadines": 2670,
    "Samoa": 2882,
    "San Marino": 2674,
    "Sao Tome and Principe": 2678,
    "Saudi Arabia": 2682,
    "Senegal": 2686,
    "Serbia": 2688,
    "Seychelles": 2690,
    "Sierra Leone": 2694,
    "Singapore": 2702,
    "Sint Maarten": 2534,
    "Slovakia": 2703,
    "Slovenia": 2705,
    "Solomon Islands": 2090,
    "Somalia": 2706,
    "South Africa": 2710,
    "South Georgia and the South Sandwich Islands": 2239,
    "South Korea": 2410,
    "South Sudan": 2728,
    "Spain": 2724,
    "Sri Lanka": 2144,
    "Sudan": 2736,
    "Suriname": 2740,
    "Sweden": 2752,
    "Switzerland": 2756,
    "Taiwan": 2158,
    "Tajikistan": 2762,
    "Tanzania": 2834,
    "Thailand": 2764,
    "The Bahamas": 2044,
    "The Gambia": 2270,
    "Timor-Leste": 2626,
    "Togo": 2768,
    "Tokelau": 2772,
    "Tonga": 2776,
    "Trinidad and Tobago": 2780,
    "Tunisia": 2788,
    "Turkey": 2792,
    "Turkmenistan": 2795,
    "Tuvalu": 2798,
    "Uganda": 2800,
    "Ukraine": 2804,
    "United Arab Emirates": 2784,
    "United Kingdom": 2826,
    "United States": 2840,
    "United States Minor Outlying Islands": 2581,
    "Uruguay": 2858,
    "Uzbekistan": 2860,
    "Vanuatu": 2548,
    "Vatican City": 2336,
    "Venezuela": 2862,
    "Vietnam": 2704,
    "Wallis and Futuna": 2876,
    "Yemen": 2887,
    "Zambia": 2894,
    "Zimbabwe": 2716
};


export const locationNames = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Caribbean Netherlands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Cook Islands",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Curacao",
    "Cyprus",
    "Czechia",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Federated States of Micronesia",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "French Southern and Antarctic Lands",
    "Gabon",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Macedonia",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Islands",
    "Poland",
    "Portugal",
    "Qatar",
    "Republic of the Congo",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "The Bahamas",
    "The Gambia",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Wallis and Futuna",
    "Yemen",
    "Zambia",
    "Zimbabwe"
];


export const operatingSystemVersionEnums = [
    'iOS v2.0',
    'iOS v3.0',
    'iOS v4.0',
    'iOS v5.0',
    'iOS v6.0',
    'iOS v7.0',
    'iOS v8.0',
    'iOS v9.0',
    'iOS v10.0',
    'iOS v11.0',
    'iOS v12.0',
    'iOS v13.0',
    'iOS v14.0',
    'iOS v15.0',
    'iOS v16.0',
    'Android v0.0',
    'Android v1.0',
    'Android v2.0',
    'Android v3.0',
    'Android v4.0',
    'Android v5.0',
    'Android v6.0',
    'Android v7.0',
    'Android v8.0',
    'Android v9.0',
    'Android v10.0',
    'Android v11.0',
    'Android v12.0',
    'Android v13.0'
]


export const versionToIdMap = {
    'iOS v2.0': 630161,
    'iOS v3.0': 630163,
    'iOS v4.0': 630165,
    'iOS v5.0': 630167,
    'iOS v6.0': 630169,
    'iOS v7.0': 630171,
    'iOS v8.0': 630173,
    'iOS v9.0': 630175,
    'iOS v10.0': 630177,
    'iOS v11.0': 630179,
    'iOS v12.0': 630181,
    'iOS v13.0': 630183,
    'iOS v14.0': 630185,
    'iOS v15.0': 630187,
    'iOS v16.0': 630189,
    'Android v0.0': 630220,
    'Android v1.0': 630222,
    'Android v2.0': 630224,
    'Android v3.0': 630226,
    'Android v4.0': 630228,
    'Android v5.0': 630230,
    'Android v6.0': 630232,
    'Android v7.0': 630234,
    'Android v8.0': 630236,
    'Android v9.0': 630238,
    'Android v10.0': 630257,
    'Android v11.0': 630264,
    'Android v12.0': 630272,
    'Android v13.0': 630463
};


export const CampaignStatus= [
    {
        value: '',
        label: 'Select...'
    },
    {
        value: 'ENABLED',
        label: 'Enabled'
    },
    {
        value: 'PAUSED',
        label: 'Paused'
    },
    {
        value: 'REMOVED',
        label: 'Removed'
    },
    {
        value: 'UNKNOWN',
        label: 'Unknown'
    }
]


export const CampaignObjectives = [
    {
        key: "1",
        icon: <TagOutlined />,
        title: "Sales",
        description: "Drive sales online, in app, by phone or in store",
        value: "SALES"
    },
    // {
    //     key: "2",
    //     icon: <TeamOutlined />,
    //     title: "Leads",
    //     description: "Get leads and other conversions by encouraging customers to take action",
    //     value: "LEADS"
    //
    // },
]


export const CampaignTypes=   [
    {
        key: "1",
        image:"/search.png",
        title: "Search",
        description: "Get in front of high-intent customers at the right time on Google Search",
        value: "SEARCH"
    },
    {
        key: "2",
        image:"/display.png",
        title: "Display",
        description: "Reach customers across three million sites and apps with engaging creative",
        value: "DISPLAY"

    },
]



export const CampaignBudgets = [
    {
        value: '',
        label: 'Select...'
    },
    {
        label: "Standard",
        value: "STANDARD"
    },
    {
        label: "Accelerated",
        value: "ACCELERATED"
    }
]

export const assets = [ "All", "Images", "Videos" ]