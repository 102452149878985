import {createAsyncThunk} from "@reduxjs/toolkit";
import {AdsActionTypes} from "../actions/AdsAction"
import {CREATE_ADS,LIST_ADS,UPLOAD_ASSET,UPLOAD_IMAGE, LIST_ASSETS,CREATE_ADS_DISPLAY} from "../types/AdsActionTypes";

export const createAds=createAsyncThunk(CREATE_ADS,async (body)=>{
    try{
        const res=await AdsActionTypes.createAds(body);
        return res?.data;
    } catch (e){
        throw  e?.response?.data?.error[0] || e;
    }
});

export const createAdsDisplay=createAsyncThunk(CREATE_ADS_DISPLAY,async (body)=>{
    try{
        const res=await AdsActionTypes.createAdsDisplay(body);
        return res?.data;
    } catch (e){
        throw  e?.response?.data?.error[0] || e;
    }
});

export const listAds=createAsyncThunk(LIST_ADS,async (body)=>{
    try{
        const res=await AdsActionTypes.listAds(body);
        return res?.data;
    }catch (e){
        throw  e?.response?.data?.error[0] || e;
    }
})

export const uploadAssetImage=createAsyncThunk(UPLOAD_IMAGE,async (body)=>{
    try{
        const res=await AdsActionTypes.uploadImage(body);
        return res?.data;
    }catch (e){
        throw  e?.response?.data?.error || e;
    }
})

export const uploadAsset=createAsyncThunk(UPLOAD_ASSET,async (body)=>{
    try{
        const res=await AdsActionTypes.uploadAsset(body);
        return res?.data;
    }catch (e){
        throw  e?.response?.data?.error || e;
    }
})

export const listAssets=createAsyncThunk(LIST_ASSETS,async (body)=>{
    try{
        const res=await AdsActionTypes.listAssets(body);
        return res?.data;
    }catch (e){
        throw  e?.response?.data?.error[0] || e;
    }
})