import Listing from "../../common/Listing";
import {useContext, useEffect, useState} from "react";
import {RESPONSE_SERVICES} from "../../common/Services";
import {useDispatch, useSelector} from "react-redux";
import {listAds} from "../../redux/thunk/AdsThunk";
import {getCampaignInfo, listAdGroup} from "../../redux/thunk/CampaignThunk";
import {LINKED_ACCOUNTS_FULFILLED} from "../../redux/types/linkedAccountActionTypes"
import { LIST_AD_GROUP_FULFILLED , GET_CAMPAIGN_INFO_FULFILLED} from "../../redux/types/CampaignActionTypes"
import {Functions} from "../../common/functions";
import {NavBarContext} from "../Navbar/Navbar";
const ListAds=()=>{
    const [loading,setLoading]=useState(false);
    const [contentLoading,setContentLoading]=useState(false);
    const [adGroupList,setAdGroupList] = useState([]);
    const [adList,setAdList]=useState([]);
    const dispatch=useDispatch();
    const {setParamsCampaignId, setParamsCustomerId}=useContext(NavBarContext)
    const params=new URLSearchParams(window.location.search);
    const customerId=params.get('customer_id');
    const campaignId=params.get('campaign_id');
    const groupId=params.get('group_id');
    const {campaigns}=useSelector((state) => state.campaigns);
    const getAdsList=async (gid,customerId)=>Functions.getAdList(setContentLoading,gid,customerId,dispatch,setAdList)

    const getAdGroupList=async (campaignId,customerId)=>Functions.getAdGroupList(setLoading,campaignId?.id || campaignId,customerId,setAdGroupList,dispatch);

    const getCampaignIds=async (selectedAccount)=>Functions.fetchCampaignDetails(selectedAccount,setContentLoading,dispatch)

    useEffect(() => {
        setParamsCampaignId(campaignId);
        setParamsCustomerId(customerId);
    }, []);

    useEffect(() => {
        if(groupId && customerId && campaignId){
            getAdsList(groupId,customerId);
        }
    }, [campaignId,customerId,groupId]);

    return <Listing contentLoading={contentLoading}
                    loading={loading}
                    setLoading={setLoading}
                    getAdsDetails={getAdGroupList}
                    fetchDetails={getCampaignIds}
                    campaignIds={campaignId?null:campaigns}
                    adgroup={true}
                    adPage={true}
                    adGroupIds={adGroupList}
                    getAdList={getAdsList}
                    data={adList}
                    adPageWithParams={!!(campaignId && customerId && groupId)}


    />
}



export default ListAds;