export const convertToNumber = (macros) => {
    return  parseFloat((macros/1000000).toFixed(2))
}

export const convertToMacros = (dollar) => {
    return dollar*1000000;
}

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}