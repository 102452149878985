import React from 'react';
import { Drawer,Tabs } from 'antd';

const AssetDrawer = ({showAssetModal, handleCancel,items,title,type}) => {
  
  return (
    <div>
       <Drawer destroyOnClose={true} className={type === "image_upload" ? "asset-modal-image": type=="image_uploaded" ? "assset-modal-image-uploaded" :"asset-modal-video"} title={title} open={showAssetModal} onClose={handleCancel} width={800} >
                {
                    <Tabs defaultActiveKey="1" items={items}/>
                }
      </Drawer>
    </div>
  );
}

export default AssetDrawer;