import {createSlice} from "@reduxjs/toolkit";
import {handleAsyncAction} from "../common/AsyncCases";
import {getLinkedAccount} from "../thunk/linkedAccountThunk";


const LinkedAccountSlice = createSlice({
    name: "linkedAccount",
    initialState: {
        linkedAccount: [],
        isLoading: false,
        error: null,
        fulfilled:false
    },
    extraReducers:(builder)=>{
        handleAsyncAction(builder,getLinkedAccount,(state,action)=>{
            state.isLoading = false;
            state.linkedAccount = action.payload?.accounts?.resourceNames;
        })
    }
})

export default LinkedAccountSlice.reducer;