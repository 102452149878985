import React, {useEffect, useState} from 'react';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        setIsLoggedIn(localStorage.getItem('login')!==null);
    }, []);

    const login = (res) => {
        setIsLoggedIn(true);
        localStorage.setItem('login', JSON.stringify(res));
    };

    const logout = () => {
        setIsLoggedIn(false);
        localStorage.removeItem('login');
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
