import {createSlice} from "@reduxjs/toolkit";
import {handleAsyncAction} from "../common/AsyncCases";
import {getCampaignInsights} from "../thunk/InsightsThunk";

const InsightsSlice = createSlice({
    name: "Insights",
    initialState: {
        insights: [],
        isLoading:false,
        error:null
    },
    reducers:{},
    extraReducers:(builder)=>{
        handleAsyncAction(builder,getCampaignInsights,(state, action) => {
            state.insights=action.payload || [];
        });

    }
})

export default InsightsSlice.reducer;


